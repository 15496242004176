<template>
  <HeaderComponent></HeaderComponent>

  <request-status :response="responseStatus" :isLoading="isLoading" />

  <div align="center" v-if="!isLoading && hospital">
    <v-row class="mt-5">
      <v-col :cols="smallWindow ? 12 : 5">
        <hospital-personal-info :hospital="hospital" />
      </v-col>
      <v-col :cols="smallWindow ? 12 : 7">
        <hospital-special-info :hospital="hospital" />
      </v-col>
    </v-row>
  </div>
  <FooterComponent></FooterComponent>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { onBeforeUnmount, ref, computed } from "vue"
import { Hospital } from "@/types"
import { onMounted } from "vue"
import { useRoute } from "vue-router"
import { useUserActions } from "@/stores/user-actions"
import { useToast } from "vue-toast-notification"
import FooterComponent from "@/components/Footer/FooterComponent.vue"
import HeaderComponent from "@/components/Header/HeaderComponent.vue"
import HospitalPersonalInfo from "./HospitalInfo/HospitalPersonalInfo.vue"
import HospitalSpecialInfo from "./HospitalInfo/HospitalSpecialInfo.vue"
import { useMeta } from "vue-meta"

const { t } = useI18n()
const route = useRoute()
const id = ref()
const userAction = useUserActions()
const isLoading = ref(true)
const hospital = ref<Hospital | undefined>(undefined)
const responseStatus = ref({})
const smallWindow = ref(window.innerWidth < 1000)
const $toast = useToast()
onMounted(() => {
  id.value = route.params.id
  localStorage.removeItem("hospitalId")
  getHospitalProfile()

  window.addEventListener("resize", updateWindowSize)
})

useMeta(
  computed(() => ({
    title: hospital.value?.name ?? "Medcircle",
  }))
)

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateWindowSize)
})

// const buildTimeArray = (totalTime: string[]) => {
//   let firstDate = new Date(totalTime[0])

//   totalTime.slice(1, -1).forEach((date, index) => {
//     availableDatesArray.value = [firstDate]

//     const thisDate = new Date(date)
//     const nextDate = new Date(totalTime[index + 2])

//     const differenceInDays = Math.abs(
//       (nextDate.getTime() - thisDate.getTime()) / (1000 * 3600 * 24)
//     )

//     if (differenceInDays > 1) {
//       availableDatesArray.value.push(thisDate)

//       twoDimensionalDateArray.value.push(availableDatesArray.value)

//       availableDatesArray.value = []
//       firstDate = nextDate
//     } else if (index == totalTime.length - 3) {
//       availableDatesArray.value.push(nextDate)

//       twoDimensionalDateArray.value.push(availableDatesArray.value)
//     }
//   })
//   modelDateArray.value = twoDimensionalDateArray.value
// }

// useMeta(
//   computed(() => ({
//     title: `${doctorName.value ?? ""}
//         ${doctor.value?.clinicLocation ?? "Medcircle"}
//         ${doctorSpecialty.value ?? ""}`,
//     description: `${t("meta.doctor-description")} ${doctorName.value} - ${
//       doctorSpecialty.value
//     } `,

//     meta: [
//       { name: "viewport", content: "user-scalable=no" },
//       {
//         property: "og:title",
//         content:
//           doctorName.value +
//           " " +
//           doctor.value?.clinicLocation +
//           " " +
//           doctorSpecialty.value,
//       },
//       { property: "og:image", content: doctor?.value?.photo },
//       {
//         property: "og:type",
//         content: `website`,
//       },
//       {
//         property: "og:description",
//         content: `${t("meta.doctor-description")} ${doctorName.value} - ${
//           doctorSpecialty.value
//         } `,
//       },
//       {
//         property: "og:url",
//         content: `https://medcircle.eu/doctors/${id.value}`,
//       },
//       {
//         property: "og:site_name",
//         content: `medcircle`,
//       },
//     ],
//   }))
// )

const getHospitalProfile = async (): Promise<void> => {
  const response = await userAction.getSingleHospital(id.value)

  responseStatus.value = response
  if (response.success) {
    hospital.value = response.result as Hospital
  }
  isLoading.value = false
}
</script>

<style scoped>
.custom-title {
  font-size: x-large;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-content: center !important;
  justify-content: center;
}

.flex-item {
  flex: 1 0 40%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}
</style>
