<template>
  <div class="header-fixed">
    <div class="app-bar">
      <router-link class="logo-container" to="/" custom>
        <button v-on:click="onLogoClicked()">
          <div class="logo-wrapper">
            <img
              :src="require('../../../assets/441.png')"
              alt="medcircle"
              class="logo"
              style="border-radius: 40%; border-radius: 8px; margin-right: 4px"
            />
            <div class="text-logo">edcircle</div>
          </div>
        </button>
      </router-link>

      <div v-if="!smallWindow" class="btns-container d-flex gap">
        <locale-picker />
        <template v-for="item in headerProps" :key="item.text">
          <router-link v-if="item.path" :to="item.path">
            <v-btn
              class="text-none text-subtitle-1"
              variant="text"
              color="white"
            >
              {{ item.text }}
            </v-btn>
          </router-link>

          <v-menu
            v-if="item.subitems"
            offset-y
            :open-on-hover="isTouchScreen ? false : true"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                class="text-none text-subtitle-1"
                variant="text"
                color="white"
                v-bind="props"
              >
                {{ item.text }}
                <v-img
                  v-if="item.photo"
                  :src="item.photo"
                  :width="30"
                  style="border-radius: 50%"
                ></v-img>
                <v-icon v-else right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list style="background-color: #1e293c">
              <v-list-item
                v-for="subitem in item.subitems"
                :key="subitem.text"
                :to="subitem.path"
              >
                <v-icon color="white" right>{{ subitem.icon }}</v-icon>
                <v-btn
                  class="text-none text-subtitle-1 ml-2"
                  variant="text"
                  color="white"
                  style="min-width: 120px"
                  >{{ subitem.text }}</v-btn
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </div>
      <div v-else class="btns-container">
        <locale-picker />
        <v-menu
          offset-y
          :close-on-content-click="true"
          eager
          :open-on-hover="isTouchScreen ? false : true"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              class="text-none text-subtitle-1"
              variant="text"
              color="white"
              v-bind="props"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list style="background-color: #1e293c">
            <template v-for="item in headerProps" :key="item.text">
              <v-list-item>
                <v-btn
                  v-if="item.path"
                  :to="item.path"
                  class="text-none text-subtitle-1 ml-2"
                  variant="text"
                  color="white"
                  style="min-width: 140px"
                >
                  {{ item.text }}
                </v-btn>

                <v-menu
                  v-if="item.subitems"
                  offset-y
                  :open-on-hover="isTouchScreen ? false : true"
                  open-on-click
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="text-none text-subtitle-1 ml-2"
                      variant="text"
                      color="white"
                      style="min-width: 140px"
                      v-bind="props"
                    >
                      {{ item.text }}
                      <v-img
                        v-if="item.photo"
                        :src="item.photo"
                        :width="30"
                        style="border-radius: 50%"
                      ></v-img>
                      <v-icon v-else right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list style="background-color: #1e293c">
                    <v-list-item
                      v-for="subitem in item.subitems"
                      :key="subitem.text"
                      :to="subitem.path"
                    >
                      <v-icon color="white" right>{{ subitem.icon }}</v-icon>
                      <v-btn
                        class="text-none text-subtitle-1 ml-2"
                        variant="text"
                        color="white"
                        style="min-width: 120px"
                        >{{ subitem.text }}</v-btn
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import LocalePicker from "@/components/Header/LocalePicker/LocalePicker.vue"
import router from "@/router"
import { useUserStore } from "@/stores/user-auth"
import { useUserActions } from "@/stores/user-actions"
import { doctorAuthStore } from "@/stores/doctor-auth"
import { doctorActions } from "@/stores/doctor-actions"

export default defineComponent({
  name: "DoctorHeader",
  components: {
    LocalePicker,
  },

  setup(_, { emit }) {
    const onLogoClicked = (): void => {
      router.push("/")
    }
    const { t } = useI18n()
    const docAuth = doctorAuthStore()
    const docAction = doctorActions()

    const smallWindow = ref(window.innerWidth < 1100)

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1100
    }

    const headerProps = computed(() => {
      let header: {
        text: string
        path: string | null
        photo?: string | null
        subitems: { text: string; path: string; icon: string }[] | null
      }[] = [
        {
          text: t("header.dashboard"),
          path: "/doctor-dashboard/overview",
          subitems: null,
        },

        {
          text: t("header.help"),
          path: "/doc-contact",
          subitems: null,
        },
        {
          text: t("header.aboutUs"),
          path: "/about-us-doc",
          subitems: null,
        },
      ]

      let profileHeader: {
        text: string
        path: string | null
        photo?: string | null
        subitems: { text: string; path: string; icon: string }[] | null
      }[] = [
        {
          text: "",
          path: null,
          photo:
            docAction.doctorProfileLink !== ""
              ? docAction.doctorProfileLink
              : require("../../../assets/blank.png"),
          subitems: [
            {
              text: t("header.profile"),
              path: "/doctor-profile/general-information",
              icon: "mdi-account",
            },
            {
              text: t("header.logout"),
              path: "/doc-logout",
              icon: "mdi-logout",
            },
          ],
        },
      ]

      header = header.concat(profileHeader)

      return header
    })

    const isTouchScreen = computed(() => {
      return window.matchMedia("(pointer: coarse)").matches
    })

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })

    return {
      onLogoClicked,
      smallWindow,
      isTouchScreen,
      headerProps,
    }
  },
})
</script>

<style scoped>
.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 2px -2px gray;
}
.app-bar {
  height: 70px;
  z-index: 100;
  align-items: center;
  display: flex;
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;
  margin: auto;
  background-color: #25344d;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.logo {
  height: 48px;
}

.text-logo {
  color: white;
  font-size: xx-large;
}
.btns-container {
  margin-left: auto;
  align-items: center;
}

@media only screen and (max-width: 500px) {
  .app-bar {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
