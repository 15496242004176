<template>
  <v-container>
    <div class="flex">
      <div align="center" v-if="resultsView">
        <filter-component
          :type="type"
          :specialty="specialty"
          :hospitalId="chosenHospital"
          :department="chosenDepartment"
          @filter-applied="filterApplied($event)"
        />
      </div>
      <div class="flex-item">
        <v-menu v-model="fromDateMenu" :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-text-field
              class="ml-4 mr-4"
              v-model="formattedDate"
              prepend-inner-icon="mdi-calendar"
              :label="$t('search.date-line')"
              style="min-width: 250px"
              v-bind="props"
            >
            </v-text-field>
          </template>

          <VueDatePicker
            :inline="isInline"
            :enable-time-picker="false"
            v-model="selectedDate"
            :min-date="new Date()"
            format="dd MMM yyyy"
            class="v-theme--light mb-2"
            :placeholder="$t('search.date-line')"
            @date-update="handleDate"
          />
        </v-menu>
      </div>
      <div class="flex-item">
        <v-autocomplete
          class="ml-4 mr-4"
          no-filter
          prepend-inner-icon="mdi-map-marker"
          v-model="chosenAddress"
          :items="addressOptions"
          :item-title="getCityAddress"
          :item-value="getCityAddressValue"
          :label="$t('search.city')"
          @input="emitCityInput"
          style="height: 80px; min-width: 250px"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" v-if="!cityLoading">
              <template v-slot:title> {{ item.raw.name }} </template>
              <template v-slot:subtitle> {{ item.raw.country }} </template>
            </v-list-item>
          </template>
          <template v-slot:no-data>
            <p class="font-weight-regular ml-4 mt-2 mb-2">
              {{ $t("no-data") }}
            </p></template
          >
          <template v-slot:append-inner>
            <v-progress-circular
              v-if="cityLoading"
              indeterminate
              class="ml-2"
            ></v-progress-circular>
          </template>
        </v-autocomplete>
      </div>

      <div class="flex-item" v-if="!resultsView">
        <v-autocomplete
          class="ml-4 mr-4"
          prepend-inner-icon="mdi-doctor"
          v-model="specialty"
          :items="
            locale == 'de' ? SPECIALIZATION_DATA_DE : SPECIALIZATION_DATA_EN
          "
          :label="$t('search.specialty')"
          outlined
          :menu-props="{ width: '700px' }"
          style="min-width: 250px"
        ></v-autocomplete>
      </div>

      <div align="center">
        <v-btn
          :disabled="!infoCompleted"
          @click="onSearchClicked"
          class="ml-5"
          icon="mdi-magnify"
          color="#3fb984"
          size="large"
        ></v-btn>
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, ref, watch } from "vue"
import moment from "moment"
import { useI18n } from "vue-i18n"
import { AutoCompleteAddress, Department } from "@/types"

import {
  SPECIALIZATION_DATA_DE,
  SPECIALIZATION_DATA_EN,
  specialtiesMap,
  SpecialtiesMap,
} from "../../../types/spec"
import { useUserActions } from "@/stores/user-actions"
import { commonStore } from "@/stores/common-store"
import { onMounted } from "vue"
import FilterComponent from "./Filter/FilterComponent.vue"
import { hospitalActions } from "@/stores/hospital-actions"
interface SearchFilterDoc {
  includeHospital: boolean
  includeClinic: boolean
  hospitalValue: string | null
  departmentValue: string | null
  clinic: string | null
  reset: boolean
}
export default defineComponent({
  components: { FilterComponent },
  name: "SearchComponent",

  data() {
    const { t } = useI18n()

    return
  },
  props: {
    resultsView: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const userAction = useUserActions()
    const common = commonStore()
    const chosenDepartment = ref<string | undefined>(undefined)

    const addressOptions = ref<AutoCompleteAddress[]>([])
    const longitude = ref<number>(0)
    const latitude = ref<number>(0)
    const chosenAddress = ref<AutoCompleteAddress>()
    const city = ref<string>()
    const country = ref<string>()
    const specialty = ref<string>()
    const today = ref(new Date())
    const locale = ref(localStorage.getItem("locale") ?? "de")

    const windowWidth = ref(window.innerWidth)
    const fromDateMenu = ref(false)
    const isInline = ref(true)
    const cityLoading = ref(false)
    let timeoutId: ReturnType<typeof setTimeout> | null = null
    const type = ref<string>()
    const chosenHospital = ref<string>()
    const selectedDate = ref<Date | undefined>()
    const storedSearchParams = localStorage.getItem("searchParams")
    if (storedSearchParams) {
      const savedParams = JSON.parse(storedSearchParams)
      selectedDate.value = savedParams["startDate"]
        ? moment(savedParams["startDate"]).locale("de").toDate()
        : undefined

      if (locale.value == "en") {
        const reverseSpecialtiesMap: SpecialtiesMap = {}
        for (const [key, value] of Object.entries(specialtiesMap)) {
          reverseSpecialtiesMap[value] = key
        }
        specialty.value =
          reverseSpecialtiesMap[savedParams["specialty"] as string]
      } else {
        specialty.value = savedParams["specialty"] as string
      }

      latitude.value = savedParams["latitude"]
      longitude.value = savedParams["longitude"]
      city.value = savedParams["city"]
      chosenAddress.value = {
        name: savedParams["city"] ?? "",
        coordinates: [
          savedParams["longitude"] ?? 0,
          savedParams["latitude"] ?? 0,
        ],
        country: savedParams["country"] ?? "",
        postcode: savedParams["postcode"] ?? "",
        state: savedParams["state"] ?? "",
      }
      type.value = savedParams["type"]
      chosenDepartment.value = savedParams["department"]
      chosenHospital.value = savedParams["hospitalId"]
    }

    const infoCompleted = computed(() => {
      return selectedDate.value != undefined && city.value
    })

    const formattedDate = computed(() => {
      return formatDate(selectedDate?.value?.toLocaleDateString() ?? "")
    })

    const emitCityInput = async (input: any) => {
      cityLoading.value = true

      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }

      timeoutId = setTimeout(async () => {
        await searchLocations(input.target.value)
        cityLoading.value = false
      }, 500)
    }

    const searchLocations = async (val: string) => {
      addressOptions.value = []
      const response = await common.searchLocation(val)
      response.features.forEach((feature, index) => {
        const address = {
          name: feature.properties.name,
          state: feature.properties.state ?? "",
          postcode: feature.properties.postcode ?? "",
          country: feature.properties.country,
          coordinates: feature.geometry.coordinates,
        }

        addressOptions.value.push(address)
      })
    }

    const getCityAddress = (address: AutoCompleteAddress): string | null => {
      if (address) {
        return `${address["name"]}`
      }
      return null
    }
    const getCityAddressValue = (address: AutoCompleteAddress): any => {
      return address
    }
    const handleDate = (modelData: Date) => {
      selectedDate.value = modelData
    }

    watch([selectedDate], () => {
      fromDateMenu.value = !fromDateMenu.value
    })

    watch([chosenAddress], () => {
      city.value = chosenAddress.value?.["name"]
      country.value = chosenAddress.value?.["country"]
      latitude.value = chosenAddress.value?.["coordinates"][1] ?? 0
      longitude.value = chosenAddress.value?.["coordinates"][0] ?? 0
      addressOptions.value = []
    })

    onMounted(async () => {
      window.addEventListener("locale-changed", updateLocale)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("locale-changed", updateLocale)
    })

    const updateLocale = () => {
      locale.value = localStorage.getItem("locale") ?? "de"
    }

    const formatDate = (dateString: string) => {
      if (dateString == "") {
        return ""
      }
      const [day, month, year] = dateString.split("/")

      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]
      const monthName = monthNames[parseInt(month, 10) - 1]
      return `${parseInt(day, 10)} ${monthName} ${year}`
    }

    const getDepartmentName = computed(
      () =>
        (department: Department): string => {
          return locale.value == "de" ? department.de : department.en
        }
    )
    const getDepartmentValue = (department: Department): string => {
      return department.value
    }

    return {
      selectedDate,
      city,
      specialty,
      today,
      windowWidth,
      type,
      infoCompleted,
      formattedDate,
      userAction,
      fromDateMenu,
      isInline,
      emitCityInput,
      addressOptions,
      chosenAddress,
      getCityAddress,
      getCityAddressValue,
      latitude,
      longitude,
      country,
      locale,
      handleDate,
      cityLoading,
      SPECIALIZATION_DATA_DE,
      SPECIALIZATION_DATA_EN,
      specialtiesMap,
      chosenHospital,
      chosenDepartment,
      getDepartmentName,
      getDepartmentValue,
    }
  },
  methods: {
    onSearchClicked(): void {
      var endDate = new Date(this.selectedDate ?? new Date())
      endDate.setDate(endDate.getDate() + 30)

      var spec = ""
      if (this.locale == "en" && this.specialty) {
        spec = specialtiesMap[this.specialty]
      } else {
        spec = this.specialty ?? ""
      }

      const searchParams = {
        startDate: this.userAction.getDatesInString(
          this.selectedDate ?? new Date()
        ),
        endDate: this.userAction.getDatesInString(endDate ?? new Date()),
        latitude: this.latitude,
        longitude: this.longitude,
        city: this.city,
        country: this.country,
        department: this.chosenDepartment,
        hospitalId: this.chosenHospital,
        specialty: spec ?? "",
        type: this.type ?? "",
      }

      localStorage.setItem("searchParams", JSON.stringify(searchParams))

      this.$emit("search-clicked")
      this.$router.push({ path: "/doctors" })
    },
    // async typeChanged(type: string): Promise<void> {
    //   this.type = type
    //   await sleep(200)
    //   this.onSearchClicked()
    // },
    // hospitalChosen(hospital: any): void {
    //   this.chosenHospital = hospital
    // },
    async filterApplied(filter: SearchFilterDoc): Promise<void> {
      if (filter.includeClinic) {
        this.type = "privateClinic"
      } else if (filter.includeHospital) {
        this.type = "hospitalAttached"
      }

      if (filter.clinic) {
        this.specialty = filter.clinic
      } else if (filter.hospitalValue) {
        this.chosenHospital = filter.hospitalValue
      }
      if (filter.departmentValue) {
        this.chosenDepartment = filter.departmentValue
      }

      if (filter.reset) {
        this.specialty = ""
        this.chosenHospital = ""
        this.type = ""
        this.chosenDepartment = ""
      }

      this.onSearchClicked()
    },
  },
})
</script>

<style>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 25%; /* Adjust the width as needed */
}
.v-input__control {
  background-color: white;
}
@media screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
}
</style>
@/stores/common-store
