<template>
  <div ref="counterSection">
    <div class="container-title">
      <h1>
        {{ $t("sofar") }}
      </h1>

      <p class="mt-2 mr-2" style="font-size: large; font-weight: 400">
        {{ $t("count-explanation") }}
      </p>
    </div>
    <div class="flex mt-10" align="center">
      <div class="flex-item" v-if="startCounter">
        <div
          class="mr-10 ml-5"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <p style="font-size: 80px; color: #8164eb; font-weight: 600">
            <count-up
              :startVal="0"
              :endVal="150"
              :duration="4"
              :options="options"
            ></count-up>
          </p>
          <p style="font-size: 30px; color: black; font-weight: 600">
            {{ t("header.applications") }}
          </p>
        </div>
      </div>
      <div class="flex-item" v-if="startCounter">
        <div
          class="mr-10 ml-5"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <p style="font-size: 80px; color: #00b1aa; font-weight: 600">
            <count-up
              :startVal="0"
              :endVal="100"
              :duration="4"
              :options="options"
            ></count-up>
          </p>
          <p style="font-size: 30px; color: black; font-weight: 600">
            {{ t("students") }}
          </p>
        </div>
      </div>
      <div class="flex-item" v-if="startCounter">
        <div
          class="mr-10 ml-5"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <p style="font-size: 80px; color: #f54265; font-weight: 600">
            <count-up
              :startVal="0"
              :endVal="50"
              :duration="4"
              :options="options"
            ></count-up>
          </p>
          <p style="font-size: 30px; color: black; font-weight: 600">
            {{ t("doctors") }}
          </p>
        </div>
      </div>
      <div class="flex-item" v-if="startCounter">
        <div
          class="mr-10 ml-5"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <p style="font-size: 80px; color: #f6c13d; font-weight: 600">
            <count-up
              :startVal="0"
              :endVal="1"
              :duration="4"
              :options="options"
            ></count-up>
          </p>
          <p style="font-size: 30px; color: black; font-weight: 600">
            {{ t("hospitals") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue"
import { useI18n } from "vue-i18n"
import CountUp from "vue-countup-v3"
export default defineComponent({
  name: "CounterComponent",
  components: { CountUp },
  data() {
    const { t } = useI18n()
    const startCounter = ref(false)
    return {
      t,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "+",
      },
      startCounter,
    }
  },
  methods: {
    observeIntersection(entries: IntersectionObserverEntry[]) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.startCounter = true
        }
      })
    },
  },
  mounted() {
    const observer = new IntersectionObserver(this.observeIntersection, {
      threshold: 0.1, // 10% of the element should be visible
    })
    const counterSection = this.$refs.counterSection as HTMLElement
    if (counterSection) {
      observer.observe(counterSection)
    }
  },
  beforeUnmount() {
    if (this.$refs.counterSection) {
      const observer = new IntersectionObserver(this.observeIntersection)
      observer.disconnect()
    }
  },
})
</script>
<style>
.container-title {
  padding-left: 30px;
  margin-top: 10px;
}
@media only screen and (max-width: 700px) {
  .container-title {
    padding-left: 10px;
    margin-top: 10px;
  }
}
</style>
