import { useUserActions } from '@/stores/user-actions'
import { doctorActions } from '../stores/doctor-actions'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { hospitalActions } from '@/stores/hospital-actions'

export const isDoctorGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const docAction = doctorActions()
  const isAuthenticated = await docAction.isTokenValid()
  if (isAuthenticated) {
    next()
  } else {
    next({ path: '/doc-login' })
  }
}

export const isLoggedInDoctorGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const docAction = doctorActions()
  const isAuthenticated = await docAction.isTokenValid()
  if (isAuthenticated) {
    next({ path: '/doctor-dashboard' })
  } else {
    next()
  }
}

export const isStudentGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const userAction = useUserActions()
  const isAuthenticated = await userAction.isTokenValid()
  if (isAuthenticated) {
    next()
  } else {
    next({ path: '/login' })
  }
}
export const isHospitalGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const hospitalAction = hospitalActions()
  const isAuthenticated = await hospitalAction.isTokenValid()
  if (isAuthenticated) {
    next()
  } else {
    next({ path: '/hospital-login' })
  }
}

export const isDoctorOrHospitalGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const hospitalAction = hospitalActions()
  const docAction = doctorActions()
  const isHospitalAuthenticated = await hospitalAction.isTokenValid()

  const isDoctorAuthenticated = await docAction.isTokenValid()
  if (isHospitalAuthenticated || isDoctorAuthenticated) {
    next()
  } else {
    next({ path: '/' })
  }
}
