import {
  Department,
  Departments,
  RequestResponse,
  SearchResponseData,
} from "../types/index"
import { defineStore } from "pinia"
import photonClient from "./photonClient"
import apiClient from "./apiclients"

export const commonStore = defineStore({
  id: "common",

  state: () => ({
    id: "",
  }),

  actions: {
    async searchLocation(keyword: string): Promise<SearchResponseData> {
      let data: SearchResponseData = { features: [], type: "" }

      await photonClient
        .get(`/`, {
          params: {
            q: keyword,
            lang: localStorage.getItem("locale") ?? "de",
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getAllHospitals(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }

      await apiClient
        .get(`public/get-hospitals`)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getDepartments(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }

      await apiClient
        .get(`public/search-department`)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
  },
})
