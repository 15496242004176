import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { align: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderComponent),
    _createVNode(_component_v_card, {
      class: "mx-auto pa-12 pb-8 my-15",
      style: {"font-family":"Verdana, Geneva, Tahoma, sans-serif"},
      elevation: "8",
      "max-width": "648",
      rounded: "lg"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('email-verification-title')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('email-verification-text')) + " ", 1),
              _createElementVNode("a", {
                class: "text-blue text-decoration-none",
                rel: "noopener noreferrer",
                style: {"cursor":"pointer"},
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/doc-login')))
              }, _toDisplayString(_ctx.$t('login')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ], 64))
}