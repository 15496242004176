<template>
  <HeaderComponent></HeaderComponent>
  <div></div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import HeaderComponent from '../../../Header/HeaderComponent.vue'
import router from '@/router'
import { hospitalAuthStore } from '@/stores/hospital-auth'

export default defineComponent({
  components: {
    HeaderComponent,
  },
  name: 'HospitalLogoutComponent',

  setup() {
    onMounted(() => {
      const hospitalAuth = hospitalAuthStore()
      localStorage.removeItem('hospitalAccessToken')
      hospitalAuth.isAuthenticated = false
      router.push('/')
    })
  },
})
</script>
