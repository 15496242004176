<template>
  <div :key="keyValue">
    <HeaderComponent />
    <request-status :response="responseStatus" :isLoading="isLoading" />
    <div>
      <v-card
        class="mx-auto pa-12 pb-8 my-15"
        elevation="8"
        max-width="448"
        rounded="lg"
      >
        <div align="center">
          <v-img
            width="100px"
            :src="require('../../../assets/support.png')"
          ></v-img>
        </div>

        <h3 class="mt-4">
          {{ $t('contact-form') }}
        </h3>

        <div class="text-subtitle-1 text-medium-emphasis">
          {{ $t('name') }}
        </div>

        <v-text-field
          v-model="name"
          density="compact"
          :placeholder="$t('name')"
          prepend-inner-icon="mdi-pencil"
          :rules="nameRule"
          variant="outlined"
        ></v-text-field>

        <div
          class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
        >
          {{ $t('email') }}
        </div>

        <v-text-field
          v-model="email"
          density="compact"
          :placeholder="$t('email')"
          prepend-inner-icon="mdi-email-outline"
          :rules="emailRules"
          variant="outlined"
        ></v-text-field>

        <div
          class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
        >
          {{ $t('message') }}
        </div>

        <v-textarea
          v-model="message"
          density="compact"
          :placeholder="$t('message')"
          prepend-inner-icon="mdi-pencil"
          :rules="messageRule"
          variant="outlined"
        ></v-textarea>
        <v-btn
          class="mb-8"
          color="blue"
          size="large"
          variant="flat"
          block
          :loading="isLoading"
          :disabled="!isInfoCompleted"
          @click="submit"
        >
          {{ $t('send') }}
        </v-btn>
      </v-card>
    </div>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script setup lang="ts">
import FooterComponent from '@/components/Footer/FooterComponent.vue'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import { useUserActions } from '@/stores/user-actions'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
import { useToast } from 'vue-toast-notification'

const { t } = useI18n()

const name = ref<string | null>(null)
const email = ref<string | null>(null)
const message = ref<string | null>(null)
const isLoading = ref<boolean>(false)
const keyValue = ref(0)

const $toast = useToast()
const responseStatus = ref({})
const emailRules = [
  (v: string) => !!v || t('email-required'),
  (v: string) => /.+@.+\..+/.test(v) || t('email-authentic'),
]
const nameRule = [(v: string) => !!v || t('name-required')]
const messageRule = [(v: string) => !!v || t('message-required')]

const isInfoCompleted = computed(() => {
  const isEmailInvalid = emailRules.some((rule) => {
    const result = rule(email.value ?? '')
    return typeof result === 'string'
  })
  const isNameValid = nameRule.some((rule) => {
    const result = rule(name.value ?? '')
    return typeof result === 'string'
  })
  const isMessageValid = messageRule.some((rule) => {
    const result = rule(message.value ?? '')
    return typeof result === 'string'
  })
  console.log(isEmailInvalid, isNameValid, isMessageValid)

  return !isEmailInvalid && !isNameValid && !isMessageValid
})

useMeta(
  computed(() => ({
    title: t('meta.contact'),
  }))
)

const submit = async () => {
  isLoading.value = true
  const userAction = useUserActions()

  const data = {
    email: email.value ?? '',
    name: name.value ?? '',
    message: message.value ?? '',
  }

  const response = await userAction.contactMedCircle(data)
  responseStatus.value = response

  if (response.success) {
    $toast.success(t('successfully-message-sent'), {
      position: 'top-right',
    })
  } else {
    $toast.error(t('successfully-not-message-sent'), {
      position: 'top-right',
    })
  }
  email.value = null
  name.value = null
  message.value = null
  isLoading.value = false
  keyValue.value++
  isLoading.value = false
}
</script>

<style scoped></style>
