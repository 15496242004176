<template>
  <h2 class="mt-4">
    {{ $t('your-applications') }}
  </h2>
  <v-divider class="mt-3 mb-10"></v-divider>

  <request-status :response="requestResponse" :isLoading="isLoading" />

  <div v-if="!isLoading">
    <div class="flex flex-wrap mt-5 justify-center">
      <div v-for="(application, index) in applications" :key="index">
        <div v-if="application?.doctorInfo !== null" class="flex-item mb-10">
          <v-hover>
            <template v-slot:default="{ isHovering, props }">
              <v-card
                class="mx-auto ml-2"
                height="250"
                :width="!smallWindow ? 500 : undefined"
                :elevation="isHovering ? 20 : 3"
                v-bind="props"
                :subtitle="application?.doctorInfo?.specialty"
                :title="`${application?.doctorInfo?.title} ${application?.doctorInfo?.lastName}`"
              >
                <template v-slot:prepend>
                  <v-avatar size="70">
                    <v-img
                      :src="
                        application?.profileUrl != ''
                          ? application?.profileUrl
                          : require('../../../assets/blank.png')
                      "
                    ></v-img>
                  </v-avatar>
                </template>
                <template v-slot:append>
                  <v-hover>
                    <template v-slot:default="{ isHovering, props }">
                      <v-avatar
                        v-bind="props"
                        :size="isHovering ? 100 : 70"
                        :class="isHovering ? 'pointer' : undefined"
                      >
                        <v-img
                          :src="
                            application?.doctorInfo?.photo ??
                            require('../../../assets/blank.png')
                          "
                          @click="
                            $router.push(
                              `/doctors/${application.doctorInfo._id}`
                            )
                          "
                        ></v-img>
                      </v-avatar>
                    </template>
                  </v-hover>
                </template>

                <v-card-text class="mt-3">
                  <div>
                    {{ $t('application-line') }}
                    <span style="font-weight: 700">
                      {{ FormatDateFunction(application?.startDate) }}
                    </span>
                  </div>
                  <div>
                    {{ $t('status-line') }}
                    {{ statusMapping[application?.status] }}
                  </div></v-card-text
                >
                <v-alert
                  v-if="takenBackMessage"
                  :title="takenBackMessage"
                  type="info"
                  variant="tonal"
                ></v-alert>

                <v-btn
                  class="mt-2"
                  color="primary"
                  :loading="takingBack"
                  @click="$router.push(`application/${application._id}`)"
                >
                  {{ $t('to-application') }}
                </v-btn>
              </v-card>
            </template>
          </v-hover>
        </div>
      </div>
    </div>
    <div v-if="applications.length == 0">{{ $t('no-application') }}</div>
  </div>
</template>

<script lang="ts">
import { Applicant, Application, Student } from '@/types'
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserActions } from '@/stores/user-actions'
import router from '@/router'
import moment from 'moment'
import { FormatDateFunction } from '../../../types/spec'
export default defineComponent({
  components: {},
  name: 'ApplicationsProfileComponent',

  props: {
    studentProfileUrl: {
      type: String as () => '',
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n()
    const userAction = useUserActions()
    const requestResponse = ref({})
    const applications = ref<Application[]>([])
    const application = ref<Applicant>()
    const isLoading = ref(false)
    const smallWindow = ref(window.innerWidth < 1000)
    const takingBack = ref(false)
    const takenBackMessage = ref()

    onMounted(async () => {
      fetchApplications()
    })

    const fetchApplications = async () => {
      isLoading.value = true

      const response = await userAction.getApplications()
      requestResponse.value = response
      if (response.success) {
        applications.value = response.result as Application[]
      }
      isLoading.value = false
    }
    const statusMapping: Record<string, string> = computed(() => {
      return {
        CREATED: t('status.open'),
        REVERT: t('status.back'),
        REJECTED: t('status.rejected'),
        ACCEPTED: t('status.accepted'),
      }
    }).value

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    onMounted(() => {
      window.addEventListener('resize', updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWindowSize)
    })

    return {
      requestResponse,
      isLoading,
      applications,
      statusMapping,
      takingBack,
      takenBackMessage,

      t,
      fetchApplications,
      smallWindow,

      application,
      FormatDateFunction,
    }
  },
  methods: {},
})
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 25%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}
.pointer {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
}
</style>
