<template>
  <v-card rounded="lg">
    <v-card-title class="d-flex justify-space-between align-center mb-5">
      <div>{{ $t("hospital.invitations") }}</div>
      <v-btn icon="mdi-close" variant="text" @click="$emit('close')"></v-btn>
    </v-card-title>
    <v-carousel
      light
      hide-delimiter-background
      :show-arrows="false"
      height="auto"
      color="black"
    >
      <v-carousel-item v-for="(invite, index) in invites" :key="index">
        <v-card-title class="d-flex justify-space-between align-center mb-5">
          <div>{{ $t("hospital.invitation-line") }} {{ invite.name }}</div>
        </v-card-title>
        <v-card-text>
          {{ invite.name }} {{ $t("hospital.invitation-line-1") }}
        </v-card-text>
        <v-card-text>{{ $t("hospital.invitation-line-2") }} </v-card-text>

        <v-card-actions class="mb-10">
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('hospital.reject')"
            color="error"
            @click="rejectInvite(invite._id)"
          ></v-btn>
          <v-btn
            :text="$t('hospital.accept')"
            color="#3fb984"
            @click="acceptInvite(invite._id)"
          ></v-btn>
        </v-card-actions>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import {
  ref,
  onMounted,
  defineEmits,
  onBeforeUnmount,
  computed,
  defineProps,
} from "vue"
import { Invite } from "@/types"
import { useToast } from "vue-toast-notification"
import { doctorActions } from "@/stores/doctor-actions"

const props = defineProps<{ invites: Invite[] }>()
const emit = defineEmits(["close"])
const { t } = useI18n()
const docAction = doctorActions()
const invites = ref(props.invites)
const $toast = useToast()
const smallWindow = ref(window.innerWidth < 1000)
const requestResponse = ref()

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}

onMounted(async () => {
  window.addEventListener("resize", updateWindowSize)
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateWindowSize)
})

const rejectInvite = async (id: string): Promise<void> => {
  const response = await docAction.rejectHospitalInvite(id)
  requestResponse.value = response
  if (response.success) {
    $toast.success(t("invite-accept-successful"), {
      position: "bottom-left",
    })
  } else {
    $toast.error(response.message ?? "invite-accept-unsuccessful", {
      position: "bottom-left",
    })
  }
  emit("close")
}
const acceptInvite = async (id: string): Promise<void> => {
  const response = await docAction.acceptHospitalInvite(id)
  requestResponse.value = response
  if (response.success) {
    $toast.success(t("invite-accept-successful"), {
      position: "bottom-left",
    })
  } else {
    $toast.error(response.message ?? "invite-reject-unsuccessful", {
      position: "bottom-left",
    })
  }
  emit("close")
}
</script>

<style scoped></style>
