<template>
  <request-status :response="requestResponse" :isLoading="loading" />
  <div class="mt-5" align="center">
    <div class="mt-5">
      <v-tooltip location="bottom">
        <template v-slot:activator="{ props }">
          <v-img
            v-bind="props"
            :width="163"
            style="border-radius: 50%"
            cover
            :src="
              photoUrl != '' ? photoUrl : require('../../../assets/blank.png')
            "
            @click="onButtonClick"
          ></v-img>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="image/*"
            @change="onImageUpload"
          />
        </template>
        {{ $t("image-upload") }}
      </v-tooltip>
    </div>
    <div>
      <v-dialog v-model="showCropper">
        <v-sheet
          border="md"
          class="pa-6 text-black mx-auto"
          :width="smallWindow ? 320 : 600"
          color="white"
        >
          <h4 class="text-h5 font-weight-bold mb-4">
            <cropper
              class="cropper"
              :src="localImageUrl"
              :stencil-props="{
                aspectRatio: 10 / 11,
              }"
              @change="change"
            />
            <div align="center" class="mt-3">
              <v-btn color="error" @click="showCropper = false">{{
                $t("go-back")
              }}</v-btn>
              <v-btn class="ml-2" color="green" @click="saveImage">{{
                $t("save")
              }}</v-btn>
            </div>
          </h4>
        </v-sheet>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useUserActions } from "@/stores/user-actions"
import { Cropper } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"
import { doctorActions } from "@/stores/doctor-actions"
import { hospitalActions } from "@/stores/hospital-actions"
export default defineComponent({
  name: "PhotoUpload",
  components: {
    Cropper,
  },

  props: {
    photo: {
      type: String,
    },
    isHospital: {
      type: Boolean,
    },
    isStudent: {
      type: Boolean,
    },
    isDoctor: {
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n()
    const smallWindow = ref(false)
    const photoUrl = ref<string>("")
    const showCropper = ref(false)
    const localImageUrl = ref<string>("")
    const requestResponse = ref()
    const loading = ref(false)
    const isSelecting = ref(false)
    const canvas = ref()
    onMounted(async () => {
      photoUrl.value = props.photo ?? ""
    })

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })

    return {
      showCropper,
      localImageUrl,
      photoUrl,
      requestResponse,
      loading,
      isSelecting,
      canvas,
      smallWindow,
    }
  },
  methods: {
    onButtonClick() {
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      if (this.$refs.uploader instanceof HTMLInputElement) {
        this.$refs.uploader.click()
      }
    },
    change({ coordinates, canvas }: any) {
      this.canvas = canvas
    },
    saveImage() {
      const hospitalAction = hospitalActions()
      const doctorAction = doctorActions()
      const studentAction = useUserActions()

      this.canvas.toBlob(async (blob: Blob) => {
        const formData = new FormData()
        formData.append("file", blob)
        if (this.isHospital) {
          const response = await hospitalAction.uploadImage(formData)
          this.requestResponse = response
        } else if (this.isDoctor) {
          const response = await doctorAction.uploadImage(formData)
          this.requestResponse = response
        } else if (this.isStudent) {
          const response = await studentAction.uploadImage(formData)
          this.requestResponse = response
        }

        if (this.requestResponse.success) {
          this.photoUrl = (this.requestResponse.result as string[])[0]
        }
        this.$emit("photo-uploaded", this.photoUrl)
        this.showCropper = false
      })
    },

    onImageUpload(e: any) {
      const image = e.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(image)

      reader.onload = async (e) => {
        this.localImageUrl = e?.target?.result as string
        this.showCropper = true
      }
    },
  },
})
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 25%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}

@media screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
}
</style>
