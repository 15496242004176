<template>
  <v-badge
    v-if="filterNumbers"
    :content="filterNumbers"
    color="#25344d"
    class="ml-5 mr-5 mt-2 mb-5"
  >
    <v-btn
      icon="mdi-filter-variant"
      color="#3fb984"
      size="small"
      @click="showFilterDialog = true"
    >
    </v-btn>
  </v-badge>
  <v-btn
    v-else
    class="ml-5 mr-5 mt-2 mb-5"
    icon="mdi-filter-variant"
    color="#3fb984"
    size="small"
    @click="showFilterDialog = true"
  >
  </v-btn>

  <v-dialog max-width="500" v-model="showFilterDialog">
    <v-card rounded="lg">
      <v-card-title class="d-flex justify-space-between mb-5">
        <div></div>
        <div class="mt-2">Filter</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click="showFilterDialog = false"
        ></v-btn>
      </v-card-title>

      <div class="d-flex justify-space-evenly">
        <v-tabs
          v-model="filterTabs"
          show-arrows
          bg-color="#25344d"
          fixed-tabs
          style="width: 100%"
        >
          <v-tab value="privateClinic"> {{ $t("clinics") }}</v-tab>

          <v-tab value="hospitalAttached"> {{ $t("hospitals") }}</v-tab>
        </v-tabs>
      </div>
      <v-window v-model="filterTabs">
        <v-window-item value="privateClinic">
          <div class="mt-2" align="center">
            <v-autocomplete
              class="ml-4 mr-4"
              prepend-inner-icon="mdi-doctor"
              v-model="chosenSpecialty"
              :items="
                locale == 'de' ? SPECIALIZATION_DATA_DE : SPECIALIZATION_DATA_EN
              "
              :label="$t('search.specialty')"
              outlined
              :menu-props="{ width: '700px' }"
              style="min-width: 250px"
            ></v-autocomplete>
          </div>
        </v-window-item>

        <v-window-item value="hospitalAttached">
          <div class="mt-2" align="center">
            <v-autocomplete
              v-model="chosenHospital"
              label="Hospitals"
              :items="hospitals"
              :item-title="getHospitalName"
              :item-value="getHospitalValue"
              style="width: 90%"
            >
              <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props">
                  <template v-slot:subtitle
                    >{{ item.raw.hospitalLocation }},
                    {{ item.raw.hospitalLand }}
                  </template>
                </v-list-item>
              </template></v-autocomplete
            >

            <v-autocomplete
              v-model="chosenDepartment"
              label="Abteilung"
              :items="departments"
              :item-title="getDepartmentName"
              :item-value="getDepartmentValue"
              style="width: 90%"
            >
            </v-autocomplete>
          </div>
        </v-window-item>
      </v-window>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="flat" class="mr-2" @click="resetFilter">Reset</v-btn>
        <v-btn color="#3fb984" variant="flat" @click="applyFilter">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, ref } from "vue"
import { onMounted } from "vue"
import { commonStore } from "@/stores/common-store"
import { SPECIALIZATION_DATA_DE, SPECIALIZATION_DATA_EN } from "@/types/spec"
import { Department, Hospital } from "@/types"

interface SearchFilterDoc {
  includeHospital: boolean
  includeClinic: boolean
  hospitalValue: string | null
  departmentValue: string | null
  clinic: string | null
  reset: boolean
}

const emit = defineEmits(["filter-applied", "close"])
const props = defineProps({
  type: {
    type: String,
  },
  openMenu: {
    type: Boolean,
  },
  specialty: {
    type: String,
  },
  hospitalId: {
    type: String,
  },
  department: {
    type: String,
  },
})

const includeHospital = ref(false)
const includeClinic = ref(false)
const publicStore = commonStore()
const hospitals = ref()
const showFilterDialog = ref(false)
const chosenHospital = ref<string | null>(null)
const chosenDepartment = ref<string | null>(null)
const chosenSpecialty = ref<string | null>(null)
const locale = ref(localStorage.getItem("locale") ?? "de")
const filterTabs = ref<string | null>(null)

const departments = ref<Department[]>([])

onMounted(async () => {
  if (props.type) {
    filterTabs.value = props.type
  }

  await getHospitals()
  await loadHospitalDepartments()
  loadFilters()
})

const filterNumbers = computed((): number => {
  var numberOfFilters = 0
  if (props.type == "hospitalAttached") {
    numberOfFilters++
    if (props.hospitalId) {
      numberOfFilters++
    }
    if (props.department) {
      numberOfFilters++
    }
  } else if (props.type == "privateClinic") {
    numberOfFilters++
    if (props.specialty) {
      numberOfFilters++
    }
  }
  return numberOfFilters
})

const getDepartmentName = computed(() => (department: Department): string => {
  return locale.value == "de" ? department.de : department.en
})
const getDepartmentValue = (department: Department): string => {
  return department.value
}

const loadFilters = () => {
  if (props.type == "privateClinic" && props.specialty) {
    chosenSpecialty.value = props.specialty
  } else if (props.type == "hospitalAttached") {
    if (props.hospitalId) {
      chosenHospital.value = props.hospitalId
    }
    if (props.department) {
      chosenDepartment.value = props.department
    }
  }
}

const loadHospitalDepartments = async (): Promise<void> => {
  const response = await publicStore.getDepartments()
  if (response.success) {
    departments.value = response.result as Department[]
  }
}

const applyFilter = async () => {
  if (filterTabs.value == "privateClinic") {
    includeClinic.value = true
  } else if (filterTabs.value == "hospitalAttached") {
    includeHospital.value = true
  }
  const searchFilter: SearchFilterDoc = {
    includeHospital: includeHospital.value,
    includeClinic: includeClinic.value,
    hospitalValue: chosenHospital.value,
    departmentValue: chosenDepartment.value,
    clinic: chosenSpecialty.value,
    reset: false,
  }
  emit("filter-applied", searchFilter)
  includeHospital.value = false
  includeClinic.value = false

  showFilterDialog.value = false
}

const resetFilter = async () => {
  const searchFilter: SearchFilterDoc = {
    includeHospital: false,
    includeClinic: false,
    hospitalValue: null,
    departmentValue: null,
    clinic: null,
    reset: true,
  }

  emit("filter-applied", searchFilter)
  showFilterDialog.value = false
}
onMounted(async () => {
  window.addEventListener("locale-changed", updateLocale)
  window.addEventListener("locale-changed", loadFilters)
})

const updateLocale = () => {
  locale.value = localStorage.getItem("locale") ?? "de"
}

const getHospitalName = (hospital: any): string => {
  return hospital.name
}

const getHospitals = async (): Promise<void> => {
  const response = await publicStore.getAllHospitals()
  if (response.success) {
    hospitals.value = response.result
  }
}

const getHospitalValue = (hospital: any): string => {
  return hospital._id
}
</script>

<style scoped></style>
