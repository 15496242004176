<template>
  <HeaderComponent />
  <request-status :response="responseStatus" :isLoading="false" />
  <div>
    <v-card
      class="mx-auto pa-12 pb-8 my-15"
      elevation="8"
      max-width="448"
      rounded="lg"
    >
      <div align="center">
        <v-img
          width="100px"
          :src="require('../../../../assets/stud-team1.png')"
        ></v-img>
      </div>

      <v-card-title class="mb-3" align="center">{{
        $t("student-login")
      }}</v-card-title>
      <div class="text-subtitle-1 text-medium-emphasis">
        {{ $t("account") }}
      </div>

      <v-text-field
        v-model="email"
        density="compact"
        :placeholder="$t('email')"
        prepend-inner-icon="mdi-email-outline"
        :rules="emailRules"
        variant="outlined"
        @keyup.enter="isInfoCompleted ? login() : undefined"
      ></v-text-field>

      <div
        class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
      >
        {{ $t("password") }}

        <a
          class="text-caption text-decoration-none text-blue"
          @click="router.push('/forgot-password')"
          style="cursor: pointer"
        >
          {{ $t("forgot-password") }}</a
        >
      </div>

      <v-text-field
        v-model="password"
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible ? 'text' : 'password'"
        density="compact"
        :placeholder="$t('enter-password')"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        :rules="passwordRules"
        @click:append-inner="visible = !visible"
        @keyup.enter="isInfoCompleted ? login() : undefined"
      ></v-text-field>

      <v-btn
        class="mb-8"
        color="blue"
        size="large"
        variant="flat"
        block
        :loading="isLoading"
        :disabled="!isInfoCompleted"
        @click="login"
      >
        {{ $t("login") }}
      </v-btn>

      <v-card-text class="text-center">
        <a
          class="text-blue text-decoration-none"
          @click="router.push('/register')"
          style="cursor: pointer"
        >
          {{ $t("register") }}<v-icon icon="mdi-chevron-right"></v-icon>
        </a>
      </v-card-text>
    </v-card>
  </div>
  <FooterComponent></FooterComponent>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { useUserStore } from "../../../../stores/user-auth"
import { useI18n } from "vue-i18n"
import HeaderComponent from "@/components/Header/HeaderComponent.vue"
import router from "@/router"
import { useUserActions } from "@/stores/user-actions"
import { useMeta } from "vue-meta"
import FooterComponent from "@/components/Footer/FooterComponent.vue"

const { t } = useI18n()
const email = ref<string>("")
const password = ref<string>("")
const visible = ref(false)
const isLoading = ref(false)
const responseStatus = ref({})

const emailRules = [
  (v: string) => !!v || t("email-required"),
  (v: string) => /.+@.+\..+/.test(v) || t("email-authentic"),
]

const passwordRules = [(v: string) => !!v || t("password-required")]

const isInfoCompleted = computed(() => {
  const isEmailInvalid = emailRules.some((rule) => {
    const result = rule(email.value)
    return typeof result === "string"
  })
  const isPasswordInValid = passwordRules.some((rule) => {
    const result = rule(password.value)
    return typeof result === "string"
  })

  return (
    email.value != "" &&
    password.value != "" &&
    !isPasswordInValid &&
    !isEmailInvalid
  )
})
useMeta(
  computed(() => ({
    title: "Medcircle",
  }))
)
const login = async () => {
  isLoading.value = true
  const userStore = useUserStore()
  const userAction = useUserActions()
  const credentials = {
    email: email.value,
    password: password.value,
  }

  const response = await userStore.login(credentials)
  responseStatus.value = response

  if (response.success) {
    localStorage.setItem("accessToken", response?.result?.token)
    userAction.isTokenValid()
    userStore.isAuthenticated = true
    const docId = localStorage.getItem("docId")
    const hospitalId = localStorage.getItem("hospitalId")
    if (docId) {
      localStorage.removeItem("docId")
      router.push(`doctors/${docId}`)
    } else if (hospitalId) {
      router.push(`/hospital/${hospitalId}`)
    } else {
      router.push("/")
    }
  }
  isLoading.value = false
}
</script>
