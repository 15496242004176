<template>
  <v-dialog v-model="broadcast">
    <v-sheet
      border="md"
      class="pa-6 text-black mx-auto"
      color="white"
      style="border-radius: 7%"
      :min-width="smallWindow ? '350px' : '400px'"
      :max-width="smallWindow ? '360px' : '600px'"
    >
      <div class="flex" v-if="studentView">
        <div class="flex-item mt-3">
          <h4>{{ $t('message-from') }}</h4>
          <p>{{ doctorName }}</p>
        </div>
        <div class="flex-item ml-5" style="align-self: flex-end">
          <v-img
            :src="doctorProfile"
            width="100px"
            style="border-radius: 50%"
          ></v-img>
        </div>
      </div>

      <h2 align="center" v-if="!studentView">
        {{ editView ? $t('edit-message') : $t('new-message') }}
      </h2>
      <div v-if="studentView" class="mt-2">
        <h4 align="start">
          {{ $t('title') }}
        </h4>
        <p class="mb-5">{{ title }}</p>
      </div>
      <v-text-field
        v-else
        class="mt-2"
        v-model="title"
        prepend-inner-icon="mdi-email-fast-outline"
        :label="$t('title')"
        variant="outlined"
        persistent-hint
      ></v-text-field>
      <h4 align="start" v-if="studentView">
        {{ $t('message') }}
      </h4>

      <div v-if="studentView" v-html="editorContent"></div>
      <QuillEditor
        v-else
        v-model:content="editorContent"
        contentType="html"
        style="min-height: 200px"
        :theme="!studentView ? 'snow' : ''"
        toolbar="essential"
        :read-only="studentView"
      />
      <div align="end" v-if="!studentView">
        <v-btn class="mt-4" color="error" @click="$emit('closeDialog')">{{
          $t('go-back')
        }}</v-btn
        ><v-btn
          v-if="!editView"
          class="ml-3 mt-4"
          color="green"
          @click="sendContent"
          >{{ $t('send') }}</v-btn
        >
        <v-btn v-else class="ml-3 mt-4" color="green" @click="updateContent">{{
          $t('done')
        }}</v-btn>
      </div>
      <div v-else align="end">
        <v-btn class="mt-4" color="primary" @click="$emit('closeDialog')">{{
          $t('back')
        }}</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  defineProps,
  ref,
  onMounted,
  computed,
  Ref,
  defineEmits,
  onBeforeUnmount,
} from 'vue'
import { Delta } from '@vueup/vue-quill'
import { broadcastingActions } from '@/stores/broadcasting-store'
import { RequestResponse } from '@/types'
import { useToast } from 'vue-toast-notification'
import { Message } from '@/types'
const props = defineProps<{
  content: Delta | undefined
  title: string
  editView: boolean
  broadcast: boolean
  id?: string
  studentView: boolean
  doctorName?: string
  doctorProfile?: string
}>()

const { t } = useI18n()
const emit = defineEmits(['closed', 'closeDialog'])
const broadcast = ref(props.broadcast)
const editorContent = ref<Delta | undefined>(props.content)
const broadcasting = broadcastingActions()
const loading = ref(false)
const title = ref<string>(props.title)
const $toast = useToast()
const requestResponse = ref<RequestResponse>()
const id = ref(props.id)
const editView = ref(props.editView)
const doctorName = ref(props.doctorName)
const doctorProfile = ref(props.doctorProfile)
const smallWindow = ref(window.innerWidth < 1000)

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}

onMounted(() => {
  window.addEventListener('resize', updateWindowSize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowSize)
})

const updateContent = async () => {
  loading.value = true
  const contents = {
    title: title.value,
    content: editorContent.value,
  }

  const response = await broadcasting.updateMessage(contents, id.value ?? '')
  requestResponse.value = response
  if (response.success) {
    $toast.success(t('successfully-updated'), {
      position: 'top-right',
    })
  } else {
    $toast.error(t('successfully-not-updated'), {
      position: 'top-right',
    })
  }

  emit('closed', requestResponse.value)
}

const sendContent = async () => {
  loading.value = true
  const contents = {
    title: title.value,
    content: editorContent.value,
  }

  const response = await broadcasting.broadcastMessage(contents)
  requestResponse.value = response
  if (response.success) {
    $toast.success(t('successfully-broadcasted'), {
      position: 'top-right',
    })
  } else {
    $toast.error(t('successfully-not-broadcasted'), {
      position: 'top-right',
    })
  }
  emit('closed', requestResponse.value)

  broadcast.value = false
}
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 35%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}
</style>
