import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28c59b41"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  align: "center",
  class: "mt-5"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex flex-wrap" }
const _hoisted_4 = { class: "flex-area-item" }
const _hoisted_5 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_6 = { class: "flex-area-item" }
const _hoisted_7 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_8 = { class: "flex-area-item" }
const _hoisted_9 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_10 = { class: "flex-area-item" }
const _hoisted_11 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_status = _resolveComponent("request-status")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_request_status, {
      response: _ctx.requestResponse,
      isLoading: _ctx.isLoading
    }, null, 8, ["response", "isLoading"]),
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("doctor.intern-info")), 1),
    _createVNode(_component_v_divider, { class: "mt-4" }),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_v_col, { class: "mt-10 mb-10" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("doctor.famulatur")), 1),
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.internshipSpecialty,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internshipSpecialty) = $event)),
                    class: "ml-2",
                    density: "compact",
                    placeholder: _ctx.$t('doctor.this-internship-text'),
                    variant: "outlined",
                    rows: "4",
                    style: {"min-width":"300px"}
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("doctor.area")), 1),
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.area,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.area) = $event)),
                    class: "ml-2",
                    density: "compact",
                    placeholder: _ctx.$t('doctor.area-text'),
                    variant: "outlined",
                    rows: "4",
                    style: {"min-width":"300px"}
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("doctor.financial-help")), 1),
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.financialHelp,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.financialHelp) = $event)),
                    class: "ml-2",
                    density: "compact",
                    placeholder: _ctx.$t('doctor.financial-help-text'),
                    variant: "outlined",
                    rows: "4",
                    style: {"min-width":"300px"}
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("doctor.requirement")), 1),
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.requirements,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.requirements) = $event)),
                    class: "ml-2",
                    density: "compact",
                    placeholder: _ctx.$t('doctor.requirement-text'),
                    variant: "outlined",
                    rows: "4",
                    style: {"min-width":"300px"}
                  }, null, 8, ["modelValue", "placeholder"])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_footer, { style: {"display":"flex","width":"96%","justify-content":"end","background-color":"#f2f2f2"} }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", null, [
                  _createVNode(_component_v_btn, {
                    class: "mr-10",
                    rounded: "lg",
                    color: "#3fb984",
                    variant: "flat",
                    size: "large",
                    width: "150",
                    loading: _ctx.isSaving,
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.saveProfileData()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("save")), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ])
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}