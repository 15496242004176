import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19ae2e3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "container-title",
  align: "center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("our-doctors")), 1),
    _createVNode(_component_v_row, {
      class: "mt-4",
      justify: "center",
      align: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          class: "mx-10 my-5",
          width: "500",
          title: "Dr. Philipp Gross",
          subtitle: _ctx.$t('general-medicine'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/doctors/655f03b38351b69e9e23c461')))
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_v_avatar, { color: "blue-darken-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { icon: "mdi-shield-check-outline" })
              ]),
              _: 1
            })
          ]),
          append: _withCtx(() => [
            _createVNode(_component_v_avatar, { size: "100" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  src: "https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2024/01/13/1705173797902.blob",
                  alt: "Philipp Gross"
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, { class: "ml-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-map-marker")
                  ]),
                  _: 1
                }),
                _createTextVNode(" München, Germany")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["subtitle"]),
        _createVNode(_component_v_card, {
          class: "mx-10 my-5",
          width: "500",
          title: "Dr. Tobias Weber",
          subtitle: _ctx.$t('general-medicine'),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/doctors/6569891578adb328249cecd6')))
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_v_avatar, { color: "blue-darken-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { icon: "mdi-shield-check-outline" })
              ]),
              _: 1
            })
          ]),
          append: _withCtx(() => [
            _createVNode(_component_v_avatar, { size: "100" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  src: "https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2023/12/20/1703085778619.blob",
                  alt: "Tobias Weber"
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, { class: "ml-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-map-marker")
                  ]),
                  _: 1
                }),
                _createTextVNode(" München, Germany")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["subtitle"]),
        _createVNode(_component_v_card, {
          class: "mx-10 my-5",
          width: "500",
          title: "Dr. Monika Schmidmayr ",
          subtitle: _ctx.$t('gynecology'),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/doctors/656645ad6600fbcdcc56ba57')))
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_v_avatar, { color: "blue-darken-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { icon: "mdi-shield-check-outline" })
              ]),
              _: 1
            })
          ]),
          append: _withCtx(() => [
            _createVNode(_component_v_avatar, { size: "100" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  src: "https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2024/03/07/1709848427785.blob",
                  alt: "Monika Schmidmayr"
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, { class: "ml-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-map-marker")
                  ]),
                  _: 1
                }),
                _createTextVNode(" Wolnzach, Germany")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["subtitle"]),
        _createVNode(_component_v_card, {
          class: "mx-10 my-5",
          width: "500",
          title: "Dr. Ursula Glombik-Lukassek  ",
          subtitle: _ctx.$t('general-medicine'),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/doctors/6565d8d67ad7aca7836f8086')))
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_v_avatar, { color: "blue-darken-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { icon: "mdi-shield-check-outline" })
              ]),
              _: 1
            })
          ]),
          append: _withCtx(() => [
            _createVNode(_component_v_avatar, { size: "100" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  src: "https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2024/03/07/1709848647200.blob",
                  alt: "Ursula Glombik-Lukassek"
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, { class: "ml-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-map-marker")
                  ]),
                  _: 1
                }),
                _createTextVNode(" Nördlingen , Germany")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["subtitle"]),
        _createVNode(_component_v_card, {
          class: "mx-10 my-5",
          width: "500",
          title: "Dr. Ute Neidlinger  ",
          subtitle: _ctx.$t('general-medicine'),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push('/doctors/6565ecec7ad7aca7836f8088')))
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_v_avatar, { color: "blue-darken-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { icon: "mdi-shield-check-outline" })
              ]),
              _: 1
            })
          ]),
          append: _withCtx(() => [
            _createVNode(_component_v_avatar, { size: "100" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  src: "https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2023/11/28/1701179297801.JPG",
                  alt: "Ute Neidlinger"
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, { class: "ml-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-map-marker")
                  ]),
                  _: 1
                }),
                _createTextVNode(" Hebertsfelden, Germany")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["subtitle"]),
        _createVNode(_component_v_card, {
          class: "mx-10 my-5",
          width: "500",
          title: "Frau Susanne Römer  ",
          subtitle: _ctx.$t('inner-general-medicine'),
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push('/doctors/65e0691ff21af3c68d760529')))
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_v_avatar, { color: "blue-darken-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { icon: "mdi-shield-check-outline" })
              ]),
              _: 1
            })
          ]),
          append: _withCtx(() => [
            _createVNode(_component_v_avatar, { size: "100" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  src: "https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2024/04/22/1713807551420.blob",
                  alt: "Susanne Römer"
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, { class: "ml-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-map-marker")
                  ]),
                  _: 1
                }),
                _createTextVNode(" Freising, Germany")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["subtitle"])
      ]),
      _: 1
    })
  ], 64))
}