<template>
  <div class="bgimg">
    <v-row>
      <v-col
        :cols="smallWindow ? 12 : 5"
        style="margin-top: 50px"
        :style="
          smallWindow ? { marginBottom: '0px' } : { marginBottom: '50px' }
        "
      >
        <div align="center">
          <v-img
            :src="require('../../assets/med-students.jpg')"
            width="500"
          ></v-img>
        </div>
      </v-col>
      <v-col
        :cols="smallWindow ? 12 : 7"
        style="margin-bottom: 50px"
        :style="smallWindow ? { marginTop: '0px' } : { marginTop: '50px' }"
      >
        <h1 class="mt-15" align="center">
          {{ $t("practical-reality") }}
        </h1>
        <div align="center">
          <p style="font-size: large" class="mt-4">
            {{ $t("practical-answer") }}
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"

export default defineComponent({
  components: {},
  name: "SecondComponent",

  setup() {
    const { t } = useI18n()

    const smallWindow = ref(window.innerWidth < 1000)

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })
    return {
      t,
      smallWindow,
    }
  },
})
</script>

<style>
.bgimg {
  background-size: cover; /* Ensures the image covers the entire div without stretching */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;
  background-image: url("../../assets/background.jpg");
}
</style>
