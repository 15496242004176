<template>
  <HeaderComponent></HeaderComponent>
  <div align="center">
    <div
      align="start"
      class="mt-10 agb"
      style="font-family: Verdana, Geneva, Tahoma, sans-serif"
    >
      <h1 class="mt-5 mb-5" style="font-size: xxx-large">AGB</h1>

      <div>
        <h3>1. Pr&auml;ambel: Leistungsumfang</h3>
        <p class="mt-2">
          <span
            >Grundsätzlich fungiert medcircle als die digitale Schnittstelle
            zwischen Ärzten, Krankenhäusern und den Studierenden der Medizin.
            Wir unterstützen die Studierenden, indem wir ihnen die Möglichkeit
            bieten, sich um Famulaturen, Blockpraktika oder
            Weiterbildungsmöglichkeiten zu bewerben und mit Ärzten oder anderem
            verantwortlichen Personal in Kontakt zu treten. Auf der anderen
            Seite ermöglichen wir Ärzten, Krankenhäusern und dem Personal aus
            diesen Einrichtungen, Profile auf medcircle zu erstellen und
            Bewerbungen sowie Studierende zu verwalten. Dabei fungieren wir als
            Verwaltungslösung für Ärzte, Krankenhäuser. Besonderen Wert legen
            wir darauf, dass Ihre Daten nicht missbraucht werden. Unser
            vorrangiges Anliegen besteht darin, die Verwaltung für Studierende
            und Ärzte zu vereinfachen. Wir sind uns bewusst, dass wir noch nicht
            perfekt sind. Um unseren Service kontinuierlich zu verbessern,
            benötigen wir Ihre Unterstützung. Nur gemeinsam können wir Schritt
            für Schritt ein medizinisches digitales europäisches Netzwerk
            aufbauen. Transparenz bezüglich unserer Geschäftsprozesse und der
            Verwendung Ihrer Daten bildet für uns den Grundstein einer
            nachhaltigen Beziehung. Daher beantworten wir gerne jederzeit
            persönlich alle Fragen in diesem Zusammenhang.
          </span>
        </p>
        <h3 class="margin-top">1.1. Anbieter</h3>
        <p>
          <span
            >Die Medcircle Plattform ist ein online Service, vertreten durch
            Paras Dwivedi, E-mail: </span
          ><span>admin@medcircle.eu</span
          ><span
            >&nbsp;Die aktuellen Kontaktinformationen finden Sie im
            Impressum.</span
          >
        </p>
        <h3 class="margin-top">1.2. Angebot nur f&uuml;r Nutzer in der EU</h3>
        <p>
          <span
            >Medcircle richtet sich an Studenten, Ärzten, Krankenhäusern im
            europ&auml;ischem Raum, d.h. mit Wohnsitz oder Gesch&auml;ftssitz in
            Europa.</span
          >
        </p>
        <h3 class="margin-top">1.3. Vertragsgegenstand</h3>
        <p>
          <span
            >Wir übernehmen die Funktion eines Verwaltungsmanagers für
            Studierende und Ärzte oder anderes verantwortliches Personal und
            helfen bei der Verwaltung von Famulaturen, Blockpraktika und
            Weiterbildungsmöglichkeiten.</span
          >
        </p>
        <h3 class="margin-top">
          2. Inhalt: Was wird in diesen Bedingungen geregelt?
        </h3>
        <h3 class="margin-top">2.1. Geltung unserer AGB</h3>
        <p>
          <span
            >Um die Medcircle Plattform zu nutzen, müssen Sie diese Allgemeinen
            Gesch&auml;ftsbedingungen akzeptieren. Diese gelten
            ausschlie&szlig;lich.</span
          >
        </p>

        <h3 class="margin-top">2.2. Vermittlung von Dienstleistungen</h3>
        <p>
          <span
            >Medcircle ist stets der Vermittler von Dienstleistungen, nicht
            deren Anbieter und nicht der Leistungserbringer.</span
          >
        </p>
        <h3 class="margin-top">
          2.3.1 Verh&auml;ltnis zu Dienstleistungsanbietern
        </h3>
        <p>
          <span
            >Das direkte Vertragsverh&auml;ltnis nach Vermittlung besteht stets
            zwischen dem Anbieter einer Dienstleistung als Leistungserbringer
            und Ihnen als Leistungsnehmer. F&uuml;r die
            Dienstleistungsvertr&auml;ge gelten die Vertragsbedingungen sowie
            die Allgemeinen Bedingungen des jeweiligen Anbieters.</span
          >
        </p>
        <h3 class="margin-top">
          3. Unsere Leistungen: Welche Funktionen &uuml;bernimmt Medcircle
          f&uuml;r Sie?
        </h3>
        <p>
          <span
            >Generell können Studenten und Ärzten das Medcircle platfrom
            kostenfrei nutzen. Allerdings kann das in der Zukunft geändert
            werden.
          </span>
        </p>
        <h3 class="margin-top">3.1. Für Studenten</h3>
        <h3 class="margin-top">3.1.1 Suchportale</h3>
        <p>
          <span
            >Wir stellen Ihnen ein Suchportal zur Verfügung, in dem Sie freie
            verfügbare Famulaturplätze bei Ärzten oder Krankenhäusern zu Ihren
            gewünschten Zeiten/ Städten/ Vertifungen suchen und sich darauf
            bewerben können.
          </span>
        </p>
        <h3 class="margin-top">3.1.2 Bewerbungsportale</h3>
        <p>
          <span
            >Wir bieten Ihnen die Möglichkeit, sich gleichzeitig auf unserer
            Plattform zu bewerben. Um den Bewerbungsprozess abzuschließen,
            müssen Sie die erforderlichen Informationen ausfüllen und Dateien
            wie Ihre Immatrikulationsbescheinigung, Ihren Lebenslauf und Ihr
            Motivationsschreiben hochladen. Nach Absenden der Bewerbung werden
            die ausgefüllten Informationen sofort an Ärzte/Krankenhäuser
            weitergeleitet.</span
          >
        </p>

        <h3 class="margin-top">3.2. Für Ärzten</h3>
        <h3 class="margin-top">3.1.1 Verwaltungssystem</h3>
        <p>
          <span
            >Die Ärzte/Krankenhäuser können Medcircle als ihr Verwaltungssystem
            nutzen, um die Famulanten zu verwalten. Sie können die Zeiten in
            ihren Profilen festlegen, zu denen sie Famulanten empfangen möchten,
            und die Bewerbungen einsehen, wenn sich ein Famulant bei ihnen
            beworben hat. Zurückgezogene Bewerbungen werden den Ärzten nicht
            angezeigt.
          </span>
        </p>
        <h3 class="margin-top">3.1.2 Brodcasting</h3>
        <p>
          <span
            >In den kommenden Wochen werden wir ihnen die Möglickkeiten bieten
            unsere Brodcasting funktionen zu nutzen mit denen Sie in einaml eine
            Nachricht an ihre Abonnenten schicken. Die Liste von ihre Abonnenten
            wird auch zu ihenen verfügbar sein.
          </span>
        </p>

        <h3 class="margin-top">
          4. Welche Rechte benötigen wir, um unsere lösung zu optimieren?
        </h3>

        <p>
          <span
            >Indem Sie die Medcircle-Plattform nutzen, erteilen Sie uns eine
            zeitlich unbefristete, bis zu Ihrem Widerruf gültige Vollmacht, Ihre
            Daten zu beziehen, zu speichern und zu analysieren. Diese Vollmacht
            ermöglicht es uns, Analysen durchzuführen. Die durchgeführte Analyse
            dient jedoch ausschließlich der Optimierung unserer Lösung und wird
            unter keinen Umständen oder Bedingungen mit externen Parteien
            geteilt.</span
          >
        </p>

        <h3 class="margin-top">
          5. Nutzungsbedingungen: In welchem Umfang darf ich Medcircle nutzen?
        </h3>
        <h3 class="margin-top">5.1. Nutzung nur f&uuml;r den Eigenbedarf</h3>
        <p>
          <span
            >Wir gew&auml;hren Medizinstudenten, Ärzten, Krankenhäusern zum
            Zwecke des Eigenbedarfs ein Nutzungsrecht f&uuml;r den Zugriff auf
            Medcircle und dessen pers&ouml;nliche, nicht-gewerbliche Nutzung.
            Sie d&uuml;rfen Medcircle nur in dem Umfang nutzen, wie es Ihnen
            diese Nutzungsbedingungen, unter Ber&uuml;cksichtigung gesetzlicher
            Bestimmungen, erlauben. Es besteht kein Anspruch auf ein
            Nutzungsrecht oder die von der Medcircle kostenfrei angebotenen
            Leistungen. Jeder Versto&szlig; gegen diese Nutzungsbedingungen oder
            weitere, einschl&auml;gige Bedingungen, sowie jede
            missbr&auml;uchliche Nutzung von Medcircle berechtigt uns, Ihnen das
            Nutzungsrecht ohne vorherige Mitteilung zu entziehen.</span
          >
        </p>
        <h3 class="margin-top">5.2. Ihre Pflichten als Nutzer</h3>
        <p>
          <span
            >(a) Jeder Nutzer der Dienste der Medcircle trägt die vollständige
            Verantwortung für seine Aktivitäten innerhalb der Medcircle-Website
          </span>
        </p>
        <p>
          <span
            >(b) Nutzer der Dienste der Medcircle sind verpflichtet, die
            rechtlichen Vorschriften einzuhalten, insbesondere nicht gegen
            bestehendes Recht der Bundesrepublik Deutschland zu verstoßen. Vor
            allem verpflichtet sich jeder Nutzer zur Einhaltung der geltenden
            Jugendschutzvorschriften. Das bedeutet u.a., dass die Dienste der
            Medcircle nicht für rechtswidrige Zwecke verwendet werden dürfen.
            Verboten ist insbesondere, Inhalte und/oder Äußerungen zu
            veröffentlichen, zu übermitteln oder zu verbreiten, die rechtswidrig
            sind, insbesondere mit beleidigendem, gewaltverherrlichendem,
            diskriminierendem oder pornographischem Inhalt. Das umfasst auch
            Inhalte, die über vom Nutzer gesetzte Links zu erreichen sind.</span
          >
        </p>
        <p>
          <span
            >(c) Darüber hinaus ist die Verwendung anstößiger Inhalte,
            doppeldeutiger Bezeichnungen und anderweitiger Darstellungen,
            untersagt, deren Rechtswidrigkeit vermutet wird aber nicht
            abschließend festgestellt werden kann.
          </span>
        </p>
        <p>
          <span
            >(d) Nutzeraktivitäten, die darauf ausgerichtet sind, die Dienste
            der Medcircle-website funktionsuntauglich zu machen oder zumindest
            deren Nutzung zu erschweren, sind verboten und können zivil- und
            strafrechtlich verfolgt werden. Untersagt sind insbesondere
            Maßnahmen, die die physikalische und logische Struktur der Dienste
            beeinflussen können.
          </span>
        </p>
        <p>
          <span
            >(e) Die Dienste der Medcircle dürfen von den Nutzern nicht zu
            kommerziellen Zwecken verwendet werden. Rücksichtsloses Werben,
            worunter insbesondere die massenhafte Versendung von Inhalten fällt,
            die der jeweilige Empfänger unaufgefordert erhält (insbesondere
            sogenanntes Spamming), ist untersagt.</span
          >
        </p>
        <p>
          <span
            >(f) Bei registrierungspflichtigen Diensten ist der Nutzer
            verpflichtet, im Falle des Missbrauchs seines Kontos die Medcircle
            von allen Ansprüchen Dritter freizustellen, die gegen die Medcircle
            in diesem Zusammenhang geltend gemacht werden. Des weiteren wird der
            Nutzer seinen Zugang zum Konto gegen die unbefugte Verwendung durch
            Dritte schützen und sein Passwort geheim halten. Der Nutzer haftet
            für jede durch sein Verhalten ermöglichte unbefugte Verwendung
            seines Kontos, soweit ihn ein Verschulden trifft. Der Nutzer hat der
            Medcircle umgehend zu benachrichtigen, falls er den begründeten
            Verdacht hat, dass ein Missbrauch seines Kontos vorliegt.
          </span>
        </p>

        <h3 class="margin-top">5.3. Benutzerkonto</h3>
        <p>
          <span
            >Als Nutzer von Medcircle legen wir f&uuml;r Sie automatisch ein
            Benutzerkonto an indem Sie alle benötige Informatinen speichern
            können.
          </span>
        </p>
        <p>
          <span
            >Es ist Ihre Verantwortung, f&uuml;r einen sicheren Umgang mit den
            damit verbundenen Zugangsinformationen zu sorgen. Wir gehen davon
            aus, dass alle Aktionen in Ihrem Benutzerkonto von Ihnen autorisiert
            sind und in Ihrem Namen und Auftrag erfolgen. Wir haften f&uuml;r
            keine Sch&auml;den, die daraus resultieren, dass Sie einem Dritten
            Zugriff auf Ihr Benutzerkonto einger&auml;umt haben und dieser es
            entgegen Ihren Vorgaben oder Auftr&auml;gen nutzt.</span
          >
        </p>
        <p>
          <span>5.4. Beendigung aus wichtigem Grund</span>
        </p>
        <p>
          <span
            >Wir k&ouml;nnen jederzeit und nach eigenem Ermessen Ihr Recht zur
            Nutzung von Medcircle inklusive Ihres Medcircle Benutzerkontos
            beenden, wenn Sie gegen diese Allgemeinen Gesch&auml;ftsbedingungen
            oder eine andere im Kontext von Medcircle stehende Richtlinie oder
            gesetzliche Regelungen versto&szlig;en haben oder wir aus einem
            anderen wichtigen Grund diese Entscheidung treffen.</span
          >
        </p>

        <h3 class="margin-top">7. Kommunikation</h3>
        <p>
          <span>7.1. Kommunikation per E-Mail</span>
        </p>
        <p>
          <span
            >Unsere Serviceleistung erfolgt im Wesentlichen online. Folglich
            senden wir Ihnen die Inforamtion zu ihre Bewerbung per E-Mail
            oder/und zeigen diese in Ihrem Benutzerprofil an. Falls wichtige
            Informationen fehlen, werden wir Sie ebenfalls &uuml;ber E-Mail
            kontaktieren. Durch die Nutzung von Medcircle r&auml;umen Sie uns
            das Recht ein, Ihnen Mitteilungen an die von Ihnen angegebene
            E-Mail-Adresse zu senden. Sie sind verantwortlich daf&uuml;r, dass
            Sie &uuml;ber die von Ihnen angegebene E-Mail-Adresse erreichbar
            sind. Es kann vorkommen, dass Nachrichten im Spam-Ordner Ihres
            E-Mail-Anbieters landen. Da wir dar&uuml;ber nicht informiert
            werden, liegt es in Ihrer Verantwortung, Ihren Spam-Ordner
            regelm&auml;&szlig;ig zu &uuml;berpr&uuml;fen und daf&uuml;r zu
            sorgen, dass E-Mails von Medcircle nicht als Spam eingestuft werden.
            F&uuml;r nicht erhaltene Nachrichten und daraus resultierender
            Konsequenzen &uuml;bernehmen wir keine Haftung.</span
          >
        </p>
        <p>
          <span>7.2. Vertraglich vereinbarte Schriftform</span>
        </p>
        <p>
          <span
            >Weiterhin stimmen Sie zu, dass die genannten Mitteilungen, die wir
            Ihnen elektronisch zukommen lassen, alle rechtlichen Anforderungen
            auf Schriftform gerecht werden. Diese Regelung hat keine Auswirkung
            auf Ihre gesetzlichen bestehenden Rechte.</span
          >
        </p>
        <h3 class="margin-top">8. Gew&auml;hrleistung und Haftung</h3>

        <p>
          <span>8.1. Keine Garantie auf Vollst&auml;ndigkeit</span>
        </p>
        <p>
          <span
            >Es ist unser Bestreben, soweit unter technischen und praktischen
            M&ouml;glichkeiten m&ouml;glich, alle Dienstleistungsanbietern und
            ihre eingegebene Inforamtionen zu überprüfen und feststellen dass
            Sie korrekt sind. Wir versuchen Ihnen außerdem eine Vielzahl von
            Dienstleistungsanbietern zur Verfügung zu stellen. Leider ist es uns
            jedoch nicht möglich den gesamten Markt abzudecken.</span
          >
        </p>
        <p>
          <span>8.2. Richtigkeit und Vollst&auml;ndigkeit Ihrer Angaben</span>
        </p>
        <p>
          <span
            >Wir sind nicht daf&uuml;r haftbar, wenn wegen fehlerhafter oder
            unvollst&auml;ndiger Angaben durch Sie ein Vertrag nicht zustande
            kommt. Hiervon ausgenommen sind vors&auml;tzliche und grob
            fahrl&auml;ssige Pflichtverletzungen.</span
          >
        </p>

        <p>
          <span>8.3. Haftungsbeschr&auml;nkung der Medcircle</span>
        </p>
        <p>
          <span
            >(a) Bei der Erbringung unserer Leistungen haften wir, gleich aus
            welchem rechtlichen Grund, nur f&uuml;r Vorsatz oder grobe
            Fahrl&auml;ssigkeit, es sei denn, es handelt sich um eine Verletzung
            von Leib, Leben oder Gesundheit (Personensch&auml;den) oder die
            Verletzung einer wesentlichen Vertragspflicht (d.h. einer
            vertraglichen Pflicht, deren Erf&uuml;llung die
            ordnungsgem&auml;&szlig;e Vertragsdurchf&uuml;hrung und die
            Erreichung des Vertragszweckes &uuml;berhaupt erst erm&ouml;glicht
            und auf deren Einhaltung der Vertragspartner regelm&auml;&szlig;ig
            vertrauen darf).</span
          >
        </p>
        <p>
          <span
            >(b) Bei der leicht fahrl&auml;ssigen Verletzung einer
            Vertragspflicht beschr&auml;nkt sich unsere Haftung, au&szlig;er
            f&uuml;r Personensch&auml;den, auf den vorhersehbaren,
            vertragstypischen und unmittelbaren Durchschnittsschaden, d.h. wir
            haften nicht auf entgangenen Gewinn, vergebliche Aufwendungen oder
            sonstige Folgesch&auml;den.</span
          >
        </p>

        <p>
          <span
            >(c) Die Haftung bei Fehlen zugesicherter Eigenschaften oder aus
            einer Garantie bleibt unber&uuml;hrt.</span
          >
        </p>
        <p>
          <span
            >(d) Die vorstehende Haftungsbeschr&auml;nkung gilt in demselben
            Umfang wie f&uuml;r die Medcircle auch f&uuml;r die Organe und
            Mitarbeiter der Medcircle sowie f&uuml;r Dritte, die von uns im
            Rahmen der Vertragserf&uuml;llung (Erf&uuml;llungsgehilfen)
            beauftragt werden.</span
          >
        </p>
        <h3 class="margin-top">9. Urheberrecht und andere Schutzrechte</h3>
        <p>
          <span
            >Alle auf www.medcircle.eu aufgef&uuml;hrten Inhalte unterliegen den
            Urheberrechten, Markenrechten und sonstigen Schutzrechten der
            Medcircle bzw. den jeweiligen Rechteinhabern und d&uuml;rfen nicht
            ohne vorherige schriftliche Vereinbarung f&uuml;r eigene Zwecke
            genutzt werden. Dies gilt f&uuml;r jede Vervielf&auml;ltigung,
            Weitergabe und Ver&ouml;ffentlichung, falls Medcircle dies nicht
            ausdr&uuml;cklich als Funktion angeboten hat.</span
          >
        </p>
        <h3 class="margin-top">10. Datenschutz</h3>
        <p>
          <span
            >Der sorgf&auml;ltige Umgang mit Ihren pers&ouml;nlichen Daten ist
            uns wichtig. Daher m&ouml;chten wir Ihnen transparent darlegen, in
            welcher Form wir Daten sammeln und verwenden. Lesen Sie daher bitte
            unsere Datenschutzerkl&auml;rung, die bei Verwendung von Medcircle
            Anwendung findet und Ihnen ausf&uuml;hrlich den Umgang mit Ihren
            pers&ouml;nlichen Informationen erl&auml;utert. Mit der Zustimmung
            unserer AGB akzeptieren Sie gleichzeitig auch unsere
            Datenschutzerkl&auml;rung und die darin geregelten Inhalte.</span
          >
        </p>
        <h3 class="margin-top">11. &Auml;nderungen der AGB</h3>
        <p>
          <span
            >(a) Durch laufende Gesch&auml;ftsentwicklungen kann es von Zeit zu
            Zeit notwendig werden, unsere Allgemeinen Gesch&auml;ftsbedingungen
            (AGB) z.B. zur Anpassung an rechtliche Vorgaben oder
            Marktgegebenheiten zu aktualisieren. Wir werden Sie rechtzeitig
            &uuml;ber m&ouml;gliche Einschr&auml;nkung des Dienstes informieren.
            Wir sind auch dazu berechtigt, unser kostenloses Angebot jederzeit
            einzustellen.</span
          >
        </p>
        <p>
          <span
            >(b) Wir behalten uns vor, diese AGB jederzeit zu &auml;ndern,
            soweit dies aus triftigen Gr&uuml;nden, insbesondere aufgrund einer
            ge&auml;nderten Rechtslage oder h&ouml;chstrichterlichen
            Rechtsprechung, technischer &Auml;nderungen oder
            Weiterentwicklungen, neuer organisatorischer Anforderungen des
            Massenverkehrs, Regelungsl&uuml;cken in den AGB, Ver&auml;nderung
            der Marktgegebenheiten oder anderen gleichwertigen Gr&uuml;nden
            erforderlich ist und unsere Nutzer nicht unangemessen benachteiligt.
            &Auml;nderungen der AGB werden wir Ihnen</span
          ><span>&nbsp;1 Woche</span
          ><span
            >&nbsp;vor ihrem Inkrafttreten schriftlich oder per E-Mail
            mitteilen. Sie haben dann das Recht, den &Auml;nderungen zu
            widersprechen. Wenn Sie den &Auml;nderungen widersprechen
            m&ouml;chten, k&ouml;nnen Sie dies per E-Mail, innerhalb der
            genannten 1 Woche tun. Wenn Sie den &Auml;nderungen nicht bis dahin
            widersprechen, gelten die &Auml;nderungen als angenommen und die
            neue Fassung der Bedingungen als akzeptiert. Auf diese
            Widerrufsfrist und die Folge des Ablaufs der Frist werden Sie in der
            &Auml;nderungsmitteilung hingewiesen.</span
          >
        </p>
        <h3 class="margin-top">12. Gerichtsstand</h3>
        <p>
          <span
            >F&uuml;r alle Rechtsbeziehungen zwischen der Medcicle und Ihnen als
            Medcircle-Nutzer gilt ausschlie&szlig;lich das Recht der
            Bundesrepublik Deutschland. Die Vertragssprache ist Deutsch.</span
          >
        </p>
        <p>
          <span
            >Wenn der Nutzer Kaufmann im Sinne des Handelsrechts, juristische
            Person des &ouml;ffentlichen Rechts oder ein juristisches
            Sonderverm&ouml;gen ist, gilt als ausschlie&szlig;licher
            Erf&uuml;llungsort f&uuml;r vertragliche Verpflichtungen und als
            ausschlie&szlig;licher Gerichtsstand der Sitz der Medcircle</span
          >
        </p>
      </div>
    </div>
  </div>
  <FooterComponent></FooterComponent>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import HeaderComponent from '../../../Header/HeaderComponent.vue'
import router from '@/router'
import { useUserStore } from '@/stores/user-auth'
import FooterComponent from '@/components/Footer/FooterComponent.vue'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'

export default defineComponent({
  components: {
    HeaderComponent,
    FooterComponent,
  },
  name: 'TermsAndConditions',
  setup() {
    const { t } = useI18n()
    useMeta({
      title: t('meta.terms'),
    })
  },
})
</script>
<style scoped>
.margin-top {
  margin-top: 16px;
}
.agb {
  width: 70%;
}
@media screen and (max-width: 800px) {
  .agb {
    width: 90%;
  }
}
</style>
