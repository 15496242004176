<template>
  <div
    class="mt-10"
    style="background-color: #25344d; color: white; min-height: 250px"
  >
    <v-divider></v-divider>
    <v-row>
      <v-col :cols="!smallWindow ? 7 : 12">
        <SubscribingComponent></SubscribingComponent>
      </v-col>
      <v-col :cols="smallWindow ? 6 : 3">
        <div class="footer-container">
          <div>
            <v-btn
              rounded="sm"
              variant="text"
              @click="$router.push('/t-and-c')"
            >
              {{ $t("footer.terms") }}
            </v-btn>
          </div>
          <div>
            <v-btn
              rounded="sm"
              variant="text"
              @click="$router.push('/contact')"
            >
              {{ $t("footer.contact") }}
            </v-btn>
          </div>
          <div>
            <v-btn
              rounded="sm"
              variant="text"
              @click="$router.push('/imprint')"
            >
              {{ $t("footer.imprint") }}
            </v-btn>
          </div>
          <div>
            <v-btn
              rounded="sm"
              variant="text"
              @click="$router.push('/privacy-policy')"
            >
              {{ $t("footer.privacy-policy") }}
            </v-btn>
          </div>
          <div>
            <v-btn
              rounded="sm"
              variant="text"
              @click="$router.push('/attribute')"
            >
              {{ $t("footer.attribute") }}
            </v-btn>
          </div>
          <!-- <div class="mt-4 ml-4">
        <v-btn
          class="mr-2"
          icon="mdi-instagram"
          size="small"
          href="https://www.instagram.com/docforme.eu/"
          target="_blank"
        ></v-btn>
        <v-btn
          icon="mdi-linkedin"
          size="small"
          href="https://www.linkedin.com/company/medcircle-eu/"
          target="_blank"
        ></v-btn>
      </div> -->
        </div>
      </v-col>
      <v-col :cols="smallWindow ? 6 : 2" class="footer-container-social">
        <p>Follow us</p>

        <div class="mt-2">
          <v-btn
            icon="mdi-instagram"
            size="small"
            href="https://www.instagram.com/medcircle.eu/"
            target="_blank"
          ></v-btn>
        </div>
        <div class="mt-2">
          <v-btn
            icon="mdi-linkedin"
            size="small"
            href="https://www.linkedin.com/company/medcircle-eu/"
            target="_blank"
          ></v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import SubscribingComponent from "./SubscribingComponent.vue"
export default defineComponent({
  name: "FooterComponent",
  components: { SubscribingComponent },
  data() {
    const { t } = useI18n()

    const smallWindow = ref(window.innerWidth < 1000)
    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })

    return { smallWindow }
  },
})
</script>

<style>
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: start;
  padding: 16px;
  margin-top: 20px;
}
.footer-container-social {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: center;
  padding: 16px;
  margin-top: 20px;
}
</style>
