import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { style: {"min-height":"60vh"} }
const _hoisted_2 = { class: "mt-5" }
const _hoisted_3 = { align: "center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_status = _resolveComponent("request-status")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_HospitalDoctorProfile = _resolveComponent("HospitalDoctorProfile")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_CalendarProfileComponent = _resolveComponent("CalendarProfileComponent")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_request_status, {
      response: _ctx.requestResponse,
      isLoading: _ctx.loading
    }, null, 8, ["response", "isLoading"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.tab == 'info')
            ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.$t("general-info")), 1))
            : _createCommentVNode("", true),
          (_ctx.tab == 'calendar')
            ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(_ctx.$t("calendar")), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_v_divider, { class: "mt-4" })
        ])
      ]),
      _createVNode(_component_v_tabs, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        "show-arrows": "",
        "bg-color": "#25344d",
        "fixed-tabs": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tab, { value: "info" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("general-info")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_tab, { value: "calendar" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("calendar")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_window, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tab) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_window_item, { value: "info" }, {
            default: _withCtx(() => [
              (_ctx.doctor)
                ? (_openBlock(), _createBlock(_component_HospitalDoctorProfile, {
                    key: 0,
                    doctor: _ctx.doctor
                  }, null, 8, ["doctor"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_window_item, { value: "calendar" }, {
            default: _withCtx(() => [
              (_ctx.doctor && !_ctx.loading)
                ? (_openBlock(), _createBlock(_component_CalendarProfileComponent, {
                    key: 0,
                    manualTime: _ctx.manualTime,
                    useCalendarSearch: _ctx.activeSearch,
                    onSaved: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveTimeTable($event)))
                  }, null, 8, ["manualTime", "useCalendarSearch"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}