<template>
  <request-status :response="requestResponse" :isLoading="loading" />
  <div style="min-height: 60vh">
    <div class="mt-5">
      <div align="center">
        <h3 v-if="tab == 'info'">{{ $t("general-info") }}</h3>
        <h3 v-if="tab == 'calendar'">{{ $t("calendar") }}</h3>
        <v-divider class="mt-4"></v-divider>
      </div>
    </div>

    <v-tabs v-model="tab" show-arrows bg-color="#25344d" fixed-tabs>
      <v-tab value="info">{{ $t("general-info") }}</v-tab>
      <v-tab value="calendar">
        {{ $t("calendar") }}
      </v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <v-window-item value="info">
        <HospitalDoctorProfile
          :doctor="doctor"
          v-if="doctor"
        ></HospitalDoctorProfile>
      </v-window-item>
      <v-window-item value="calendar">
        <CalendarProfileComponent
          v-if="doctor && !loading"
          :manualTime="manualTime"
          :useCalendarSearch="activeSearch"
          @saved="saveTimeTable($event)"
        ></CalendarProfileComponent>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import HospitalDoctorProfile from "./HospitalDoctorProfile.vue"
import { useI18n } from "vue-i18n"
import CalendarProfileComponent from "@/components/Static/CalendarComponent/CalendarProfileComponent.vue"
import { hospitalActions } from "@/stores/hospital-actions"
import { useRoute } from "vue-router"
import { ApplicantTime, Department, HospitalDoctor } from "@/types"
import { doctorActions } from "@/stores/doctor-actions"
import { useToast } from "vue-toast-notification"
export default defineComponent({
  components: { HospitalDoctorProfile, CalendarProfileComponent },
  name: "HospitalDoctorProfileTabs",

  setup(props) {
    const tab = ref("info")
    const { t } = useI18n()
    const hospitalAction = hospitalActions()
    const docAction = doctorActions()
    const route = useRoute()
    const requestResponse = ref()
    const doctor = ref<HospitalDoctor>()
    const loading = ref(true)
    const id = ref()
    const title = ref<string | undefined>(undefined)
    const firstName = ref<string | undefined>(undefined)
    const lastName = ref<string | undefined>(undefined)

    const activeSearch = ref(false)
    const manualTime = ref<any[][]>([])
    const applicants = ref<ApplicantTime[]>([])

    onMounted(async () => {
      id.value = route.params.id
      await getDoctorProfile()
    })

    const getDoctorProfile = async () => {
      loading.value = true

      const response = await hospitalAction.getDoctor(id.value)
      requestResponse.value = response
      if (response.success) {
        doctor.value = response.result as HospitalDoctor
        activeSearch.value = doctor.value?.useCalendarSearch ?? false
        title.value = doctor.value?.title ?? "-"
        firstName.value = doctor.value?.firstName ?? undefined
        lastName.value = doctor.value?.lastName ?? undefined

        applicants.value = doctor.value?.applicants ?? []
      }
      buildTimeArray(applicants.value)
      loading.value = false
    }

    const buildTimeArray = (array: ApplicantTime[]) => {
      manualTime.value = []
      array.forEach((item: ApplicantTime) => {
        const startDate = new Date(item.start)
        const endDate = new Date(item.end)
        manualTime.value.push([[startDate, endDate], item.applicants])
      })
    }

    return {
      tab,
      t,
      manualTime,
      doctor,
      loading,
      id,
      hospitalAction,
      docAction,
      requestResponse,
      getDoctorProfile,
      activeSearch,
    }
  },
  methods: {
    async saveTimeTable(data: any): Promise<void> {
      this.loading = true
      var applicants: ApplicantTime[] = []
      const $toast = useToast()
      if (data.useCalendarSearch == this.activeSearch) {
        if (data.addingNewTimeWindow) {
          this.manualTime.push(data.timeWindow)
        } else if (data.deletingTimeWindow) {
          this.manualTime = this.manualTime.filter((item, index) => {
            return index !== data.index
          })
        } else {
          this.manualTime[data.index] = data.timeWindow
        }
      }

      this.manualTime.forEach((val) => {
        const data: ApplicantTime = {
          start: this.docAction.getDatesInString(val[0][0] as Date),
          end: this.docAction.getDatesInString(val[0][1] as Date),
          applicants: parseInt(val[1].toString()),
        }
        applicants.push(data)
      })

      const profile = {
        applicants: applicants,
        useCalendarSearch: data.useCalendarSearch,
      }

      const response = await this.hospitalAction.saveDoctorProfile(
        this.id,
        profile
      )
      this.requestResponse = response

      this.loading = false
      if (response.success) {
        $toast.success(this.t("profile-saved-successfully"), {
          position: "bottom-left",
        })
        this.getDoctorProfile()
      } else {
        $toast.error(response.message ?? "", {
          position: "bottom-left",
        })
      }
    },
  },
})
</script>

<style></style>
