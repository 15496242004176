<template>
  <v-app>
    <v-main>
      <metainfo>
        <template v-slot:title="{ content }">{{
          content ? `${content}` : `Medcircle`
        }}</template>
      </metainfo>
      <router-view />
      <CookieBanner />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import CookieBanner from "./components/Static/CookieBanner/CookieBanner.vue"
export default defineComponent({
  name: "App",
  components: {
    CookieBanner,
  },

  onMounted(): void {
    console.log("version-1.2")
    localStorage.removeItem("searchParams")
  },

  data() {
    return {
      //
    }
  },
})
</script>

<style></style>
