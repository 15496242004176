<template>
  <h2 align="center" class="mt-4">{{ $t('your-subscribers') }}</h2>
  <v-divider class="mt-3"></v-divider>

  <request-status :response="requestResponse" v-if="!loading" />

  <v-data-table
    style="margin-top: 9px"
    v-model:items-per-page="itemsPerPage"
    :headers="headers"
    :items="applicantsArray"
  >
    <template v-slot:[`item.photo`]="props">
      <div v-if="props.item.photo" align="center">
        <v-img
          :src="props.item.photo"
          alt="student Image"
          v-bind="props"
          class="image mb-2"
          referrerpolicy="no-referrer"
          rel="noreferrer"
        />
      </div>
      <div v-else>
        <v-img
          v-bind="props"
          :src="require('../../../../../assets/stud-team1.png')"
          alt="student Image"
          class="image mb-2"
          referrerpolicy="no-referrer"
          rel="noreferrer"
        />
      </div>
    </template>

    <template v-slot:[`item.action`]="props">
      <v-btn v-bind="props" color="#3fb984" @click="remove(props.item)"
        ><v-icon class="mr-2">mdi-trash-can</v-icon>
        {{ $t('table.remove') }}
      </v-btn>
    </template></v-data-table
  >
  <v-dialog v-model="removing" max-width="450px">
    <v-sheet
      border="md"
      class="pa-6 text-black mx-auto"
      color="white"
      min-width="350px"
      style="border-radius: 7%"
    >
      <h5 class="text-h6 font-weight-bold mb-4">
        {{ $t('remove-subscriber') }}
      </h5>
      <div align="center">
        {{ removingName }}
      </div>

      <div align="center" class="mt-2">
        <v-img
          :src="removingPhoto"
          width="70px"
          style="border-radius: 50%"
          lazy
        ></v-img>
      </div>
      <div align="end" class="mt-15">
        <v-btn color="error" @click="removing = false">{{
          $t('go-back')
        }}</v-btn>
        <v-btn
          class="ml-2"
          color="#3fb984"
          @click="removeSubscriber(removingId)"
          >{{ $t('table.remove') }}</v-btn
        >
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, onMounted, computed, Ref } from 'vue'
import { RequestResponse, Subscriber } from '@/types'
import { broadcastingActions } from '@/stores/broadcasting-store'

const subscribers = ref<Subscriber[]>([])

const loading = ref(false)
const { t } = useI18n()
const broadcasting = broadcastingActions()

const page = ref(1)
const itemsPerPage = ref(5)
const requestResponse = ref<RequestResponse>()
const removing = ref(false)
const removingId = ref<string>('')
const removingName = ref<string>('')
const removingPhoto = ref<string>('')
const headers = computed(() => {
  return [
    {
      title: 'Photo',

      key: 'photo',
      sortable: false,
      align: 'center',
    },
    { title: t('table.name'), key: 'name', sortable: false, align: 'center' },
    { title: t('city'), key: 'city', sortable: false, align: 'center' },
    { title: t('table.uni'), key: 'uni', sortable: false, align: 'center' },

    {
      title: t('table.remove'),
      key: 'action',
      sortable: false,
      align: 'center',
    },
  ]
})

onMounted(() => {
  loadSubscribers()
})

const loadSubscribers = async () => {
  loading.value = true

  const response = await broadcasting.getSubscribers(
    page.value,
    itemsPerPage.value
  )

  requestResponse.value = response

  if (response.success) {
    subscribers.value = response.result as Subscriber[]
  }
  loading.value = false
}

const applicantsArray = computed((): any => {
  const array: any[] = []

  subscribers.value.forEach((applicant) => {
    const singleSubscriber = {
      id: applicant._id,
      photo: applicant.userProfile,
      name:
        applicant.userInfo?.firstName ??
        '' + ' ' + applicant.userInfo?.lastName ??
        '',
      uni: applicant.userInfo.university,
      city: applicant.userInfo.city,
    }
    array.push(singleSubscriber)
  })

  return array
})
const remove = (subscriber: any) => {
  removingId.value = subscriber.id
  removingName.value = subscriber.name
  removingPhoto.value = subscriber.photo
  removing.value = true
}
const removeSubscriber = async (id: string) => {
  loading.value = true
  const body = {
    connectionId: id,
    status: 'REMOVE',
  }
  await broadcasting.removeSubscriber(body)
  removing.value = false

  loadSubscribers()

  setTimeout(() => {
    loadSubscribers()
    loading.value = false
  }, 200)
}
</script>

<style scoped>
.custom-title {
  font-size: x-large;
}

.custom-subtitle {
  font-size: large;
}
.image {
  width: 50px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 10px;
  height: 50px;
  max-width: 50px;
  min-height: 50px;
}
</style>
