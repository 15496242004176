import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a41045e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "mt-5",
  align: "center"
}
const _hoisted_2 = { class: "mt-5" }
const _hoisted_3 = { class: "text-h5 font-weight-bold mb-4" }
const _hoisted_4 = {
  align: "center",
  class: "mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_status = _resolveComponent("request-status")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_cropper = _resolveComponent("cropper")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_request_status, {
      response: _ctx.requestResponse,
      isLoading: _ctx.loading
    }, null, 8, ["response", "isLoading"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_tooltip, { location: "bottom" }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_v_img, _mergeProps(props, {
              width: 163,
              style: {"border-radius":"50%"},
              cover: "",
              src: 
              _ctx.photoUrl != '' ? _ctx.photoUrl : require('../../../assets/blank.png')
            ,
              onClick: _ctx.onButtonClick
            }), null, 16, ["src", "onClick"]),
            _createElementVNode("input", {
              ref: "uploader",
              class: "d-none",
              type: "file",
              accept: "image/*",
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImageUpload && _ctx.onImageUpload(...args)))
            }, null, 544)
          ]),
          default: _withCtx(() => [
            _createTextVNode(" " + _toDisplayString(_ctx.$t("image-upload")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.showCropper,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showCropper) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_sheet, {
              border: "md",
              class: "pa-6 text-black mx-auto",
              width: _ctx.smallWindow ? 320 : 600,
              color: "white"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h4", _hoisted_3, [
                  _createVNode(_component_cropper, {
                    class: "cropper",
                    src: _ctx.localImageUrl,
                    "stencil-props": {
                aspectRatio: 10 / 11,
              },
                    onChange: _ctx.change
                  }, null, 8, ["src", "onChange"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_v_btn, {
                      color: "error",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCropper = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("go-back")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      class: "ml-2",
                      color: "green",
                      onClick: _ctx.saveImage
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("save")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["width"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ])
  ], 64))
}