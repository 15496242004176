import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, mergeProps as _mergeProps, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f0a2c00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex flex-wrap mt-5 justify-center" }
const _hoisted_4 = {
  key: 0,
  class: "flex-item mb-10"
}
const _hoisted_5 = { style: {"font-weight":"700"} }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_request_status = _resolveComponent("request-status")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_hover = _resolveComponent("v-hover")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('your-applications')), 1),
    _createVNode(_component_v_divider, { class: "mt-3 mb-10" }),
    _createVNode(_component_request_status, {
      response: _ctx.requestResponse,
      isLoading: _ctx.isLoading
    }, null, 8, ["response", "isLoading"]),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applications, (application, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                (application?.doctorInfo !== null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_v_hover, null, {
                        default: _withCtx(({ isHovering, props }) => [
                          _createVNode(_component_v_card, _mergeProps({
                            class: "mx-auto ml-2",
                            height: "250",
                            width: !_ctx.smallWindow ? 500 : undefined,
                            elevation: isHovering ? 20 : 3
                          }, props, {
                            subtitle: application?.doctorInfo?.specialty,
                            title: `${application?.doctorInfo?.title} ${application?.doctorInfo?.lastName}`
                          }), {
                            prepend: _withCtx(() => [
                              _createVNode(_component_v_avatar, { size: "70" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_img, {
                                    src: 
                        application?.profileUrl != ''
                          ? application?.profileUrl
                          : require('../../../assets/blank.png')
                      
                                  }, null, 8, ["src"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            append: _withCtx(() => [
                              _createVNode(_component_v_hover, null, {
                                default: _withCtx(({ isHovering, props }) => [
                                  _createVNode(_component_v_avatar, _mergeProps(props, {
                                    size: isHovering ? 100 : 70,
                                    class: isHovering ? 'pointer' : undefined
                                  }), {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_img, {
                                        src: 
                            application?.doctorInfo?.photo ??
                            require('../../../assets/blank.png')
                          ,
                                        onClick: ($event: any) => (
                            _ctx.$router.push(
                              `/doctors/${application.doctorInfo._id}`
                            )
                          )
                                      }, null, 8, ["src", "onClick"])
                                    ]),
                                    _: 2
                                  }, 1040, ["size", "class"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_text, { class: "mt-3" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('application-line')) + " ", 1),
                                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.FormatDateFunction(application?.startDate)), 1)
                                  ]),
                                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('status-line')) + " " + _toDisplayString(_ctx.statusMapping[application?.status]), 1)
                                ]),
                                _: 2
                              }, 1024),
                              (_ctx.takenBackMessage)
                                ? (_openBlock(), _createBlock(_component_v_alert, {
                                    key: 0,
                                    title: _ctx.takenBackMessage,
                                    type: "info",
                                    variant: "tonal"
                                  }, null, 8, ["title"]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_v_btn, {
                                class: "mt-2",
                                color: "primary",
                                loading: _ctx.takingBack,
                                onClick: ($event: any) => (_ctx.$router.push(`application/${application._id}`))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('to-application')), 1)
                                ]),
                                _: 2
                              }, 1032, ["loading", "onClick"])
                            ]),
                            _: 2
                          }, 1040, ["width", "elevation", "subtitle", "title"])
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          (_ctx.applications.length == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('no-application')), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}