<template>
  <request-status :response="requestResponse" v-if="!loading" />

  <MessageTable
    :messages="messages"
    :isDoctor="true"
    v-if="!loading"
    @edit="editMessage($event)"
  ></MessageTable>

  <MessageBox
    :content="content"
    :id="id"
    :title="title"
    :editView="true"
    :studentView="false"
    :broadcast="editContent"
    @closed="closeDialog"
    @closeDialog="editContent = false"
    v-if="editContent"
  ></MessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { defineProps, ref, onMounted, computed, Ref } from 'vue'
import { Applicant, Message, RequestResponse, Subscriber } from '@/types'
import { broadcastingActions } from '@/stores/broadcasting-store'
import { Delta } from '@vueup/vue-quill'
import MessageBox from '@/components/Static/MessageBox/MessageBox.vue'
import MessageTable from '@/components/Static/MessageTable/MessageTable.vue'

const messages = ref<Message[]>([])

const loading = ref(false)
const { t } = useI18n()
const broadcasting = broadcastingActions()

const page = ref(1)
const itemsPerPage = ref(5)
const removeSubscriberResponse = ref<RequestResponse>()
const requestResponse = ref<RequestResponse>()
const content = ref<Delta>()
const editContent = ref(false)
const id = ref<string>('')
const title = ref<string>('')
const headers = computed(() => {
  return [
    {
      title: 'Photo',

      key: 'photo',
      sortable: false,
      align: 'center',
    },
    { title: t('title'), key: 'title', sortable: false, align: 'center' },
    {
      title: t('table.created-at'),
      key: 'createdAt',
      sortable: false,
      align: 'center',
    },

    {
      title: t('table.update'),
      key: 'action',
      sortable: false,
      align: 'center',
    },
  ]
})

onMounted(() => {
  loadMessages()
})

const closeDialog = () => {
  editContent.value = false
  loadMessages()
}

const loadMessages = async () => {
  loading.value = true
  const docToken = localStorage.getItem('docAccessToken') ?? ''
  const response = await broadcasting.getMessages(
    page.value,
    itemsPerPage.value,
    docToken
  )
  requestResponse.value = response
  if (response.success) {
    messages.value = response.result as Message[]
  }

  loading.value = false
}

const editMessage = async (event: any) => {
  content.value = event.content
  id.value = event.id
  title.value = event.title
  editContent.value = !editContent.value
}
</script>

<style scoped>
.custom-title {
  font-size: x-large;
}

.custom-subtitle {
  font-size: large;
}
.image {
  width: 50px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 10px;
  height: 50px;
  max-width: 50px;
  min-height: 50px;
}
</style>
