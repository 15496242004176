import { RequestResponse } from './../types/index'
import { defineStore } from 'pinia'
import apiClient from './apiclients'
import { useUserActions } from './user-actions'
import router from '@/router'
export const useUserStore = defineStore({
  id: 'user',

  state: () => ({
    name: '',
    accessToken: localStorage.getItem('accessToken') ?? '',
    userId: localStorage.getItem('userId') ?? '',
    isAuthenticated: false,
  }),

  actions: {
    async login(credentials: {
      email: string
      password: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('user/login', credentials)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async register(user: {
      firstName: string
      lastName: string
      email: string
      password: string
      agreements: boolean
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('user/register', user)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async forgotPassword(credentials: {
      email: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('user/forgot-password', credentials)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async resetPassword(credentials: {
      userId: string
      token: string
      password: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const token = localStorage.getItem('accessToken') ?? ''
      await apiClient
        .post('user/forgot-password-verify', credentials)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
  },
})
