<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-card
        class="mx-auto my-5 ml-3 mr-3"
        :width="!smallWindow ? 400 : 300"
        v-bind="props"
        height="400"
        :elevation="isHovering ? 20 : 3"
      >
        <v-card-item
          :title="
            prop.doctor.title +
            ' ' +
            prop.doctor.firstName +
            ' ' +
            prop.doctor.lastName
          "
          @click="$router.push(`/doctors/${prop.doctor._id}`)"
          style="cursor: pointer"
        >
          <template v-slot:subtitle>
            <div align="center">
              <div
                v-if="!prop.doctor.hospitalDetails"
                class="mt-2"
                style="white-space: normal"
              >
                {{ spec }}
              </div>
              <div v-else class="mt-2" style="white-space: normal">
                {{ prop.doctor?.hospitalDetails[0]?.name }}
                <div class="mt-2" style="white-space: normal">
                  {{ prop.doctor?.hospitalDetails[0]?.department }}
                </div>
              </div>
            </div>
          </template>
        </v-card-item>

        <v-row align="center" justify="center">
          <v-col cols="6">
            <v-badge color="white">
              <v-img
                width="150"
                style="border-radius: 50%; cursor: pointer"
                :src="
                  prop.doctor.photo ??
                  require('../../../assets/profile_def.png')
                "
                @click="$router.push(`/doctors/${prop.doctor._id}`)"
              >
                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                      color="#3fb984"
                      indeterminate
                    ></v-progress-circular>
                  </div> </template
              ></v-img>
              <template
                v-slot:badge
                v-if="doctor.universityLogo && !doctor.isHospitalConnected"
              >
                <div class="mt-5">
                  <v-img
                    width="50"
                    style="border-radius: 50%"
                    :src="prop.doctor.universityLogo"
                    ><template v-slot:placeholder>
                      <div
                        class="d-flex align-center justify-center fill-height"
                      >
                        <v-progress-circular
                          color="#3fb984"
                          indeterminate
                        ></v-progress-circular>
                      </div> </template
                  ></v-img>
                </div>
              </template>
              <template
                v-slot:badge
                v-else-if="prop.doctor?.isHospitalConnected"
              >
                <div class="mt-5">
                  <v-img
                    width="55"
                    style="border-radius: 50%; cursor: pointer"
                    :src="
                      prop.doctor?.hospitalDetails[0]?.photo != '' &&
                      prop.doctor?.hospitalDetails[0]?.photo
                        ? prop.doctor?.hospitalDetails[0]?.photo
                        : require('../../../assets/hospital.png')
                    "
                    @click="$router.push(`hospital/${doctor.hospitalId[0]}`)"
                    ><template v-slot:placeholder>
                      <div
                        class="d-flex align-center justify-center fill-height"
                      >
                        <v-progress-circular
                          color="#3fb984"
                          indeterminate
                        ></v-progress-circular>
                      </div> </template
                  ></v-img>
                </div>
              </template>
            </v-badge>
          </v-col>
        </v-row>

        <div class="d-flex py-3 justify-space-between" style="height: 80px">
          <v-btn
            class="ml-5"
            icon="mdi-map-marker"
            size="small"
            @click="openDocLocation"
          ></v-btn>

          <v-list-item-subtitle class="mt-5">
            {{ prop.doctor.clinicLocation + ", " + prop.doctor.clinicLand }}
          </v-list-item-subtitle>

          <div>
            <v-btn
              :disabled="!doctor.linkedInLink"
              class="mr-5"
              icon="mdi-web"
              size="small"
              @click="toDoctorLink"
            ></v-btn>
          </div>
        </div>

        <v-card-actions
          style="
            background-color: #3fb984;
            position: absolute;
            bottom: 0;
            width: 100%;
          "
        >
          <v-btn
            block
            color="white"
            @click="$router.push(`/doctors/${prop.doctor._id}`)"
          >
            {{ $t("doctor.to-profile") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-hover>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, defineProps, onBeforeUnmount, onMounted, ref } from "vue"
import { Doctor } from "@/types"
import { specialtiesReverseMap } from "../../../types/spec"
const prop = defineProps<{
  doctor: Doctor
}>()

const { t } = useI18n()
const smallWindow = ref(window.innerWidth < 1000)
const locale = ref<string | null>(localStorage.getItem("locale"))
const openDocLocation = (): void => {
  const address = prop.doctor.clinicStreet.split(" ")

  window.open(
    `https://www.google.com/maps/search/${address[0] ?? ""}+${
      address[1] ?? ""
    }+${prop.doctor.clinicLocation ?? ""}+${prop.doctor.clinicLand}`,
    "_blank"
  )
}

onMounted(async () => {
  window.addEventListener("locale-changed", updateLocale)
})

onBeforeUnmount(() => {
  window.removeEventListener("locale-changed", updateLocale)
})

const updateLocale = () => {
  locale.value = localStorage.getItem("locale") ?? "de"
}

const spec = computed((): string => {
  if (locale.value !== "de") {
    return specialtiesReverseMap[prop.doctor.specialty]
  }
  return prop.doctor.specialty
})

const toDoctorLink = (): void => {
  const headerLink = prop.doctor.linkedInLink.split(":", 1)
  const wwwLink = prop.doctor.linkedInLink.split(".", 1)
  console.log(headerLink)
  if (headerLink[0] == "https") {
    window.open(prop.doctor.linkedInLink, "_blank")
  } else if (wwwLink[0] == "www") {
    window.open("https://" + prop.doctor.linkedInLink, "_blank")
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set height to full viewport height */
}
</style>
