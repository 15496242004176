<template>
  <request-status :response="requestResponse" :isLoading="isLoading" />

  <div class="mt-5" v-if="!isLoading">
    <div align="center">
      <h3>{{ $t("calendar") }}</h3>
      <v-divider class="mt-4"></v-divider>
    </div>
    <div class="calendar" style="padding: 0px 20px">
      <FullCalendar :options="calendarOptions" class="mt-7" />
    </div>
  </div>
</template>

<script setup lang="ts">
export interface CalendarEvents {
  id: string
  title: string
  start: Date
  end: Date
  color?: string
  textColor?: string
  startEditable: boolean
}

import { useI18n } from "vue-i18n"
import { onBeforeUnmount, onMounted, ref } from "vue"
import { useRoute } from "vue-router"
import { hospitalActions } from "@/stores/hospital-actions"
import { Applicant, RequestResponse } from "@/types"
import router from "@/router"
import { doctorActions } from "@/stores/doctor-actions"
import FullCalendar from "@fullcalendar/vue3"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import moment from "moment"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import deLocale from "@fullcalendar/core/locales/de"
import { CalendarOptions, EventApi } from "@fullcalendar/core"

const { t } = useI18n()
const events = ref<CalendarEvents[]>([])
const applicants = ref<Applicant[]>([])
const calendarOptions = ref({
  plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
  initialView: "dayGridMonth",
  headerToolbar: {
    left: "prev next today",
    center: "title",
    right: "dayGridMonth,timeGridWeek,listWeek",
  },
  selectable: false,
  weekends: true,
  events: events.value,
  displayEventTime: false,
  locale: localStorage.getItem("locale") == "de" ? deLocale : "en",
  eventClick: function (info: any) {
    openApplication(info.event._def.publicId)
  },
})

const isLoading = ref(false)
const route = useRoute()
const type = route.params.type
const requestResponse = ref<RequestResponse>()

onMounted(async () => {
  isLoading.value = true
  if (type == "hospital") {
    const hospitalAction = hospitalActions()
    const response = await hospitalAction.getApplications("", 1, 20)
    if (response.success) {
      applicants.value = response.result as Applicant[]
      buildEvents(applicants.value)
    } else {
      requestResponse.value = response
    }
  } else if (type == "doctor") {
    const doctorAction = doctorActions()
    const response = await doctorAction.getApplications("", 1, 20)
    if (response.success) {
      applicants.value = response.result as Applicant[]
      buildEvents(applicants.value)
    } else {
      requestResponse.value = response
    }
  }

  isLoading.value = false
  window.addEventListener("locale-changed", updateCalendarLocale)
})
onBeforeUnmount(() => {
  window.removeEventListener("locale-changed", updateCalendarLocale)
})

const updateCalendarLocale = () => {
  calendarOptions.value.locale =
    localStorage.getItem("locale") == "de" ? deLocale : "en"
  buildEvents(applicants.value)
}

const openApplication = (eventId: string) => {
  console.log("event", eventId)
  if (type == "hospital") {
    router.push(`/hospital-dashboard/application/${eventId}`)
  } else if (type == "doctor") {
    router.push(`/doctor-dashboard/application/${eventId}`)
  }
}

const buildEvents = (applications: Applicant[]) => {
  isLoading.value = true
  applications.forEach((application) => {
    if (application.status == "CREATED") {
      events.value.push({
        title: `
          ${t("calendar-open-applicant")} 
          ${application.firstName} 
          ${application.lastName}`,
        start: moment(application.startDate).locale("de").toDate(),
        end: moment(application.endDate).locale("de").toDate(),
        color: "#25344d",
        id: application._id,
        startEditable: false,
      })
    } else if (application.status == "ACCEPTED") {
      events.value.push({
        title: `
          ${t("calendar-accepted-applicant")} 
          ${application.firstName} 
          ${application.lastName}`,
        start: moment(application.startDate).locale("de").toDate(),
        end: moment(application.endDate).locale("de").toDate(),
        color: "#3fb984",
        id: application._id,
        startEditable: false,
      })
    }
  })
  calendarOptions.value.events = events.value
  isLoading.value = false
}
</script>
<style>
.fc-event {
  cursor: pointer;
}
</style>
