<template>
  <v-data-table
    style="margin-top: 9px"
    v-model:items-per-page="itemsPerPage"
    :headers="headers"
    :items="applicantsArray"
    :loading="loading"
  >
    <template v-slot:[`item.photo`]="props">
      <div v-if="props.item.photo" align="center">
        <v-img
          :src="props.item.photo"
          alt="student Image"
          v-bind="props"
          class="image mb-2"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          style="cursor: pointer"
          @click="router.push(`/doctors/${props?.item?.doctorId}`)"
        />
      </div>
      <div v-else>
        <v-img
          v-bind="props"
          :src="require('../../../assets/stud-team1.png')"
          alt="student Image"
          class="image mb-2"
          referrerpolicy="no-referrer"
          rel="noreferrer"
        />
      </div>
    </template>

    <template v-slot:[`item.name`]="props">
      <p class="d-inline-block text-truncate" style="max-width: 150px">
        {{ props.item.name }}
      </p>
    </template>
    <template v-slot:[`item.title`]="props">
      <h4 class="d-inline-block text-truncate" style="max-width: 150px">
        {{ props.item.title }}
      </h4>
    </template>

    <template v-slot:[`item.action`]="props">
      <v-btn
        v-if="isDoctor"
        v-bind="props"
        color="#3fb984"
        @click="$emit('edit', props.item)"
      >
        {{ $t('table.update') }}
      </v-btn>
      <v-btn
        v-else
        v-bind="props"
        color="#3fb984"
        @click="$emit('view', props.item)"
      >
        {{ $t('table.view-message') }}
      </v-btn>
    </template>

    <template v-slot:[`item.seen`]="props">
      <div class="flex ml-5">
        <div v-for="(user, index) in props.item.seen" :key="index">
          <v-tooltip location="bottom" v-if="index == 0">
            <template v-slot:activator="{ props }">
              <v-avatar v-bind="props">
                <v-img :alt="user.name" :src="user.photo"></v-img>
              </v-avatar>
            </template>
            {{ user.name }}
          </v-tooltip>
        </div>
        <v-avatar
          class="ml-1"
          color="#3fb984"
          v-if="props.item.seen.length > 1"
          @click="buildUsers(props.item.seen)"
          style="cursor: pointer"
        >
          +{{ props.item.seen.length - 1 }}
        </v-avatar>
      </div>
    </template>
  </v-data-table>
  <SeenUsers
    v-if="viewSeenList"
    :viewSeenList="viewSeenList"
    :usersList="seenList"
    :studentHeader="studentHeader"
    @close="viewSeenList = false"
  ></SeenUsers>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { defineProps, ref, onMounted, computed, Ref } from 'vue'
import { Message } from '@/types'

import router from '@/router'
import SeenUsers from './SeenUsers/SeenUsers.vue'

const props = defineProps<{
  messages: Message[]
  isDoctor: boolean
}>()

const messages = ref(props.messages)
const isDoctor = ref(props.isDoctor)
const loading = ref(false)
const { t } = useI18n()
const viewSeenList = ref(false)
const seenList = ref()
const itemsPerPage = ref(5)

const buildUsers = (seen: any) => {
  seenList.value = seen
  viewSeenList.value = true
}
const studentHeader = computed(() => {
  return [
    {
      title: 'Photo',

      key: 'photo',
      sortable: false,
      align: 'center',
    },
    {
      title: t('table.name'),

      key: 'name',
      sortable: false,
      align: 'center',
    },
  ]
})
const headers = computed(() => {
  let headers = [
    {
      title: 'Photo',

      key: 'photo',
      sortable: false,
      align: 'center',
    },
  ]

  if (!isDoctor.value) {
    headers.push({
      title: t('table.name'),

      key: 'name',
      sortable: false,
      align: 'center',
    })
  }
  headers.push(
    { title: t('title'), key: 'title', sortable: false, align: 'center' },
    {
      title: isDoctor.value ? t('table.created-at') : t('table.received-at'),
      key: 'createdAt',
      sortable: false,
      align: 'center',
    },

    {
      title: isDoctor.value ? t('table.update') : t('table.view'),
      key: 'action',
      sortable: false,
      align: 'center',
    }
  )

  if (isDoctor.value) {
    headers.push({
      title: t('seen-by'),
      key: 'seen',
      sortable: false,
      align: 'start',
    })
  }

  return headers
})

const applicantsArray = computed((): any => {
  const array: any[] = []

  messages.value.forEach((message) => {
    const seenUsers: any[] = []
    message.seenByUsers.forEach((user) => {
      const seenUser = {
        name: user.firstName + ' ' + user.lastName,
        photo: user.profileUrl,
      }
      seenUsers.push(seenUser)
    })
    const singleMessage = {
      id: message._id,
      photo: message.doctorProfile,
      title: message.title,
      content: message.content,
      createdAt: formatDate(message.createdAt),
      name: message.doctorTitle + ' ' + message.doctorLastName,
      doctorId: message.doctorId,
      seen: seenUsers,
    }
    array.push(singleMessage)
  })

  return array
})
const formatDate = (dateString: string) => {
  const [year, month, day] = dateString.split('-')
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const monthName = monthNames[parseInt(month, 10) - 1]
  return `${monthName} ${parseInt(day, 10)} ${year}`
}
</script>

<style scoped>
.custom-title {
  font-size: x-large;
}

.custom-subtitle {
  font-size: large;
}
.image {
  width: 50px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 10px;
  height: 50px;
  max-width: 50px;
  min-height: 50px;
}
</style>
