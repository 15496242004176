export interface DepartmentSpecialtiesMap {
  [key: string]: string
}

export const departmentValueToEnMap: DepartmentSpecialtiesMap = {
  Department_of_General_Surgery: "Department of General Surgery",
  Department_of_General_Medicine: "Department of General Medicine",
  Department_of_Anesthesiology: "Department of Anesthesiology",
  Department_of_Angiology: "Department of Angiology",
  Department_of_Occupational_Medicine: "Department of Occupational Medicine",
  Department_of_Ophthalmology: "Department of Ophthalmology",
  Department_of_Surgery: "Department of Surgery",
  Department_of_Dermatology: "Department of Dermatology",
  Department_of_Endocrinology_Diabetology:
    "Department of Endocrinology/Diabetology",
  Department_of_Gastroenterology: "Department of Gastroenterology",
  Department_of_Geriatrics: "Department of Geriatrics",
  Department_of_Gynecology: "Department of Gynecology",
  Department_of_Hematology_Oncology: "Department of Hematology/Oncology",
  Department_of_Cardiovascular_and_Vascular_Surgery:
    "Department of Cardiovascular and Vascular Surgery",
  Department_of_Otorhinolaryngology_ENT:
    "Department of Otorhinolaryngology (ENT)",
  Department_of_Human_Genetics: "Department of Human Genetics",
  Department_of_Infectiology: "Department of Infectiology",
  Department_of_Internal_Medicine: "Department of Internal Medicine",
  Department_of_Cardiology: "Department of Cardiology",
  Department_of_Child_and_Adolescent_Psychiatry:
    "Department of Child and Adolescent Psychiatry",
  Department_of_Pediatric_Surgery: "Department of Pediatric Surgery",
  Department_of_Laboratory_Medicine: "Department of Laboratory Medicine",
  Department_of_Microbiology: "Department of Microbiology",
  Department_of_Nephrology: "Department of Nephrology",
  Department_of_Neurosurgery: "Department of Neurosurgery",
  Department_of_Neurology: "Department of Neurology",
  Department_of_Neuroradiology: "Department of Neuroradiology",
  Department_of_Emergency_Medicine: "Department of Emergency Medicine",
  Department_of_Nuclear_Medicine: "Department of Nuclear Medicine",
  Department_of_Orthopedics: "Department of Orthopedics",
  Department_of_Pediatrics: "Department of Pediatrics",
  Department_of_Palliative_Medicine: "Department of Palliative Medicine",
  Department_of_Pathology: "Department of Pathology",
  Department_of_Physical_Medicine_and_Rehabilitation:
    "Department of Physical Medicine and Rehabilitation",
  Department_of_Plastic_Surgery: "Department of Plastic Surgery",
  Department_of_Pulmonology: "Department of Pulmonology",
  Department_of_Psychiatry: "Department of Psychiatry",
  Department_of_Psychosomatic_Medicine_and_Psychotherapy:
    "Department of Psychosomatic Medicine and Psychotherapy",
  Department_of_Radiology: "Department of Radiology",
  Department_of_Forensic_Medicine: "Department of Forensic Medicine",
  Department_of_Rheumatology: "Department of Rheumatology",
  Department_of_Sports_Medicine: "Department of Sports Medicine",
  Department_of_Radiation_Therapy: "Department of Radiation Therapy",
  Department_of_Thoracic_Surgery: "Department of Thoracic Surgery",
  Department_of_Tropical_Medicine: "Department of Tropical Medicine",
  Department_of_Trauma_Surgery: "Department of Trauma Surgery",
  Department_of_Urology: "Department of Urology",
  Department_of_Visceral_Surgery: "Department of Visceral Surgery",
  Department_of_Oral_and_Maxillofacial_Surgery:
    "Department of Oral and Maxillofacial Surgery",
  Department_of_Intensive_Care_Medicine:
    "Department of Intensive Care Medicine",
}

export const departmentValueToDeMap: DepartmentSpecialtiesMap = {
  Department_of_General_Surgery: "Abteilung der Allgemeinchirurgie",
  Department_of_General_Medicine: "Abteilung der Allgemeinmedizin",
  Department_of_Anesthesiology: "Abteilung der Anästhesiologie",
  Department_of_Angiology: "Abteilung der Angiologie",
  Department_of_Occupational_Medicine: "Abteilung der Arbeitsmedizin",
  Department_of_Ophthalmology: "Abteilung der Augenheilkunde",
  Department_of_Surgery: "Abteilung der Chirurgie",
  Department_of_Dermatology: "Abteilung der Dermatologie",
  Department_of_Endocrinology_Diabetology:
    "Abteilung der Endokrinologie/ Diabetologie",
  Department_of_Gastroenterology: "Abteilung der Gastroenterologie",
  Department_of_Geriatrics: "Abteilung der Geriatrie",
  Department_of_Gynecology: "Abteilung der Gynäkologie",
  Department_of_Hematology_Oncology: "Abteilung der Hämatologie/Onkologie",
  Department_of_Cardiovascular_and_Vascular_Surgery:
    "Abteilung der Herz- und Gefäßchirurgie",
  Department_of_Otorhinolaryngology_ENT:
    "Abteilung der Hals-Nasen-Ohren-Heilkunde (HNO)",
  Department_of_Human_Genetics: "Abteilung der Humangenetik",
  Department_of_Infectiology: "Abteilung der Infektiologie",
  Department_of_Internal_Medicine: "Abteilung der Inneren Medizin",
  Department_of_Cardiology: "Abteilung der Kardiologie",
  Department_of_Child_and_Adolescent_Psychiatry:
    "Abteilung der Kinder- und Jugendpsychiatrie",
  Department_of_Pediatric_Surgery: "Abteilung der Kinderchirurgie",
  Department_of_Laboratory_Medicine: "Abteilung der Laboratoriumsmedizin",
  Department_of_Microbiology: "Abteilung der Mikrobiologie",
  Department_of_Nephrology: "Abteilung der Nephrologie",
  Department_of_Neurosurgery: "Abteilung der Neurochirurgie",
  Department_of_Neurology: "Abteilung der Neurologie",
  Department_of_Neuroradiology: "Abteilung der Neuroradiologie",
  Department_of_Emergency_Medicine: "Abteilung der Notfallmedizin",
  Department_of_Nuclear_Medicine: "Abteilung der Nuklearmedizin",
  Department_of_Orthopedics: "Abteilung der Orthopädie",
  Department_of_Pediatrics: "Abteilung der Pädiatrie",
  Department_of_Palliative_Medicine: "Abteilung der Palliativmedizin",
  Department_of_Pathology: "Abteilung der Pathologie",
  Department_of_Physical_Medicine_and_Rehabilitation:
    "Abteilung der Physikalischen Medizin und Rehabilitation",
  Department_of_Plastic_Surgery: "Abteilung der Plastischen Chirurgie",
  Department_of_Pulmonology: "Abteilung der Pneumologie",
  Department_of_Psychiatry: "Abteilung der Psychiatrie",
  Department_of_Psychosomatic_Medicine_and_Psychotherapy:
    "Abteilung der Psychosomatischen Medizin und Psychotherapie",
  Department_of_Radiology: "Abteilung der Radiologie",
  Department_of_Forensic_Medicine: "Abteilung der Rechtsmedizin",
  Department_of_Rheumatology: "Abteilung der Rheumatologie",
  Department_of_Sports_Medicine: "Abteilung der Sportmedizin",
  Department_of_Radiation_Therapy: "Abteilung der Strahlentherapie",
  Department_of_Thoracic_Surgery: "Abteilung der Thoraxchirurgie",
  Department_of_Tropical_Medicine: "Abteilung der Tropenmedizin",
  Department_of_Trauma_Surgery: "Abteilung der Unfallchirurgie",
  Department_of_Urology: "Abteilung der Urologie",
  Department_of_Visceral_Surgery: "Abteilung der Visceralchirurgie",
  Department_of_Oral_and_Maxillofacial_Surgery:
    "Abteilung der Zahn-, Mund- und Kieferchirurgie",
  Department_of_Intensive_Care_Medicine: "Abteilung der Intensivmedizin",
}
