<template>
  <HeaderComponent />
  <request-status :response="responseStatus" :isLoading="false" />

  <v-card
    class="mx-auto pa-12 pb-8 my-15"
    elevation="8"
    max-width="548"
    rounded="lg"
  >
    <div v-if="!responseStatus.success">
      <div class="text-subtitle-1 text-medium-emphasis">
        {{ $t('password') }}
      </div>

      <v-text-field
        v-model="password"
        density="compact"
        :placeholder="$t('enter-password')"
        prepend-inner-icon="mdi-lock-outline"
        :rules="passwordRules"
        variant="outlined"
        @keyup.enter="isInfoCompleted ? resetPassword() : undefined"
      ></v-text-field>

      <div
        class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
      >
        {{ $t('re-password') }}
      </div>
      <v-text-field
        v-model="confirmPassword"
        density="compact"
        :placeholder="$t('enter-password')"
        prepend-inner-icon="mdi-lock-outline"
        :rules="passwordMatchingRule"
        variant="outlined"
        @keyup.enter="isInfoCompleted ? resetPassword() : undefined"
      ></v-text-field>

      <v-btn
        class="mb-8"
        color="blue"
        size="large"
        variant="flat"
        block
        :loading="isLoading"
        :disabled="!isInfoCompleted"
        @click="resetPassword"
      >
        {{ $t('reset-password') }}
      </v-btn>
    </div>

    <div v-else>
      <v-card-item>
        <v-card-title>{{ $t('password-reset-title') }} </v-card-title>
        <v-card-text> {{ $t('password-reset-text') }} </v-card-text>
        <v-card-text>
          <a
            @click="$router.push('/hospital-login')"
            class="text-blue text-decoration-none"
            style="cursor: pointer"
          >
            {{ $t('login') }}
          </a>
        </v-card-text>
      </v-card-item>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { doctorAuthStore } from '../../../../stores/doctor-auth'
import { useI18n } from 'vue-i18n'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import { useRoute } from 'vue-router'
import { useMeta } from 'vue-meta'
import { hospitalAuthStore } from '@/stores/hospital-auth'

const { t } = useI18n()
const route = useRoute()
const password = ref<string>('')
const confirmPassword = ref<string>('')
const isLoading = ref(false)
const token = ref()
const docId = ref()
const responseStatus = ref({})

const passwordRules = [(v: string) => !!v || t('password-required')]
const passwordMatchingRule = [
  (v: string) => v == password.value || t('password-match'),
]
onMounted(() => {
  token.value = route.query.token
  docId.value = route.query.userId
})

const isInfoCompleted = computed(() => {
  const isPasswordInvalid = passwordRules.some((rule) => {
    const result = rule(password.value)
    return typeof result === 'string'
  })

  const passwordDoNotMatch = passwordMatchingRule.some((rule) => {
    const result = rule(confirmPassword.value)
    return typeof result === 'string'
  })

  return !isPasswordInvalid && !passwordDoNotMatch
})

useMeta(
  computed(() => ({
    title: 'Medcircle',
  }))
)

const resetPassword = async () => {
  isLoading.value = true
  const hospitalAuth = hospitalAuthStore()

  const credentials = {
    userId: docId.value,
    token: token.value,
    password: password.value,
  }
  const response = await hospitalAuth.resetPassword(credentials)
  responseStatus.value = response
  isLoading.value = false
}
</script>
