<template>
  <HeaderComponent />
  <request-status :response="responseStatus" :isLoading="false" />

  <div v-if="!successfullyRegistered">
    <v-card
      class="mx-auto pa-12 pb-8 my-15"
      elevation="8"
      max-width="448"
      rounded="lg"
    >
      <div align="center">
        <v-img
          width="100px"
          :src="require('../../../../assets/doc-team2.png')"
        ></v-img>
      </div>
      <v-card-title align="center"> {{ $t('doctor-register') }}</v-card-title>

      <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
        {{ $t('doctor.title') }}
      </div>

      <v-select
        v-model="title"
        density="compact"
        :placeholder="$t('doctor.title')"
        prepend-inner-icon="mdi-pencil"
        :items="doctorTitle"
        variant="outlined"
        @keyup.enter="isInfoCompleted ? register() : undefined"
      ></v-select>

      <div class="text-subtitle-1 text-medium-emphasis">
        {{ $t('first-name') }}
      </div>

      <v-text-field
        v-model="firstName"
        density="compact"
        :placeholder="$t('first-name')"
        prepend-inner-icon="mdi-pencil"
        :rules="notEmptyRule"
        variant="outlined"
        @keyup.enter="isInfoCompleted ? register() : undefined"
      ></v-text-field>

      <div class="text-subtitle-1 text-medium-emphasis">
        {{ $t('last-name') }}
      </div>

      <v-text-field
        v-model="lastName"
        density="compact"
        :placeholder="$t('last-name')"
        prepend-inner-icon="mdi-pencil"
        :rules="notEmptyRule"
        variant="outlined"
        @keyup.enter="isInfoCompleted ? register() : undefined"
      ></v-text-field>

      <div class="text-subtitle-1 text-medium-emphasis">
        {{ $t('account') }}
      </div>

      <v-text-field
        v-model="email"
        density="compact"
        :placeholder="$t('email')"
        prepend-inner-icon="mdi-email-outline"
        :rules="emailRules"
        variant="outlined"
        @keyup.enter="isInfoCompleted ? register() : undefined"
      ></v-text-field>

      <div
        class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
      >
        {{ $t('password') }}
      </div>

      <v-text-field
        v-model="password"
        :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
        :type="showPassword ? 'text' : 'password'"
        density="compact"
        :placeholder="$t('enter-password')"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        :rules="passwordRules"
        @click:append-inner="showPassword = !showPassword"
        @keyup.enter="isInfoCompleted ? register() : undefined"
      ></v-text-field>

      <div
        class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
      >
        {{ $t('re-password') }}
      </div>

      <v-text-field
        v-model="rePassword"
        :append-inner-icon="showRePassword ? 'mdi-eye-off' : 'mdi-eye'"
        :type="showRePassword ? 'text' : 'password'"
        density="compact"
        :placeholder="$t('enter-password')"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        :rules="passwordMatchingRule"
        @click:append-inner="showRePassword = !showRePassword"
        @keyup.enter="isInfoCompleted ? register() : undefined"
      ></v-text-field>

      <v-checkbox v-model="termsAndConditions">
        <template v-slot:label>
          <div>
            {{ $t('checkbox.agree') }}
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <a href="/t-and-c" target="_blank" v-bind="props" @click.stop>
                  {{ $t('checkbox.t&c') }}
                </a>
              </template>
              {{ $t('checkbox.t&c') }}
            </v-tooltip>
          </div>
        </template>
      </v-checkbox>
      <v-btn
        class="mb-8"
        color="blue"
        size="large"
        variant="flat"
        block
        :loading="isLoading"
        :disabled="!isInfoCompleted"
        @click="register"
      >
        {{ $t('register') }}
      </v-btn>

      <v-card-text class="text-center">
        <a
          class="text-blue text-decoration-none"
          @click="$router.push('/doc-login')"
          rel="noopener noreferrer"
          style="cursor: pointer"
        >
          {{ $t('login') }}<v-icon icon="mdi-chevron-right"></v-icon>
        </a>
      </v-card-text>
    </v-card>
    <FooterComponent></FooterComponent>
  </div>

  <v-dialog
    v-model="successfullyRegistered"
    transition="dialog-bottom-transition"
    width="auto"
    style="font-family: Verdana, Geneva, Tahoma, sans-serif"
  >
    <v-card
      max-width="544"
      elevation="16"
      class="mx-auto my-16"
      style="border-radius: 1%"
    >
      <div align="center">
        <v-card-item>
          <v-card-title class="mt-2">{{
            $t('register-message-title')
          }}</v-card-title>
          <v-card-text class="mt-3">{{
            $t('register-message-text')
          }}</v-card-text>
          <v-card-text>
            <a
              class="text-blue text-decoration-none"
              rel="noopener noreferrer"
              style="cursor: pointer"
              @click="router.push('/doc-login')"
              >{{ $t('back-login') }}</a
            >
          </v-card-text>
        </v-card-item>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { doctorAuthStore } from '../../../../stores/doctor-auth'
import { useI18n } from 'vue-i18n'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import router from '@/router'
import { useMeta } from 'vue-meta'
import FooterComponent from '@/components/Footer/FooterComponent.vue'

const { t } = useI18n()
const email = ref('')
const password = ref('')
const rePassword = ref('')
const firstName = ref('')
const lastName = ref('')
const isLoading = ref(false)
const showPassword = ref(false)
const showRePassword = ref(false)
const termsAndConditions = ref(false)
const responseStatus = ref({})
const successfullyRegistered = ref(false)
const title = ref<string>('')

const emailRules = [
  (v: string) => !!v || t('email-required'),
  (v: string) => /.+@.+\..+/.test(v) || t('email-authentic'),
]

const passwordRules = [(v: string) => !!v || t('password-required')]

const notEmptyRule = [(v: string) => !!v || t('field-required')]

const passwordMatchingRule = [
  (v: string) => v == password.value || t('password-match'),
]

const doctorTitle = computed(() => {
  return ['-', 'Dr.', 'Prof. Dr.', 'Prof. Dr. Dr.']
})

const isInfoCompleted = computed(() => {
  const isEmailInvalid = emailRules.some((rule) => {
    const result = rule(email.value)
    return typeof result === 'string'
  })

  const isPasswordInValid = passwordRules.some((rule) => {
    const result = rule(password.value)
    return typeof result === 'string'
  })

  const passwordDoNotMatch = passwordMatchingRule.some((rule) => {
    const result = rule(rePassword.value)
    return typeof result === 'string'
  })

  const isValid =
    title.value !== '' &&
    firstName.value !== '' &&
    lastName.value !== '' &&
    termsAndConditions &&
    !isEmailInvalid &&
    !isPasswordInValid &&
    !passwordDoNotMatch &&
    termsAndConditions.value

  return isValid
})

useMeta(
  computed(() => ({
    title: 'Medcircle',
  }))
)

const register = async () => {
  isLoading.value = true
  const docAuthStore = doctorAuthStore()

  const credentials = {
    title: title.value,
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value ?? '',
    password: password.value,
    confirmPassword: rePassword.value,
    agreements: termsAndConditions.value,
  }
  const response = await docAuthStore.register(credentials)
  responseStatus.value = response
  if (response.success) {
    successfullyRegistered.value = true
  }
  isLoading.value = false
}
</script>
