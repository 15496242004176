<template>
  <h1 class="container-title" align="center">
    {{ $t("supported-by") }}
  </h1>

  <div align="center" class="mt-5">
    <v-img
      :width="300"
      aspect-ratio="16/9"
      cover
      src="../../../assets/lmu.png"
    ></v-img>
  </div>
</template>

<script lang="ts">
import router from "@/router"
import { defineComponent } from "vue"

export default defineComponent({
  name: "SupportedComponent",

  data() {
    return {}
  },
})
</script>
<style scoped>
.container-title {
  margin-top: 20px;
}
</style>
