<template>
  <div class="feature-videos-container" :key="features.length">
    <h1 class="container-title">
      {{ $t("feature-video.title") }}
    </h1>

    <div class="features">
      <div class="feature-description">
        <h4>{{ selectedFeature.title }}</h4>
        <div class="mt-4"><p v-html="selectedFeature.text" /></div>
      </div>
      <div class="videos">
        <div class="videos-buttons">
          <v-btn
            v-for="(feature, index) in features"
            :color="selectedFeature === feature ? '#3fb984' : undefined"
            :text="selectedFeature !== feature"
            :key="index"
            variant="text"
            small
            @click="selectedFeatureIndex = index"
            >{{ feature.title }}</v-btn
          >
        </div>

        <video id="video" autoplay muted loop playsinline>
          <source
            :src="
              require('../../../assets/videos/' +
                selectedFeature.video +
                '.mp4')
            "
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
interface Feature {
  title: string
  subtitle: string
  text: string
  video: string
  videoLength: number
}
const { t } = useI18n()

const features = ref<Feature[]>([
  {
    title: t("feature-video.student.title"),
    subtitle: t("feature-video.student.subtitle"),
    text: t("feature-video.student.text"),
    video: "student",
    videoLength: 74,
  },
  {
    title: t("feature-video.doctor.title"),
    subtitle: t("feature-video.doctor.subtitle"),
    text: t("feature-video.doctor.text"),
    video: "doctor",
    videoLength: 80,
  },
  {
    title: t("feature-video.hospital.title"),
    subtitle: t("feature-video.hospital.subtitle"),
    text: t("feature-video.hospital.text"),
    video: "hospital",
    videoLength: 84,
  },
])

const activeTimeout = ref<number>()
const selectedFeatureIndex = ref(0)

const selectedFeature = computed((): Feature => {
  return features.value[selectedFeatureIndex.value]
})

onMounted(() => {
  onSelectedFeatureChanged()
  localeChanged()
  window.addEventListener("locale-changed", localeChanged)
})
onUnmounted(() => {
  window.removeEventListener("locale-changed", localeChanged)
})

const localeChanged = () => {
  features.value = [
    {
      title: t("feature-video.student.title"),
      subtitle: t("feature-video.student.subtitle"),
      text: t("feature-video.student.text"),
      video: "student",
      videoLength: 74,
    },
    {
      title: t("feature-video.doctor.title"),
      subtitle: t("feature-video.doctor.subtitle"),
      text: t("feature-video.doctor.text"),
      video: "doctor",
      videoLength: 80,
    },
    {
      title: t("feature-video.hospital.title"),
      subtitle: t("feature-video.hospital.subtitle"),
      text: t("feature-video.hospital.text"),
      video: "hospital",
      videoLength: 84,
    },
  ]
}

watch(selectedFeatureIndex, () => {
  onSelectedFeatureChanged()
})

const onSelectedFeatureChanged = (): void => {
  var video = document.getElementById("video") as any
  video.load()
  video.play()
  video.playbackRate = 1.5

  clearTimeout(activeTimeout.value)
  activeTimeout.value = setTimeout(() => {
    selectedFeatureIndex.value =
      (selectedFeatureIndex.value + 1) % features.value.length
  }, (selectedFeature.value.videoLength * 1000) / 1.5)
}
</script>

<style scoped>
.feature-videos-container {
  padding: 10px;
  display: grid;
}
.container-title {
  padding-left: 30px;
  margin-top: 10px;
}

.video-wrapper {
  width: 100%;
}

video {
  border-radius: 10px;
  width: 100%;
  margin-top: 16px;
}

.videos {
  width: 100%;
  min-width: 300px;
  max-width: 1000px;
}
.videos-buttons {
  display: inline-flex;
  gap: 30px;
}

.features {
  display: flex;
  margin-top: 32px;
  gap: 32px;
  justify-self: center;
}

.feature-description {
  max-width: 300px;
  margin-top: 10px;
}

@media only screen and (max-width: 700px) {
  .features {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .feature-description {
    max-width: none;
    margin-top: 0;
  }
  .videos-buttons {
    display: inline-flex;
    gap: 5px;
  }
  .container-title {
    padding-left: 0px;
    margin-top: 10px;
  }
}
</style>
