<template>
  <div class="flex mt-5" style="justify-content: center">
    <v-tooltip location="bottom">
      <template v-slot:activator="{ props }">
        <v-switch
          v-bind="props"
          v-model="activeSearch"
          color="primary"
          :label="$t('doctor.calender')"
          @click="saveProfile(!activeSearch)"
        ></v-switch>
      </template>
      {{ $t("doctor.calender-tip") }}
    </v-tooltip>
  </div>

  <div v-if="activeSearch">
    <v-dialog v-model="enterManualTime" max-width="500">
      <template v-slot:activator="{ props: activatorProps }">
        <div class="flex" style="justify-content: end">
          <v-btn v-bind="activatorProps" color="#3fb984" class="mr-4">
            {{ $t("add-time-period") }}
          </v-btn>
        </div>
      </template>

      <v-card
        prepend-icon="mdi-calendar"
        :text="$t('add-time-period-text')"
        :title="$t('add-time-period')"
      >
        <div align="center" style="padding: 5%">
          <div class="text-subtitle-1 text-medium-emphasis" align="start">
            {{ $t("time-period") }}
          </div>
          <VueDatePicker
            v-model="singleTimeWindow[0]"
            range
            :enable-time-picker="false"
            :min-date="Date.now()"
            :teleport="true"
            :clearable="false"
            format="dd MMM yyyy"
          />

          <div class="text-subtitle-1 mt-2 text-medium-emphasis" align="start">
            {{ $t("number-students") }}
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="mdi-help-circle-outline"
                  color="#3fb984"
                ></v-icon>
              </template>
              {{ $t("number-students-tip") }}
            </v-tooltip>
          </div>

          <v-text-field
            density="compact"
            :placeholder="$t('number-students')"
            v-model="singleTimeWindow[1]"
            hide-details
            single-line
            type="number"
            :rules="minRule"
          />
        </div>
        <template v-slot:actions>
          <v-btn
            v-if="canDeleteTimerPeriod"
            class="mt-2 ml-2 mb-2"
            density="compact"
            color="red"
            icon="mdi-trash-can-outline"
            @click="deleteTimeWindow"
          ></v-btn>
          <v-spacer></v-spacer>

          <v-btn @click="enterManualTime = false">{{ $t("go-back") }}</v-btn>

          <v-btn
            color="primary"
            @click="saveProfile(useCalendarSearch)"
            :disabled="!infoCompleted"
            >Speichern</v-btn
          >
        </template>
      </v-card>
    </v-dialog>

    <div class="calendar mt-5" style="padding: 0px 20px" v-if="!isLoading">
      <FullCalendar :options="calendarOptions" class="mt-7" />
    </div>
  </div>
</template>

<script setup lang="ts">
export interface CalendarEvents {
  id: string
  title: string
  start: Date
  end: Date
  color?: string
  textColor?: string
  startEditable: boolean
}

import { useI18n } from "vue-i18n"
import {
  onBeforeUnmount,
  onMounted,
  ref,
  defineProps,
  watch,
  computed,
  defineEmits,
} from "vue"

import { RequestResponse } from "@/types"

import FullCalendar from "@fullcalendar/vue3"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import moment from "moment"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import deLocale from "@fullcalendar/core/locales/de"

const { t } = useI18n()
const emit = defineEmits(["saved", "changedCalendarSearch"])
const events = ref<CalendarEvents[]>([])
const singleTimeWindow = ref<any[]>([["", ""], 1])

const enterManualTime = ref<boolean>(false)
const calendarOptions = ref({
  plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
  initialView: "dayGridMonth",
  headerToolbar: {
    left: "prev next today",
    center: "title",
    right: "dayGridMonth,timeGridWeek,listWeek",
  },
  selectable: true,
  timezone: "local",
  weekends: true,
  events: events.value,
  displayEventTime: false,
  locale: localStorage.getItem("locale") == "de" ? deLocale : "en",
  eventClick: function (info: any) {
    singleTimeWindow.value[0][0] = moment(info.event.start)
      .locale("de")
      .toDate()
    singleTimeWindow.value[0][1] = moment(info.event.end).locale("de").toDate()
    singleTimeWindow.value[1] = getNumberOfApplicants(info.event._def.publicId)
    index.value = parseInt(info.event._def.publicId)
    canDeleteTimerPeriod.value = true
    enterManualTime.value = true
  },
  select: function (info: any) {
    singleTimeWindow.value[0][0] = info.start
    singleTimeWindow.value[0][1] = info.end
    addingNewTimeWindow.value = true
    enterManualTime.value = true
  },
})

const minRule = [
  (v: number) => !!v || t("required"),
  (v: number) => v > 0 || t("min-student-required"),
]
const isLoading = ref(true)
const addingNewTimeWindow = ref(false)

const props = defineProps<{ manualTime: any[][]; useCalendarSearch: boolean }>()
const index = ref<number>(props.manualTime.length - 1)
const activeSearch = ref(props.useCalendarSearch ?? false)
const canDeleteTimerPeriod = ref(false)

onMounted(async () => {
  window.addEventListener("locale-changed", updateCalendarLocale)
  calendarOptions.value.events = [...events.value]

  isLoading.value = false
})

onBeforeUnmount(() => {
  window.removeEventListener("locale-changed", updateCalendarLocale)
})

const deleteTimeWindow = () => {
  singleTimeWindow.value[1] = parseInt(singleTimeWindow.value[1])

  const data = {
    timeWindow: singleTimeWindow.value,
    index: index.value,
    addingNewTimeWindow: addingNewTimeWindow.value,
    deletingTimeWindow: true,
    useCalendarSearch: true,
  }

  emit("saved", data)

  enterManualTime.value = false
}

const saveProfile = (useCalendarNewVal: boolean) => {
  singleTimeWindow.value[1] = parseInt(singleTimeWindow.value[1])

  const data = {
    timeWindow: singleTimeWindow.value,
    index: index.value,
    addingNewTimeWindow: addingNewTimeWindow.value,
    deletingTimeWindow: false,
    useCalendarSearch: useCalendarNewVal,
  }

  emit("saved", data)

  enterManualTime.value = false
}

const infoCompleted = computed((): boolean => {
  return (
    singleTimeWindow.value[0][0] !== null &&
    singleTimeWindow.value[0][0] !== "" &&
    singleTimeWindow.value[0][1] !== null &&
    singleTimeWindow.value[0][1] !== "" &&
    singleTimeWindow.value[1] >= 1
  )
})

const updateCalendarLocale = () => {
  calendarOptions.value.locale =
    localStorage.getItem("locale") == "de" ? deLocale : "en"
  buildEvents(props.manualTime)
}

const getNumberOfApplicants = (index: string): number => {
  let val = 1
  props.manualTime.forEach((newVal, i) => {
    if (i.toString() == index) {
      val = newVal[1]
    }
  })
  return val
}

watch(
  () => enterManualTime,
  (enterManualTime) => {
    if (!enterManualTime.value) {
      addingNewTimeWindow.value = false
      canDeleteTimerPeriod.value = false
    }
  },
  { deep: true, immediate: true }
)

const buildEvents = (manualTime: any[][]) => {
  manualTime.forEach((singleTimeWindow: any[], index) => {
    events.value.push({
      title: `${t("calendar-open")} ${singleTimeWindow[1]} ${t("interns")}`,
      start: moment(singleTimeWindow[0][0]).locale("de").toDate(),
      end: moment(singleTimeWindow[0][1]).locale("de").toDate(),
      color: "#25344d",
      id: index.toString(),
      startEditable: false,
    })
  })
  calendarOptions.value.events = [...events.value]
}

watch(
  () => props.manualTime,
  (newVal) => {
    isLoading.value = true

    if (newVal) {
      buildEvents(newVal)
    }
    isLoading.value = false
  },
  { deep: true, immediate: true }
)

watch(
  () => props.useCalendarSearch,
  (newVal) => {
    isLoading.value = true

    activeSearch.value = newVal
    isLoading.value = false
  },
  { deep: true, immediate: true }
)
</script>
<style>
.fc-event {
  cursor: pointer;
}
.fc-view-harness {
  height: 100vh !important;
}
.fc-view-harness-active {
  height: 100vh !important;
}
</style>
