<template>
  <request-status :response="requestResponse" :isLoading="loading" />

  <SingleApplication
    :isStudent="false"
    :singleApplication="application"
    @action-taken="fetchApplication"
    v-if="!loading"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch, defineEmits } from 'vue'

import { useI18n } from 'vue-i18n'

import { doctorActions } from '@/stores/doctor-actions'
import { useRoute } from 'vue-router'
import { Applicant } from '@/types'
import SingleApplication from '@/components/Static/Application/SingleApplication.vue'
export default defineComponent({
  components: {
    SingleApplication,
  },
  name: 'DoctorApplication',

  setup(props) {
    const { t } = useI18n()

    const docAction = doctorActions()
    const route = useRoute()
    const requestResponse = ref()
    const application = ref<Applicant>()
    const loading = ref(true)
    const id = ref()
    onMounted(() => {
      id.value = route.params.id
      fetchApplication()
    })

    const fetchApplication = async () => {
      loading.value = true

      const response = await docAction.getSingleApplication(id.value)
      requestResponse.value = response
      if (response.success) {
        application.value = response.result as Applicant
      }
      loading.value = false
    }

    return { application, loading, requestResponse, fetchApplication, id }
  },
  methods: {},
})
</script>

<style>
.text {
  font-size: 50px;
  font-weight: 500;
  margin-top: 130px;
}
.sub-text {
  font-size: 20px;
  font-weight: 300;
}

.content {
  overflow-x: hidden;
}
.footer {
  margin-top: 200px;
}
</style>
