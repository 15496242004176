<template>
  <HeaderComponent></HeaderComponent>
  <div align="center">
    <div align="start" style="width: 70%" class="mt-10">
      <h1 style="font-size: xxx-large">Attribution</h1>
      <p>
        We would like to thank the following image and icon providers for
        providing the images and icons used in our products for free.
      </p>

      <ul>
        <li>
          <a
            class="text-blue text-decoration-none"
            rel="noopener noreferrer"
            style="cursor: pointer"
            href="https://www.freepik.com/"
            >Freepik
          </a>
        </li>
        <li>
          <a
            class="text-blue text-decoration-none"
            rel="noopener noreferrer"
            style="cursor: pointer"
            href="https://www.flaticon.com/"
            >Flaticon</a
          >
        </li>
      </ul>
    </div>
  </div>
  <FooterComponent class="footer-here"></FooterComponent>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import FooterComponent from '@/components/Footer/FooterComponent.vue'
import { computed } from 'vue'
import { useMeta } from 'vue-meta'
const { t } = useI18n()
useMeta(
  computed(() => ({
    title: 'Attribution',
  }))
)
</script>
<style>
.footer-here {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>
