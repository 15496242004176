<template>
  <v-dialog v-model="showBanner" persistent>
    <v-sheet
      border="md"
      class="pa-6 text-black mx-auto"
      color="white"
      :max-width="!smallWindow ? '450px' : '300px'"
      style="
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        border-radius: 5%;
      "
    >
      <div align="center">
        <v-img
          :src="require('../../../assets/main-logo.png')"
          width="100px"
        ></v-img>
      </div>
      <h4 class="text-h5 font-weight-bold mb-4">
        MEDCIRCLE steht für Datenschutz!
      </h4>

      <p class="mb-8" style="font-size: 15px">
        Bei MEDCIRCLE legen wir großen Wert auf Datenschutz! Wir verwenden
        Cookies, um zu lernen, wie wir Sie am besten erreichen können, ohne
        dabei persönliche Daten zu erfassen. Sie haben die Wahl, nur essentielle
        Cookies zu akzeptieren oder uns zu unterstützen, indem Sie alle Cookies
        akzeptieren, damit wir aus anonymisierten Daten lernen können. Unsere
        Datenschutzerklärung finden Sie
        <router-link to="/privacy-policy" target="_blank">hier</router-link>..
        Ganz gleich, wie Sie sich entscheiden: Herzlich willkommen bei
        MEDCIRCLE!

        <br />
        <br />
      </p>

      <v-btn color="primary" @click="onDecisionMade(true)" block
        >Alle Cookies akzeptieren</v-btn
      >
      <v-btn class="mt-2" color="secondary" @click="onDecisionMade(false)" block
        >Nur essentielle Cookies akzeptieren</v-btn
      >
    </v-sheet>
  </v-dialog>
</template>
<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const showBanner = ref(false)
const smallWindow = ref(window.innerWidth < 500)

onMounted(() => {
  const decision = localStorage.getItem('cookies-decision')
  if (!decision) {
    showBanner.value = true
  }
})
onMounted(() => {
  window.addEventListener('resize', updateWindowSize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowSize)
})

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}

const onDecisionMade = (decision: boolean) => {
  if (decision) {
    localStorage.setItem('cookies-decision', 'yes')
  } else {
    localStorage.setItem('cookies-decision', 'no')
  }
  showBanner.value = false
}
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}
.v-card:not(.on-hover) {
  opacity: 0.8;
}
</style>
