<template>
  <v-menu offset-y open-on-hover open-on-click eager>
    <template v-slot:activator="{ props }">
      <v-btn flat v-bind="props" color="#25344d">
        <img :src="require(`@/assets/locales/${$i18n.locale}.png`)" />
      </v-btn>
    </template>

    <div class="d-flex flex-column gap">
      <v-btn
        v-for="(locale, index) in locales"
        :key="index"
        @click="updateLocale(locale.value)"
      >
        <img :src="locale.img" />
        {{ locale.name }}
      </v-btn>
    </div>
  </v-menu>
</template>

<script setup lang="ts">
import { onMounted } from "vue"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"

const locales = [
  {
    name: "DE",
    img: require("@/assets/locales/de.png"),
    value: "de",
  },
  {
    name: "EN",
    img: require("@/assets/locales/en.png"),
    value: "en",
  },
]
const i18n = useI18n()

const $router = useRouter()

onMounted(() => {
  const locale = localStorage.getItem("locale")
  if (locale) {
    i18n.locale.value = locale
  }
})
const updateLocale = (locale: string): void => {
  localStorage.setItem("locale", locale)
  i18n.locale.value = locale
  window.dispatchEvent(new CustomEvent("locale-changed"))
}
</script>

<style scoped>
img {
  width: 20px;
  height: 20px;
  margin-right: 2px;
  border-radius: 16px;
}
</style>
