<template>
  <div>
    <v-divider class="mt-3"></v-divider>

    <div class="container-answer-title">
      <h1>{{ $t("the-answer") }}: MEDCIRCLE</h1>
    </div>
    <div align="start" justify="space-evenly">
      <v-slide-group :show-arrows="true">
        <v-slide-group-item v-for="(card, index) in cards" :key="index">
          <v-card
            class="mx-auto ml-10 mt-5 mb-10"
            :class="smallWindow ? 'mr-5' : 'mr-10'"
            :width="smallWindow ? 250 : 500"
            elevation="10"
            style="border-radius: 5%"
          >
            <v-img height="200px" :src="getImage(card.photo)" cover></v-img>

            <v-card-title> {{ card.title }} </v-card-title>

            <v-chip
              class="ml-3 mt-2"
              v-for="city in card.cities"
              :key="city"
              prepend-icon="mdi-map-marker"
              >{{ city }}</v-chip
            >
            <v-card-actions>
              <v-btn
                color="#3fb984"
                text="Explore"
                @click="show = !show"
              ></v-btn>

              <v-spacer></v-spacer>

              <v-btn
                :icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                @click="show = !show"
              ></v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>

                <v-card-text>
                  {{ card.explanation }}
                </v-card-text>
                <v-card-text v-if="card.subExplanation">
                  <p style="font-weight: 500">
                    {{ card.subExplanation }}
                  </p>
                </v-card-text>
                <div v-if="card.bulletPoints">
                  <v-card-text v-for="point in card.bulletPoints" :key="point">
                    <v-icon color="#3fb984" icon="mdi-check"></v-icon>
                    {{ point }}
                  </v-card-text>
                </div>
                <div v-if="card.logo">
                  <v-img height="150px" :src="getImage(card.logo)"></v-img>
                </div>
              </div>
            </v-expand-transition>
          </v-card>
        </v-slide-group-item>
      </v-slide-group>

      <v-divider style="margin-top: 10px"></v-divider>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"

export interface AdvantageCard {
  explanation: string
  subExplanation?: string
  title: string
  photo: string
  bulletPoints?: string[]
  cities?: string[]
  logo?: string
}

export default defineComponent({
  name: "AdvantageDisadvantageComponent",

  data() {
    const { t } = useI18n()
    const cards = ref<AdvantageCard[]>([])
    cards.value = [
      {
        explanation: t("advantage.famulatur.explanation"),
        title: t("advantage.famulatur.title"),
        photo: "doc-line.jpg",
        subExplanation: t("advantage.famulatur.subExplanation"),
        bulletPoints: [
          t("advantage.famulatur.bulletPoint1"),
          t("advantage.famulatur.bulletPoint2"),
        ],
        cities: ["München", "Ulm", "Freising"],
      },
      {
        explanation: t("advantage.pj.explanation"),
        title: t("advantage.pj.title"),
        subExplanation: t("advantage.pj.subExplanation"),
        photo: "single-doc.jpg",
        logo: "work-in-progress.png",
      },

      {
        explanation: t("advantage.further-education.explanation"),
        title: t("advantage.further-education.title"),
        subExplanation: t("advantage.further-education.subExplanation"),
        photo: "doc-research.jpg",
        logo: "work-in-progress.png",
      },
    ]

    const show = ref(false)

    function getImage(photo: string | undefined) {
      return require(`../../../assets/${photo}`)
    }

    const smallWindow = ref(window.innerWidth < 1000)

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    onMounted(() => {
      localeChanged()
      window.addEventListener("locale-changed", localeChanged)
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
      window.removeEventListener("locale-changed", localeChanged)
    })

    const localeChanged = () => {
      cards.value = [
        {
          explanation: t("advantage.famulatur.explanation"),
          title: t("advantage.famulatur.title"),
          photo: "doc-line.jpg",
          subExplanation: t("advantage.famulatur.subExplanation"),
          bulletPoints: [
            t("advantage.famulatur.bulletPoint1"),
            t("advantage.famulatur.bulletPoint2"),
          ],
          cities: ["München", "Ulm", "Freising"],
        },
        {
          explanation: t("advantage.pj.explanation"),
          title: t("advantage.pj.title"),
          subExplanation: t("advantage.pj.subExplanation"),
          photo: "single-doc.jpg",
          logo: "work-in-progress.png",
        },

        {
          explanation: t("advantage.further-education.explanation"),
          title: t("advantage.further-education.title"),
          subExplanation: t("advantage.further-education.subExplanation"),
          photo: "doc-research.jpg",
          logo: "work-in-progress.png",
        },
      ]
    }

    return {
      t,
      cards,
      show,
      getImage,
      smallWindow,
    }
  },
})
</script>

<style>
.container-answer-title {
  padding-left: 85px;
  margin-top: 10px;
}
@media only screen and (max-width: 700px) {
  .container-answer-title {
    padding-left: 10px;
    margin-top: 10px;
  }
}
</style>
