<template><div></div></template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue"
import router from "@/router"
import { useUserStore } from "@/stores/user-auth"

export default defineComponent({
  components: {},
  name: "LogoutComponent",

  setup() {
    onMounted(() => {
      const userStore = useUserStore()

      localStorage.removeItem("accessToken")

      userStore.isAuthenticated = false
      router.push("/")
    })
  },
})
</script>
