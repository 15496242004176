import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../assets/lmu.png'


const _withScopeId = n => (_pushScopeId("data-v-68e9b7f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "container-title",
  align: "center"
}
const _hoisted_2 = {
  align: "center",
  class: "mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("supported-by")), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_img, {
        width: 300,
        "aspect-ratio": "16/9",
        cover: "",
        src: _imports_0
      })
    ])
  ], 64))
}