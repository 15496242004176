import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_component = _resolveComponent("header-component")!
  const _component_FirstComponent = _resolveComponent("FirstComponent")!
  const _component_SecondComponent = _resolveComponent("SecondComponent")!
  const _component_AdvantageDisadvantage = _resolveComponent("AdvantageDisadvantage")!
  const _component_CounterComponent = _resolveComponent("CounterComponent")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_FeatureVideos = _resolveComponent("FeatureVideos")!
  const _component_top_doctors_component = _resolveComponent("top-doctors-component")!
  const _component_supported_component = _resolveComponent("supported-component")!
  const _component_footer_component = _resolveComponent("footer-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_component),
    _createVNode(_component_FirstComponent),
    _createVNode(_component_SecondComponent),
    _createVNode(_component_AdvantageDisadvantage),
    _createVNode(_component_CounterComponent),
    _createVNode(_component_v_divider, { style: {"margin-top":"50px"} }),
    _createVNode(_component_FeatureVideos),
    _createVNode(_component_v_divider),
    _createVNode(_component_top_doctors_component),
    _createVNode(_component_v_divider),
    _createVNode(_component_supported_component),
    _createVNode(_component_footer_component)
  ], 64))
}