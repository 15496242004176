<template>
  <HospitalHeader></HospitalHeader>
  <request-status
    class="mt-6"
    :response="responseStatus"
    :isLoading="isLoading"
  />

  <div class="mt-6" align="center" v-if="!isLoading">
    <v-card>
      <v-layout>
        <v-navigation-drawer
          :expand-on-hover="smallWindow"
          :rail="smallWindow"
          :permanent="!smallWindow"
          mobile-breakpoint="xs"
          width="260"
          height="100vh"
        >
          <v-list align="start">
            <v-list-item
              :prepend-avatar="
                hospitalAction.hospitalProfileLink != '' &&
                hospitalAction.hospitalProfileLink != undefined
                  ? hospitalAction.hospitalProfileLink
                  : require('../../../assets/blank.png')
              "
              :title="name"
            ></v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list density="compact" nav align="start">
            <v-list-item
              prepend-icon="mdi-account"
              :title="$t('general-info')"
              :class="{
                'v-list-item--active':
                  selectedItem === 'Hospital General Information',
              }"
              @click="navigateTo('Hospital General Information')"
            >
            </v-list-item>
            <v-list-item
              prepend-icon="mdi-hospital"
              :title="$t('doctor.interns')"
              :class="{
                'v-list-item--active':
                  selectedItem === 'Hospital Internship Information',
              }"
              @click="navigateTo('Hospital Internship Information')"
            ></v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-main>
          <router-view />
        </v-main>
      </v-layout>
    </v-card>
  </div>
  <FooterComponent class="mb-15"></FooterComponent>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, onBeforeUnmount, ref, watch } from "vue"
import { Universities } from "@/types"
import HospitalHeader from "../Headers/HospitalHeader.vue"
import router from "@/router"
import { onMounted } from "vue"

import FooterComponent from "@/components/Footer/FooterComponent.vue"
import { useRoute } from "vue-router"
import { hospitalActions } from "@/stores/hospital-actions"

const { t } = useI18n()

const hospitalAction = hospitalActions()
const isLoading = ref(false)
const token = ref(localStorage.getItem("docAccessToken") ?? "")
const responseStatus = ref({})
const val = ref(true)
const selectedItem = ref<string>("info")
const universities = ref<Universities[]>([])
const route = useRoute()
const smallWindow = ref(window.innerWidth < 600)

onMounted(async () => {
  await getUniversities()
  navigateTo((routeName.value as string) ?? "Hospital General Information")
})

const routeName = computed(() => {
  return route.name
})

watch([routeName], () => {
  selectedItem.value =
    (routeName.value as string) ?? "Hospital General Information"
})

onMounted(() => {
  window.addEventListener("resize", updateWindowSize)
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateWindowSize)
})

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}

const getUniversities = async () => {
  isLoading.value = true
  const response = await hospitalAction.getUniversities()
  responseStatus.value = response
  if (response.success) {
    universities.value = response.result as Universities[]
  }
  isLoading.value = false
}

const name = computed(() => {
  return `${hospitalAction.hospitalName}`
})

const navigateTo = (item: string) => {
  selectedItem.value = item
  router.push({ name: item })
}
</script>

<style scoped>
.custom-title {
  font-size: x-large;
}

.custom-subtitle {
  font-size: large;
}
</style>
