<template>
  <request-status :response="requestResponse" :isLoading="isLoading" />

  <h3 align="center" class="mt-5">{{ $t("doctor.intern-info") }}</h3>

  <v-divider class="mt-4"></v-divider>
  <div v-if="!isLoading">
    <v-col class="mt-10 mb-10">
      <div class="flex flex-wrap">
        <div class="flex-area-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("doctor.famulatur") }}
          </div>
          <v-textarea
            v-model="internshipSpecialty"
            class="ml-2"
            density="compact"
            :placeholder="$t('doctor.this-internship-text')"
            variant="outlined"
            rows="4"
            style="min-width: 300px"
          ></v-textarea>
        </div>
        <div class="flex-area-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("doctor.area") }}
          </div>
          <v-textarea
            v-model="area"
            class="ml-2"
            density="compact"
            :placeholder="$t('doctor.area-text')"
            variant="outlined"
            rows="4"
            style="min-width: 300px"
          ></v-textarea>
        </div>
        <div class="flex-area-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("doctor.financial-help") }}
          </div>
          <v-textarea
            v-model="financialHelp"
            class="ml-2"
            density="compact"
            :placeholder="$t('doctor.financial-help-text')"
            variant="outlined"
            rows="4"
            style="min-width: 300px"
          ></v-textarea>
        </div>
        <div class="flex-area-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("doctor.requirement") }}
          </div>
          <v-textarea
            v-model="requirements"
            class="ml-2"
            density="compact"
            :placeholder="$t('doctor.requirement-text')"
            variant="outlined"
            rows="4"
            style="min-width: 300px"
          ></v-textarea>
        </div>
      </div>
    </v-col>
    <v-footer
      style="
        display: flex;
        width: 96%;
        justify-content: end;
        background-color: #f2f2f2;
      "
    >
      <div>
        <div>
          <v-btn
            class="mr-10"
            rounded="lg"
            color="#3fb984"
            variant="flat"
            size="large"
            width="150"
            :loading="isSaving"
            @click="saveProfileData()"
          >
            {{ $t("save") }}
          </v-btn>
        </div>
      </div>
    </v-footer>
  </div>
</template>

<script lang="ts">
import { ApplicantTime, DoctorProfile, Student } from "@/types"
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useUserActions } from "@/stores/user-actions"
import { doctorActions } from "@/stores/doctor-actions"

export default defineComponent({
  name: "FamulaturComponent",

  setup(props, { emit }) {
    const { t } = useI18n()
    const requestResponse = ref({})
    const applications = ref()
    const isLoading = ref(false)
    const smallWindow = ref(window.innerWidth < 1000)
    const internshipSpecialty = ref<string | undefined>(undefined)
    const area = ref<string | undefined>(undefined)
    const financialHelp = ref<string | undefined>(undefined)
    const requirements = ref<string | undefined>(undefined)
    const isSaving = ref(false)
    const docAction = doctorActions()
    const doctor = ref<DoctorProfile>()

    onMounted(async () => {
      await getProfile()
      internshipSpecialty.value = doctor.value?.selectReason
      area.value = doctor.value?.areaDescription
      financialHelp.value = doctor.value?.financialDescription
      requirements.value = doctor.value?.requirementsForStudent
    })

    const getProfile = async () => {
      isLoading.value = true
      const response = await docAction.getProfile()
      requestResponse.value = response
      if (response.success) {
        doctor.value = response.result as DoctorProfile
      }
      isLoading.value = false
    }

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })

    return {
      requestResponse,
      isLoading,
      applications,

      t,

      smallWindow,
      internshipSpecialty,
      area,
      financialHelp,
      requirements,
      isSaving,
    }
  },
  methods: {
    async saveProfileData(): Promise<void> {
      this.isSaving = true
      const docAction = doctorActions()

      const data = {
        selectReason: this.internshipSpecialty,
        areaDescription: this.area,
        financialDescription: this.financialHelp,
        requirementsForStudent: this.requirements,
      }

      const response = await docAction.updateProfile(data)
      this.requestResponse = response

      if (response.success) {
        this.$toast.success(this.$t("profile-upload-successful"), {
          position: "bottom-left",
        })
        this.$emit("profile-saved", "applications")
      } else {
        this.$toast.error(this.$t("profile-not-upload-successful"), {
          position: "bottom-left",
        })
      }

      this.isSaving = false
    },
  },
})
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 25%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}
.flex-area-item {
  flex: 1 0 40%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}
.pointer {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
}
</style>
