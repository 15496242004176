<template>
  <v-card class="mr-4">
    <v-card-title class="custom-title">
      {{ t('doctor.student-comment') }}
    </v-card-title>
    <v-expansion-panels>
      <v-expansion-panel :text="$t('be-first')">
        <v-expansion-panel-title>
          {{ $t('no-comments') }}
        </v-expansion-panel-title>
      </v-expansion-panel>
      <!-- <v-expansion-panel
        text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima"
      >
        <v-expansion-panel-title> Leon Mogli </v-expansion-panel-title>
        <v-rating
          v-model="ratingLeon"
          active-color="blue"
          color="orange-lighten-1"
          readonly
        ></v-rating>
      </v-expansion-panel> -->
    </v-expansion-panels>
  </v-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, defineProps, ref } from 'vue'
import { DoctorProfile } from '@/types'
import HeaderComponent from '../Header/HeaderComponent.vue'
import router from '@/router'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useUserActions } from '@/stores/user-actions'

const { t } = useI18n()
const route = useRoute()
const id = ref()
const userAction = useUserActions()
const isLoading = ref(true)
const doctor = ref<DoctorProfile | undefined>(undefined)
const rating = ref(3)
const ratingLeon = ref(5)
onMounted(() => {
  id.value = route.params.id
})
</script>

<style scoped></style>
