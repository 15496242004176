<template>
  <div>
    <v-card class="mr-2" height="820" elevation="5" style="overflow-y: scroll">
      <v-tabs
        v-model="tab"
        show-arrows
        bg-color="#25344d"
        fixed-tabs
        v-if="!applyClicked"
      >
        <v-tab value="INFORMATION">{{
          $t("hospital.general-information")
        }}</v-tab>
        <v-tab value="DOCTORS">
          {{ $t("hospital.doctors") }}
        </v-tab>
      </v-tabs>
      <v-tabs v-model="tab" show-arrows bg-color="#25344d" fixed-tabs v-else>
        <v-tab value="INFORMATION">{{
          $t("hospital.general-information")
        }}</v-tab>
        <v-tab value="DOCTORS">
          {{ $t("hospital.doctors") }}
        </v-tab>
        <v-tab value="APPLY">
          {{ $t("apply") }}
        </v-tab>
      </v-tabs>

      <v-window v-model="tab">
        <v-window-item value="INFORMATION">
          <v-list lines="one" class="mt-5" align="start">
            <v-list-item>
              <template v-slot:prepend>
                <v-avatar color="blue-darken-2">
                  <v-icon icon="mdi-star"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <div style="font-size: large; font-weight: 500">
                  {{ $t("doctor.famulatur") }}
                </div>
              </template>
              <div style="font-size: small" justify-start>
                {{ hospital?.selectReason ?? lorem }}
              </div></v-list-item
            >
          </v-list>

          <v-list lines="one" align="start">
            <v-list-item>
              <template v-slot:prepend>
                <v-avatar color="blue-darken-2">
                  <v-icon icon="mdi-clipboard-list"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <div style="font-size: large; font-weight: 500">
                  {{ $t("doctor.requirement") }}
                </div>
              </template>
              <div style="font-size: small" justify-start>
                {{ hospital?.requirementsForStudent ?? lorem }}
              </div>
            </v-list-item>
          </v-list>

          <v-list lines="one" align="start">
            <v-list-item>
              <template v-slot:prepend>
                <v-avatar color="blue-darken-2">
                  <v-icon icon="mdi-currency-eur"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <div style="font-size: large; font-weight: 500">
                  {{ $t("doctor.financial-help") }}
                </div>
              </template>
              <div style="font-size: small" justify-start>
                {{ hospital?.financialDescription ?? lorem }}
              </div>
            </v-list-item>
          </v-list>
          <v-list lines="one" align="start">
            <v-list-item>
              <template v-slot:prepend>
                <v-avatar color="blue-darken-2">
                  <v-icon icon="mdi-home-city"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <div style="font-size: large; font-weight: 500">
                  {{ $t("doctor.area") }}
                </div>
              </template>
              <div style="font-size: small" justify-start>
                {{ hospital?.areaDescription ?? lorem }}
              </div>
            </v-list-item>
          </v-list>
          <v-card-actions class="justify-center mt-10" v-if="!applyClicked">
            <v-btn
              rounded="lg"
              color="#3fb984"
              variant="flat"
              size="large"
              width="180"
              @click="apply"
            >
              {{ $t("apply") }}
            </v-btn>
          </v-card-actions>
        </v-window-item>
        <v-window-item value="DOCTORS">
          <hospital-doctor-info :hospital="hospital" />
        </v-window-item>

        <v-window-item value="APPLY">
          <hospital-apply :hospital="hospital" />
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, defineProps, ref } from "vue"
import { Hospital } from "@/types"
import HospitalDoctorInfo from "./HospitalDoctorInfo.vue"
import router from "@/router"
import { useUserActions } from "@/stores/user-actions"
import HospitalApply from "./HospitalApply.vue"

const { t } = useI18n()

const props = defineProps<{ hospital: Hospital | undefined }>()
const hospital = ref<Hospital | undefined>(props.hospital)
const tab = ref("")
const lorem = ref(
  "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad eos adipisci corporis, dolore quidem perspiciatis officiis voluptatibus soluta molestiae asperiores, libero rem fugiat quam iusto, cumque distinctio nesciunt dolorum labore?"
)
const applyClicked = ref(false)
const userAction = useUserActions()

const apply = async () => {
  localStorage.setItem("hospitalId", hospital.value?._id ?? "")

  const token = localStorage.getItem("accessToken") ?? null
  if (token) {
    const response = await userAction.getStudentProfile(token)
    if (response.success) {
      localStorage.removeItem("hospitalId")
      tab.value = "APPLY"
    } else {
      router.push("/login")
    }
  } else {
    router.push("/login")
  }

  applyClicked.value = true
}
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
</style>
