<template>
  <HeaderComponent></HeaderComponent>

  <request-status
    class="mt-6"
    :response="responseStatus"
    :isLoading="isLoading"
  />
  <div class="mt-6" align="center" v-if="!isLoading">
    <v-card>
      <v-layout>
        <v-navigation-drawer
          :expand-on-hover="smallWindow"
          :rail="smallWindow"
          :permanent="!smallWindow"
          mobile-breakpoint="xs"
          width="260"
        >
          <v-list align="start" density="compact">
            <v-list-item
              :prepend-avatar="
                photo != '' || undefined
                  ? photo
                  : require('../../assets/blank.png')
              "
              :subtitle="email"
              :title="name"
            ></v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list density="compact" nav align="start" :key="selectedItem">
            <v-list-item
              prepend-icon="mdi-account"
              :title="$t('general-info')"
              :class="{
                'v-list-item--active': selectedItem === 'General Information',
                'v-list-item--inactive': selectedItem !== 'General Information',
              }"
              value="General Information"
              @click="navigate('General Information')"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-application"
              :title="$t('your-applications')"
              value="Student Application"
              :class="{
                'v-list-item--active': selectedItem === 'Student Application',
                'v-list-item--inactive': selectedItem !== 'Student Application',
              }"
              @click="navigate('Student Application')"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-email-outline"
              :title="$t('your-messages')"
              value="Student Messages"
              :class="{
                'v-list-item--active': selectedItem === 'Student Messages',
                'v-list-item--inactive': selectedItem !== 'Student Messages',
              }"
              @click="navigate('Student Messages')"
              ><template v-slot:append>
                <v-badge
                  v-if="notifications > 0"
                  color="primary"
                  :content="notifications"
                  inline
                ></v-badge> </template
            ></v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-main style="min-height: 100vh">
          <router-view />
        </v-main>
      </v-layout>
    </v-card>
  </div>
  <FooterComponent></FooterComponent>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, onBeforeUnmount, ref, watch } from "vue"
import { Applicant, DoctorProfile, Student } from "@/types"
import HeaderComponent from "../Header/HeaderComponent.vue"
import router from "@/router"
import { onMounted } from "vue"
import { useUserActions } from "@/stores/user-actions"
import GeneralInformation from "./GeneralInformation/GeneralInformation.vue"
import ApplicationsProfileComponent from "./Applications/ApplicationsProfileComponent.vue"
import FooterComponent from "../Footer/FooterComponent.vue"
import SingleApplication from "../Static/Application/SingleApplication.vue"
import StudentMessages from "./Messages/StudentMessages.vue"
import { broadcastingActions } from "@/stores/broadcasting-store"
import { useRoute } from "vue-router"
import { useMeta } from "vue-meta"
const { t } = useI18n()

const userAction = useUserActions()
const isLoading = ref(false)
const token = ref(localStorage.getItem("accessToken") ?? "")
const responseStatus = ref({})
const selectedItem = ref<string>("info")
const notifications = ref(0)
const broadcasting = broadcastingActions()
const smallWindow = ref(window.innerWidth < 1000)

const route = useRoute()

onMounted(() => {
  getNotifications()
  navigate((path.value as string) ?? "General Information")
})

onMounted(() => {
  window.addEventListener("resize", updateWindowSize)
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateWindowSize)
})

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}
const path = computed(() => {
  return route.name
})

const photo = computed(() => {
  return userAction.userProfileLink
})
const name = computed(() => {
  return userAction.name
})
const email = computed(() => {
  return userAction.email
})

watch(path, () => {
  selectedItem.value = path.value as string
})

useMeta(
  computed(() => ({
    title: name.value,
  }))
)

const navigate = (page: string) => {
  selectedItem.value = page
  router.push({ name: page })
}

const getNotifications = async () => {
  const response = await broadcasting.getNotifications()
  responseStatus.value = response
  if (response.success) {
    notifications.value = response.result as number
  }
}
</script>

<style scoped>
.custom-title {
  font-size: x-large;
}

.custom-subtitle {
  font-size: large;
}
</style>
