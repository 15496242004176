import { defineStore } from "pinia"
import apiClient from "./apiclients"
import { RequestResponse } from "./../types/index"
import { hospitalAuthStore } from "./hospital-auth"
export const hospitalActions = defineStore({
  id: "hospital-actions",
  state: () => ({
    hospitalName: "",
    hospitalProfileLink: "",
    studentTableTab: "",
  }),

  actions: {
    async getProfile(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const token = localStorage.getItem("hospitalAccessToken")
      await apiClient
        .get("hospital/get-profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },

    async isTokenValid(): Promise<boolean> {
      let isValid = false
      let url = ""
      let name = ""
      const hospitalAuth = hospitalAuthStore()

      const token = localStorage.getItem("hospitalAccessToken")
      if (token) {
        await apiClient
          .get("hospital/get-profile", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response) {
            isValid = response.data.success
            if (isValid) {
              hospitalAuth.isAuthenticated = true
            }
            url = response.data.result.photo
            name = response.data.result.name
          })
          .catch(function (error) {
            hospitalAuth.isAuthenticated = false
            isValid = error.response.data.success
          })
        this.hospitalName = name
        this.hospitalProfileLink = url
      }

      return isValid
    },

    getDatesInString(data: Date): string {
      const year = data.getFullYear()
      const month = String(data.getMonth() + 1).padStart(2, "0")
      const day = String(data.getDate()).padStart(2, "0")

      return `${year}-${month}-${day}`
    },
    async uploadImage(formData: FormData): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      await apiClient
        .post("common/upload-file?type=profile", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getUniversities(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      await apiClient
        .get("public/university-list")
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async updateProfile(hospitalProfile: any): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      await apiClient
        .post("hospital/update-profile", hospitalProfile, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getApplications(
      type: string,
      page: number,
      itemsPerPage: number
    ): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      await apiClient
        .post(
          "get-application",
          {
            page: page,
            pageSize: itemsPerPage,
            role: "",
            status: type,
            name: "",
            search: "",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getDashboard(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const hospitalToken = localStorage.getItem("hospitalAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${hospitalToken}`,
      }

      await apiClient
        .get("hospital/dashboard", { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getSingleApplication(id: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${token}`,
      }

      await apiClient
        .get(`application/${id}`, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async updateApplicationStatus(requestBody: {
      status: string
      comment: string
      id: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }

      await apiClient
        .put(`admin-update-application-status`, requestBody, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async inviteDoctor(emailData: any): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${token}`,
      }

      await apiClient
        .post(`hospital/invite-doctor`, emailData, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getDoctors(status: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${token}`,
      }

      await apiClient
        .get(`hospital/invite-doctor/list?type=${status}`, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getDoctor(id: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${token}`,
      }

      await apiClient
        .get(`hospital/doctor/${id}`, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async saveDoctorProfile(
      id: string,
      profile: object
    ): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${token}`,
      }

      await apiClient
        .put(`hospital/doctor/${id}`, profile, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getDepartments(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${token}`,
      }

      await apiClient
        .get(`hospital/department/list`, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async updateAssignedDoctor(body: {
      applicationId: string
      doctorId: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${token}`,
      }

      await apiClient
        .post(`hospital/update-assigned-doctor`, body, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async takeBackInvite(body: { inviteId: string }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${token}`,
      }

      await apiClient
        .post(`hospital/delete-invite-doctor`, body, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getAllDoctors(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("hospitalAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${token}`,
      }

      await apiClient
        .get(`hospital/doctors`, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
  },
})
