<template>
  <div :key="key">
    <div v-for="(doctor, index) in doctors" :key="index">
      <v-card
        class="mx-auto my-5 ml-3 mr-3"
        max-width="55%"
        height="130"
        v-bind="props"
        @click="$router.push(`/doctors/${doctor.doctorId}`)"
      >
        <v-row align="center">
          <v-col cols="7" align="start">
            <div class="ml-2">
              <p style="font-size: 92%">{{ $t("name") }}:</p>
              <p style="font-weight: 500">{{ doctorName(doctor) }}</p>
              <p style="font-size: 92%" class="mt-2">
                {{ $t("hospital.department") }}:
              </p>
              <p style="font-weight: 500">
                {{ doctorDepartment(doctor.department) ?? "-" }}
              </p>
            </div>
          </v-col>
          <v-col cols="5">
            <v-img
              class="mt-2"
              :src="doctor.photo"
              width="100"
              style="border-radius: 50%; cursor: pointer"
            ></v-img>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, defineProps, onMounted, onUnmounted, ref } from "vue"
import { Hospital, HospitalDoctor } from "@/types"
import {
  departmentValueToDeMap,
  departmentValueToEnMap,
} from "@/types/hospital-spec"

const { t } = useI18n()

const props = defineProps<{ hospital: Hospital | undefined }>()
const hospital = ref<Hospital | undefined>(props.hospital)
const key = ref(0)
const doctors = computed((): HospitalDoctor[] => {
  var doctors: HospitalDoctor[] = []

  hospital.value?.doctors.forEach((d) => {
    doctors.push(d)
  })
  return doctors
})

const loadPage = () => {
  key.value++
}

onMounted(() => {
  window.addEventListener("locale-changed", loadPage)
})
onUnmounted(() => {
  window.removeEventListener("locale-changed", loadPage)
})

const doctorDepartment = (value: string): string => {
  return localStorage.getItem("locale") == "de"
    ? departmentValueToDeMap[value as string]
    : departmentValueToEnMap[value as string]
}

const doctorName = (d: HospitalDoctor) => {
  return d.title + " " + d.firstName + " " + d.lastName
}
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
</style>
