<template>
  <HeaderComponent></HeaderComponent>
  <div align="center" style="font-family: Verdana, Geneva, Tahoma, sans-serif">
    <div align="start" style="width: 80%">
      <h1 class="mt-10 mb-10" style="font-size: xxx-large">Datenschutz</h1>
      <h3>1. Einleitung</h3>
      <p>
        Im Folgenden informieren wir &uuml;ber die Verarbeitung
        personenbezogener Daten bei der Nutzung
      </p>
      <ul>
        <li>unserer Website www.medcircle.eu</li>
        <li>unserer Profile in Sozialen Medien.</li>
      </ul>
      <p>
        Personenbezogene Daten sind alle Daten, die auf eine konkrete
        nat&uuml;rliche Person beziehbar sind, z. B. ihr Name oder ihre
        IP-Adresse.
      </p>
      <h3>1.1. Kontaktdaten</h3>
      <p>
        Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung
        (DSGVO) ist Medcircle, Georgenstraße 35, 80799 München, Deutschland,
        E-Mail: admin@medcircle.eu. Gesetzlich vertreten werden wir durch Paras
        Dwivedi.
      </p>

      <h3>
        1.2. Umfang der Datenverarbeitung, Verarbeitungszwecke und
        Rechtsgrundlagen
      </h3>
      <p>
        Den Umfang der Verarbeitung der Daten, Verarbeitungszwecke und
        Rechtsgrundlagen f&uuml;hren wir im Detail weiter unten aus. Als
        Rechtsgrundlage f&uuml;r eine Datenverarbeitung kommen
        grunds&auml;tzlich die folgenden in Betracht:
      </p>
      <ul>
        <li>
          Art. 6 Abs. 1 S. 1 lit. a DSGVO dient uns als Rechtsgrundlage f&uuml;r
          Verarbeitungsvorg&auml;nge, f&uuml;r die wir eine Einwilligung
          einholen.
        </li>
        <li>
          Art. 6 Abs. 1 S. 1 lit. b DSGVO ist Rechtsgrundlage, soweit die
          Verarbeitung personenbezogener Daten zur Erf&uuml;llung eines
          Vertrages erforderlich ist, z.B. wenn ein Seitenbesucher von uns ein
          Produkt erwirbt oder wir f&uuml;r ihn eine Leistung ausf&uuml;hren.
          Diese Rechtsgrundlage gilt auch f&uuml;r Verarbeitungen, die f&uuml;r
          vorvertragliche Ma&szlig;nahmen erforderlich sind, etwa bei Anfragen
          zu unseren Produkten oder Leistungen.
        </li>
        <li>
          Art. 6 Abs. 1 S. 1 lit. c DSGVO findet Anwendung, wenn wir mit der
          Verarbeitung personenbezogener Daten eine rechtliche Verpflichtung
          erf&uuml;llen, wie es z.B. im Steuerrecht der Fall sein kann.
        </li>
        <li>
          Art. 6 Abs. 1 S. 1 lit. f DSGVO dient als Rechtsgrundlage, wenn wir
          uns zur Verarbeitung personenbezogener Daten auf berechtigte
          Interessen berufen k&ouml;nnen, z.B. f&uuml;r Cookies, die f&uuml;r
          den technischen Betrieb unserer Website erforderlich sind.
        </li>
      </ul>
      <h3>1.3. Datenverarbeitung au&szlig;erhalb des EWR</h3>
      <p>
        Soweit wir Daten an Dienstleister oder sonstige Dritte au&szlig;erhalb
        des EWR &uuml;bermitteln, garantieren die Sicherheit der Daten bei der
        Weitergabe, soweit (z.B. f&uuml;r Gro&szlig;britannien, Kanada und
        Israel) vorhanden, Angemessenheitsbeschl&uuml;sse der EU-Kommission
        (Art. 45 Ab. 3 DSGVO).
      </p>
      <p>
        Wenn kein Angemessenheitsbeschluss existiert (z.B. f&uuml;r die USA),
        sind Rechtsgrundlage f&uuml;r die Datenweitergabe im Regelfall, also
        soweit wir keinen abweichenden Hinweis geben, Standardvertragsklauseln.
        Diese sind ein von der EU-Kommission verabschiedetes Regelwerk und Teil
        des Vertrags mit dem jeweiligen Dritten. Nach Art. 46 Abs. 2 lit. b
        DSGVO gew&auml;hrleisten sie die Sicherheit der Datenweitergabe. Viele
        der Anbieter haben &uuml;ber die Standardvertragsklauseln hinausgehende
        vertragliche Garantien abgegeben, die die Daten &uuml;ber die
        Standardvertragsklauseln hinaus sch&uuml;tzen. Das sind z.B. Garantien
        hinsichtlich der Verschl&uuml;sselung der Daten oder hinsichtlich einer
        Pflicht des Dritten zum Hinweis an Betroffene, wenn
        Strafverfolgungsorgane auf Daten zugreifen wollen.
      </p>
      <h3>1.4. Speicherdauer</h3>
      <p>
        Sofern nicht im Rahmen dieser Datenschutzerkl&auml;rung
        ausdr&uuml;cklich angegeben, werden die bei uns gespeicherten Daten
        gel&ouml;scht, sobald sie f&uuml;r ihre Zweckbestimmung nicht mehr
        erforderlich sind und der L&ouml;schung keine gesetzlichen
        Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht
        gel&ouml;scht werden, weil sie f&uuml;r andere und gesetzlich
        zul&auml;ssige Zwecke erforderlich sind, wird ihre Verarbeitung
        eingeschr&auml;nkt, d.h. die Daten werden gesperrt und nicht f&uuml;r
        andere Zwecke verarbeitet. Das gilt z.B. f&uuml;r Daten, die wir aus
        handels- oder steuerrechtlichen Gr&uuml;nden aufbewahren m&uuml;ssen.
      </p>
      <h3>1.5. Rechte der Betroffenen</h3>
      <p>
        Betroffene haben gegen&uuml;ber uns folgende Rechte hinsichtlich der sie
        betreffenden personenbezogenen Daten:
      </p>
      <ul>
        <li>Recht auf Auskunft,</li>
        <li>Recht auf Berichtigung oder L&ouml;schung,</li>
        <li>Recht auf Einschr&auml;nkung der Verarbeitung,</li>
        <li><strong>Recht auf Widerspruch gegen die Verarbeitung</strong>,</li>

        <li>
          <strong
            >Recht, eine erteilte Einwilligung jederzeit zu widerrufen</strong
          >.
        </li>
      </ul>
      <p>
        Betroffene haben zudem das Recht, sich bei einer
        Datenschutz-Aufsichtsbeh&ouml;rde &uuml;ber die Verarbeitung ihrer
        personenbezogenen Daten zu beschweren. Kontaktdaten der
        Datenschutz-Aufsichtsbeh&ouml;rden sind unter
        https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html
        abrufbar.
      </p>
      <h3>1.6. Pflicht zur Bereitstellung von Daten</h3>
      <p>
        Kunden, Interessenten oder Dritte m&uuml;ssen uns im Rahmen einer
        Gesch&auml;ftsbeziehung oder sonstigen Beziehung nur diejenigen
        personenbezogenen Daten bereitstellen, die f&uuml;r die Begr&uuml;ndung,
        Durchf&uuml;hrung und Beendigung der Gesch&auml;ftsbeziehung oder
        f&uuml;r die sonstige Beziehung erforderlich sind oder zu deren Erhebung
        wir gesetzlich verpflichtet sind.
      </p>
      <p>Pflichtangaben sind als solche gekennzeichnet.</p>
      <h3>1.7. Keine automatische Entscheidungsfindung im Einzelfall</h3>
      <p>
        Zur Begr&uuml;ndung und Durchf&uuml;hrung einer Gesch&auml;ftsbeziehung
        oder sonstigen Beziehung nutzen wir grunds&auml;tzlich keine
        vollautomatisierte Entscheidungsfindung gem&auml;&szlig; Artikel 22
        DSGVO. Sollten wir diese Verfahren in Einzelf&auml;llen einsetzen,
        werden wir hier&uuml;ber gesondert informieren, sofern dies gesetzlich
        geboten ist.
      </p>
      <h3>1.8. Kontaktaufnahme</h3>
      <p>
        Bei der Kontaktaufnahme mit uns, z.B. per E-Mail oder Telefon, werden
        die uns mitgeteilten Daten (z.B. Namen und E-Mail-Adressen) von uns
        gespeichert, um Fragen zu beantworten. Rechtsgrundlage f&uuml;r die
        Verarbeitung ist unser berechtigtes Interesse (Art. 6 Abs. 1 S. 1 lit. f
        DSGVO), an uns gerichtete Anfragen zu beantworten. Die in diesem
        Zusammenhang anfallenden Daten l&ouml;schen wir, nachdem die Speicherung
        nicht mehr erforderlich ist, oder schr&auml;nken die Verarbeitung ein,
        falls gesetzliche Aufbewahrungspflichten bestehen.
      </p>
      <h3>1.9. Kundenumfragen</h3>
      <p>
        Von Zeit zu Zeit f&uuml;hren wir Kundenumfragen durch, um unsere Kunden
        und ihre W&uuml;nsche besser kennenzulernen. Dabei erheben wir die
        jeweils abgefragten Daten. Es ist unser berechtigtes Interesse, unsere
        Kunden und ihre W&uuml;nsche besser kennenzulernen, so dass
        Rechtsgrundlage der damit einhergehenden Datenverarbeitung Art. 6 Abs. 1
        S. 1 lit f DSGVO ist. Die Daten l&ouml;schen wir, wenn die Ergebnisse
        der Umfragen ausgewertet sind.
      </p>
      <h3>2. Newsletter</h3>
      <p>
        Wir behalten uns vor, Kunden, die bereits Dienstleistungen von uns in
        Anspruch genommen haben, von Zeit zu Zeit per E-Mail oder auf anderem
        Weg elektronisch &uuml;ber unsere Angebote zu informieren, falls sie dem
        nicht widersprochen haben. Rechtsgrundlage f&uuml;r diese
        Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser
        berechtigtes Interesse liegt in der Direktwerbung (Erw&auml;gungsgrund
        47 DSGVO). Kunden k&ouml;nnen der Verwendung ihrer E-Mail-Adresse zu
        Werbezwecken jederzeit ohne zus&auml;tzliche Kosten widersprechen, zum
        Beispiel &uuml;ber den Link am Ende einer jeden E-Mail oder per E-Mail
        an unsere oben genannte E-Mail-Adresse.
      </p>
      <p>
        Interessenten haben die M&ouml;glichkeit, einen kostenlosen Newsletter
        zu abonnieren. Wir verarbeiten die bei der Anmeldung angegebenen Daten
        ausschlie&szlig;lich f&uuml;r den Versand des Newsletters. Die Anmeldung
        erfolgt &uuml;ber die Auswahl des entsprechenden Feldes auf unserer
        Website, durch Ankreuzen des entsprechenden Feldes in einem
        Papierdokument oder durch eine andere eindeutige Handlung, wodurch
        Interessenten ihr Einverst&auml;ndnis in die Verarbeitung ihrer Daten
        erkl&auml;ren, so dass Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. a DSGVO
        ist. Die Einwilligung kann jederzeit widerrufen werden, z.B. durch
        Klicken des entsprechenden Links im Newsletter oder Hinweis an unsere
        oben angegebene E-Mail-Adresse. Die Verarbeitung der Daten bis zum
        Widerruf bleibt auch im Falle eines Widerrufs rechtm&auml;&szlig;ig.
      </p>
      <p>
        Auf Grundlage der Einwilligung der Empf&auml;nger (Art. 6 Abs. 1 S. 1
        lit. a DSGVO)&nbsp;messen wir auch die &Ouml;ffnungs- und Klickrate
        unserer Newsletter, um zu verstehen, welche Inhalte relevant f&uuml;r
        unsere Empf&auml;nger sind.
      </p>

      <h3>3. Datenverarbeitung auf unserer Website</h3>
      <h3>3.1. Informatorische Nutzung der Website</h3>
      <p>
        Bei der informatorischen Nutzung der Website, also wenn Seitenbesucher
        uns nicht gesondert Informationen &uuml;bermitteln, erheben wir die
        personenbezogenen Daten, die der Browser an unseren Server
        &uuml;bermittelt, um die Stabilit&auml;t und Sicherheit unserer Website
        zu gew&auml;hrleisten. Darin liegt unser berechtigtes Interesse, so dass
        Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO ist.
      </p>
      <p>Diese Daten sind:</p>
      <ul>
        <li>IP-Adresse</li>
        <li>Datum und Uhrzeit der Anfrage</li>
        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
        <li>Inhalt der Anfrage (konkrete Seite)</li>
        <li>Zugriffsstatus/HTTP-Statuscode</li>
        <li>jeweils &uuml;bertragene Datenmenge</li>
        <li>Website, von der die Anfrage kommt</li>
        <li>Browser</li>
        <li>Betriebssystem und dessen Oberfl&auml;che</li>
        <li>Sprache und Version der Browsersoftware.</li>
      </ul>
      <p>
        Diese Daten werden au&szlig;erdem in Logfiles gespeichert. Sie werden
        gel&ouml;scht, wenn ihre Speicherung nicht mehr erforderlich ist,
        sp&auml;testens nach 14 Tagen.
      </p>
      <h3>3.2. Webhosting und Bereitstellung der Website</h3>
      <p>
        Unsere Website hostet die OVH GmbH, Christophstraße 19, Köln,
        Deutschland (Datenschutzerkl&auml;rung:
        https://www.ovhcloud.com/de/personal-data-protection/).
      </p>

      <h3>3.3. Datenverarbeitung zur Bewerbung</h3>
      <p>
        Wir stellen die Studenten eine Plattform zur Verf&uuml;gung, mit der sie
        für eine Famulaturplatz bewerben können.
      </p>
      <p>
        Im Rahmen der Registrierung und Bewerbung erfassen wir die folgenden
        Daten eines Studentes:
      </p>
      <ul>
        <li>Name</li>
        <li>Adresse</li>
        <li>E-Mail-Adresse</li>
        <li>Telefonnummer</li>
        <li>Aktuelle Addresse</li>
        <li>Universität</li>
        <li>Immatrikulationsbescheinigung</li>
        <li>Lebenslauf</li>
        <li>Motivationsschreiben</li>
      </ul>

      <p>
        Schlie&szlig;lich geben wir Daten des Studenten auf seinen Wunsch nach
        der Bewerbung hin an Ärzten weiter. Rechtsgrundlage der Verarbeitung ist
        dann Art. 6 Abs. 1 S. 1 lit. b DSGVO, da die Weitergabe zur Vorbereitung
        des Vertrags erforderlich ist.
      </p>

      <h3>3.4. Datenverarbeitung für Ärzten</h3>
      <p>
        Wir stellen die Ärzten eine Plattform zur Verf&uuml;gung, mit der sie
        für eine Famulaturplatz veröffentlichen können und Brodcasting können.
      </p>
      <p>
        Im Rahmen der Veröffentlichung und Brodcasting erfassen wir die
        folgenden Daten eines Ärztes:
      </p>
      <ul>
        <li>Name</li>
        <li>Adresse</li>
        <li>E-Mail-Adresse</li>
        <li>Profilphoto</li>
        <li>Telefonnummer</li>
        <li>Aktuelle Praxisaddresse</li>
        <li>Spezialierung</li>
      </ul>

      <p>
        Schlie&szlig;lich Veröffentlichen wir Daten des Ärztes nach der
        Ausfüllung. Rechtsgrundlage der Verarbeitung ist dann Art. 6 Abs. 2 S. 1
        lit. e DSGVO, da die Weitergabe zur Vorbereitung des Vertrags
        erforderlich ist.
      </p>

      <p>
        Wir halten uns recht vor die fehlende Inforamtionen selber auszufüllen
        sodass unsere Studenten komplette ausblick auf den Ärzten haben können
        vordem Sie Bewerbung abschicken. Näturlich werden Sie sofort durch eine
        email informiert mit den aktuellen Inforamtionen
      </p>

      <h3>4. Datenverarbeitung auf Social Media-Plattformen</h3>
      <p>
        Wir sind in Social Media-Netzwerken vertreten, um dort unsere
        Organisation und unsere Leistungen vorzustellen. Die Betreiber dieser
        Netzwerke verarbeiten Daten ihrer Nutzer regelm&auml;&szlig;ig zu
        Werbezwecken. Unter anderem erstellen sie aus ihrem Onlineverhalten
        Nutzerprofile, die beispielsweise dazu verwendet werden, um auf den
        Seiten der Netzwerke und auch sonst im Internet Werbung zu zeigen, die
        den Interessen der Nutzer entspricht. Dazu speichern die Betreiber der
        Netzwerke Informationen zu dem Nutzungsverhalten in Cookies auf dem
        Rechner der Nutzer. Es ist au&szlig;erdem nicht auszuschlie&szlig;en,
        dass die Betreiber diese Informationen mit weiteren Daten
        zusammenf&uuml;hren. Weitere Informationen sowie Hinweise, wie Nutzer
        der Verarbeitung durch die Seitenbetreiber widersprechen k&ouml;nnen,
        erhalten Nutzer in den unten aufgef&uuml;hrten
        Datenschutzerkl&auml;rungen der jeweiligen Betreiber. Es kann auch sein,
        dass die Betreiber oder ihre Server in Nicht-EU-Staaten sitzen, so dass
        sie Daten dort verarbeiten. Hierdurch k&ouml;nnen sich f&uuml;r die
        Nutzer Risiken ergeben, z.B. weil die Durchsetzung ihrer Rechte
        erschwert wird oder staatliche Stellen Zugriff auf die Daten nehmen.
      </p>
      <p>
        Wenn Nutzer der Netzwerke mit uns &uuml;ber unsere Profile in Kontakt
        treten, verarbeiten wir die uns mitgeteilten Daten, um die Anfragen zu
        beantworten. Darin liegt unser berechtigtes Interesse, so dass
        Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. f DSGVO ist.
      </p>

      <h3>5. Cookies</h3>
      <p>
        Unsere Webseiten arbeiten teilweise mit sog. „Cookies“. Dies sind kleine
        Informationseinheiten, die vom Web-Server in Ihrem Web-Browser für die
        Dauer Ihrer Sitzung (Session-Cookie) oder dauerhaft als Textdatei
        gespeichert werden. Nur wenn Cookies aktiviert sind, können Sie alle
        Funktionen unserer Webseiten nutzen.
      </p>
      <p>
        Cookies lassen sich mit allen Internet-Browsern steuern. Sie können
        beispielsweise beim Internet Explorer Cookies sperren, zulassen oder nur
        teilweise zulassen (jeweils durch Anklicken von „Extras“ im Menü, den
        Reiter „Internetoptionen“ und den Punkt „Datenschutz“). Wenn Sie die
        Warenkorb-Funktion des Onlineshops nutzen möchten, sollten Sie Ihren
        Browser so einstellen, dass jedenfalls Session-Cookies akzeptiert
        werden.
      </p>
      <h3>6. &Auml;nderungen dieser Datenschutzerkl&auml;rung</h3>
      <p>
        Wir behalten uns das Recht vor, diese Datenschutzerkl&auml;rung mit
        Wirkung f&uuml;r die Zukunft zu &auml;ndern. Eine aktuelle Version ist
        jeweils hier verf&uuml;gbar.
      </p>
      <h3>7. Fragen und Kommentare</h3>
      <p>
        F&uuml;r Fragen oder Kommentare bez&uuml;glich dieser
        Datenschutzerkl&auml;rung stehen wir gern unter den oben angegebenen
        Kontaktdaten zur Verf&uuml;gung.
      </p>
    </div>
  </div>
  <FooterComponent></FooterComponent>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import HeaderComponent from '../../../Header/HeaderComponent.vue'
import FooterComponent from '@/components/Footer/FooterComponent.vue'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    HeaderComponent,
    FooterComponent,
  },
  name: 'PrivacyPolicy',

  setup() {
    const { t } = useI18n()
    useMeta({
      title: t('meta.privacy-policy'),
    })
  },
})
</script>
