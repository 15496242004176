<template>
  <request-status :response="requestResponse" :isLoading="false" />
  <div :key="rerenderKey">
    <h2 align="center" class="mt-4">Broadcasting</h2>

    <v-divider class="mt-3"></v-divider>
    <div align="center">
      <v-btn class="mt-10" color="primary" @click="broadcast = true">
        <v-icon class="mr-3">mdi-radio-tower</v-icon> Broadcast</v-btn
      >
    </div>
    <div class="mt-10"></div>
    <MessageComponent></MessageComponent>
    <MessageBox
      :content="content"
      :title="title"
      :broadcast="broadcast"
      :editView="false"
      v-model="broadcast"
      :studentView="false"
      @closed="closeDialog($event)"
      @closeDialog="broadcast = false"
    ></MessageBox>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { defineProps, ref, onMounted, computed, Ref } from 'vue'
import { Delta } from '@vueup/vue-quill'
import { broadcastingActions } from '@/stores/broadcasting-store'
import { RequestResponse } from '@/types'
import { useToast } from 'vue-toast-notification'

import MessageComponent from './MessageComponent/MessageComponent.vue'
import MessageBox from '@/components/Static/MessageBox/MessageBox.vue'
const { t } = useI18n()
const content = ref<Delta | undefined>()
const title = ref<string>('')
const broadcast = ref(false)
const requestResponse = ref<RequestResponse>()
const rerenderKey = ref(0)

const closeDialog = async (response: RequestResponse) => {
  requestResponse.value = response
  broadcast.value = false
  rerenderKey.value++
}
</script>

<style scoped></style>
