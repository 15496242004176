<template>
  <request-status :response="requestResponse" :isLoading="isLoading" />

  <div style="min-height: 60vh">
    <h3 align="center" class="mt-5">
      {{ $t("doctor.choose-time") }}
    </h3>
    <v-divider class="mt-4"></v-divider>

    <CalendarProfileComponent
      v-if="doctor && !isLoading"
      :manualTime="manualTime"
      :useCalendarSearch="activeSearch"
      @saved="saveProfileData($event)"
    ></CalendarProfileComponent>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import CalendarProfileComponent from "@/components/Static/CalendarComponent/CalendarProfileComponent.vue"
import { ApplicantTime, DoctorProfile } from "@/types"
import { doctorActions } from "@/stores/doctor-actions"
import { computed } from "vue"
import { useToast } from "vue-toast-notification"

export default defineComponent({
  name: "TimeTableComponent",
  components: { CalendarProfileComponent },

  setup(props, { emit }) {
    const { t } = useI18n()
    const requestResponse = ref({})

    const isLoading = ref(false)
    const docAction = doctorActions()
    const doctor = ref<DoctorProfile>()
    const isSaving = ref(false)
    const activeSearch = ref(false)
    const manualTime = ref<any[][]>([])
    const modelDateArray = ref<object[][]>([])
    const lectureFreeSearch = ref(false)
    const manualSearch = ref(false)
    const applicants = ref<ApplicantTime[]>([])
    const lectureFreeTimes = ref([
      [[new Date("2024-07-20"), new Date("2024-10-13")], 1],
      [[new Date("2025-02-08"), new Date("2025-04-21")], 1],
      [[new Date("2025-07-26"), new Date("2025-10-12")], 1],
    ])
    const numberValue = ref(0)

    onMounted(async () => {
      await getProfile()

      buildTimeArray(applicants.value)
    })

    const getProfile = async () => {
      isLoading.value = true
      const response = await docAction.getProfile()
      requestResponse.value = response
      if (response.success) {
        doctor.value = response.result as DoctorProfile
        applicants.value = doctor.value?.applicants ?? []
        activeSearch.value = doctor.value?.useCalendarSearch ?? false
      }
      isLoading.value = false
    }

    const disableButton = computed((): boolean => {
      return (
        manualTime.value[manualTime.value.length - 1]?.[0].length == 0 ||
        manualTime.value[manualTime.value.length - 1]?.[0]?.[0] == null ||
        manualTime.value[manualTime.value.length - 1]?.[0]?.[1] == null ||
        manualTime.value[manualTime.value.length - 1]?.[1] < 1
      )
    })

    const minRule = [
      (v: number) => !!v || t("required"),
      (v: number) => v > 0 || t("min-student-required"),
    ]

    const buildTimeArray = (array: ApplicantTime[]) => {
      array.forEach((item: ApplicantTime) => {
        const startDate = new Date(item.start)
        const endDate = new Date(item.end)
        manualTime.value.push([[startDate, endDate], item.applicants])
      })

      modelDateArray.value = manualTime.value
    }

    return {
      doctor,
      requestResponse,
      isLoading,
      modelDateArray,
      t,
      disableButton,
      lectureFreeTimes,
      manualTime,
      minRule,
      isSaving,
      activeSearch,
      numberValue,
      docAction,
      getProfile,
    }
  },
  methods: {
    // lectureFree() {
    //   this.lectureFreeSearch = !this.lectureFreeSearch
    //   if (this.lectureFreeSearch) {
    //     this.manualTime = this.lectureFreeTimes
    //   } else {
    //     this.manualTime = this.modelDateArray
    //   }
    // },
    async saveProfileData(data: any): Promise<void> {
      this.isLoading = true
      var applicants: ApplicantTime[] = []
      const $toast = useToast()
      if (data.useCalendarSearch == this.activeSearch) {
        if (data.addingNewTimeWindow) {
          this.manualTime.push(data.timeWindow)
        } else if (data.deletingTimeWindow) {
          this.manualTime = this.manualTime.filter((item, index) => {
            return index !== data.index
          })
        } else {
          this.manualTime[data.index] = data.timeWindow
        }
      }

      this.manualTime.forEach((val) => {
        const data: ApplicantTime = {
          start: this.docAction.getDatesInString(val[0][0] as Date),
          end: this.docAction.getDatesInString(val[0][1] as Date),
          applicants: parseInt(val[1].toString()),
        }
        applicants.push(data)
      })

      const profile = {
        applicants: applicants,
        useCalendarSearch: data.useCalendarSearch,
      }

      const response = await this.docAction.updateProfile(profile)
      this.requestResponse = response
      this.isLoading = false
      if (response.success) {
        $toast.success(this.t("profile-saved-successfully"), {
          position: "bottom-left",
        })
        await this.getProfile()
      } else {
        $toast.error(response.message ?? "", {
          position: "bottom-left",
        })
      }
    },
  },
})
</script>

<style scoped></style>
