<template>
  <div>
    <h2 align="center" class="mt-4">{{ $t("hospital.your-doctors") }}</h2>
  </div>
  <v-divider class="mt-3"></v-divider>
  <invite-doctor
    v-model="inviteDoctorDialog"
    @close="inviteDoctorDialog = false"
    @invited="loadInvites"
  />
  <request-status :response="requestResponse" v-if="!loading" />

  <div align="end" class="mb-5">
    <v-btn color="#3fb984" class="mr-10 mt-5" @click="inviteDoctorDialog = true"
      >{{ $t("hospital.add-doctor")
      }}<template v-slot:append>
        <v-icon icon="mdi-plus" size="x-large"></v-icon> </template
    ></v-btn>
  </div>

  <v-tabs v-model="tab" show-arrows bg-color="#25344d" grow>
    <v-tab value="doctors"> {{ $t("hospital.doctors") }}</v-tab>
    <v-tab value="invites">
      {{ $t("hospital.invitations") }}
    </v-tab>
  </v-tabs>
  <v-window v-model="tab">
    <v-window-item value="doctors">
      <v-data-table
        style="margin-top: 9px; cursor: pointer"
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :loading="loading"
        :items="doctorArray"
        @click:row="handleRowClick"
      >
        <template v-slot:[`item.photo`]="props">
          <div v-if="props.item.photo" align="center">
            <v-img
              :src="props.item.photo"
              alt="student Image"
              v-bind="props"
              class="image mb-2"
              referrerpolicy="no-referrer"
              rel="noreferrer"
            />
          </div>
          <div v-else align="center">
            <v-img
              v-bind="props"
              :src="require('../../../../assets/doctor1.png')"
              alt="student Image"
              class="image mb-2"
              referrerpolicy="no-referrer"
              rel="noreferrer"
            />
          </div>
        </template>
      </v-data-table>
    </v-window-item>
    <v-window-item value="invites">
      <v-data-table
        style="margin-top: 9px"
        v-model:items-per-page="itemsPerPage"
        :headers="invitationHeader"
        :loading="loading"
        :items="doctorInvitesArray"
      >
        <template v-slot:[`item.photo`]="props">
          <div v-if="props.item.photo" align="center">
            <v-img
              :src="props.item.photo"
              alt="student Image"
              v-bind="props"
              class="image mb-2"
              referrerpolicy="no-referrer"
              rel="noreferrer"
            />
          </div>
          <div v-else align="center">
            <v-img
              v-bind="props"
              :src="require('../../../../assets/doctor1.png')"
              alt="student Image"
              class="image mb-2"
              referrerpolicy="no-referrer"
              rel="noreferrer"
            />
          </div>
        </template>
        <template v-slot:[`item.action`]="props">
          <v-btn
            size="large"
            class="mt-2"
            density="compact"
            color="red"
            icon="mdi-trash-can-outline"
            @click="takeBack(props.item._id)"
          ></v-btn>
        </template>
      </v-data-table>
    </v-window-item>
  </v-window>
</template>

<script setup lang="ts">
interface DoctorInvites {
  doctorId: string
  firstName: string
  lastName: string
  inviteEmail: string
  photo: string
  department: string
}
interface invitation {
  _id: string
  inviteEmail: string
  photo: string
  department: string
}
interface HospitalDoctor {
  _id: string
  firstName: string
  lastName: string
  email: string
  photo: string
  department: string
}

import { useI18n } from "vue-i18n"
import { ref, onMounted, computed, Ref, onUnmounted } from "vue"
import { RequestResponse } from "@/types"
import InviteDoctor from "./InviteDoctor.vue"
import { hospitalActions } from "@/stores/hospital-actions"

const hospitalDoctors = ref<HospitalDoctor[]>([])
const invitedDoctors = ref<invitation[]>([])
const loading = ref(false)
const { t } = useI18n()
const hospitalAction = hospitalActions()
import router from "@/router"
import {
  departmentValueToDeMap,
  departmentValueToEnMap,
} from "@/types/hospital-spec"
const page = ref(1)
const itemsPerPage = ref(5)
const requestResponse = ref<RequestResponse>()
const inviteDoctorDialog = ref(false)
const tab = ref<string>("doctors")
const headers = computed(() => {
  return [
    {
      key: "photo",
      sortable: false,
      align: "center",
    },
    { title: t("table.name"), key: "name", sortable: false, align: "center" },

    {
      title: t("table.department"),
      key: "department",
      sortable: false,
      align: "center",
    },
    { title: t("email"), key: "email", sortable: false, align: "center" },
    {
      title: t("table.action"),
      key: "action",
      sortable: false,
      align: "center",
    },
  ]
})
const invitationHeader = computed(() => {
  return [
    {
      key: "photo",
      sortable: false,
      align: "center",
    },
    {
      title: t("table.department"),
      key: "department",
      sortable: false,
      align: "center",
    },
    { title: t("email"), key: "inviteEmail", sortable: false, align: "center" },
    {
      title: t("table.action"),
      key: "action",
      sortable: false,
      align: "center",
    },
  ]
})

onMounted(() => {
  loadDoctors()
  loadInvites()
  window.addEventListener("locale-changed", loadDoctors)
})
onUnmounted(() => {
  window.removeEventListener("locale-changed", loadDoctors)
})

const takeBack = async (id: string) => {
  loading.value = true

  const response = await hospitalAction.takeBackInvite({ inviteId: id })

  requestResponse.value = response

  if (response.success) {
    loadInvites()
  }
  loading.value = false
}

const loadDoctors = async () => {
  inviteDoctorDialog.value = false
  loading.value = true

  const response = await hospitalAction.getAllDoctors()

  requestResponse.value = response

  if (response.success) {
    hospitalDoctors.value = response.result as HospitalDoctor[]
  }

  loading.value = false
}

const handleRowClick = (click: any, doctor: any) => {
  router.push({
    path: `doctor/${doctor.item.doctorId}`,
  })
}

const doctorDepartment = (value: string): string => {
  return localStorage.getItem("locale") == "de"
    ? departmentValueToDeMap[value as string]
    : departmentValueToEnMap[value as string]
}

const loadInvites = async () => {
  inviteDoctorDialog.value = false
  loading.value = true

  const response = await hospitalAction.getDoctors("notAccepted")

  requestResponse.value = response

  if (response.success) {
    invitedDoctors.value = response.result as invitation[]
  }
  loading.value = false
}

const doctorArray = computed((): DoctorInvites[] => {
  const array: any[] = []

  hospitalDoctors.value.forEach((doctor) => {
    const firstName = doctor?.firstName ?? ""
    const lastName = doctor?.lastName ?? ""
    const singleDoctor = {
      doctorId: doctor._id,
      photo: doctor?.photo,
      name: firstName + " " + lastName,
      department: doctorDepartment(doctor.department),
      email: doctor.email,
    }
    array.push(singleDoctor)
  })

  return array
})

const doctorInvitesArray = computed((): any[] => {
  const array: invitation[] = []

  invitedDoctors.value.forEach((invite) => {
    const singleDoctor = {
      _id: invite._id,
      photo: invite?.photo,
      department: invite.department,
      inviteEmail: invite.inviteEmail,
    }
    array.push(singleDoctor)
  })

  return array
})
</script>

<style scoped>
.custom-title {
  font-size: x-large;
}

.custom-subtitle {
  font-size: large;
}
.image {
  width: 50px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 10px;
  height: 50px;
  max-width: 50px;
  min-height: 50px;
}
</style>
