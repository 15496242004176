<template>
  <request-status :response="requestResponse" :isLoading="loading" />

  <div>
    <v-col v-if="props.doctor && !loading">
      <photo-upload
        :photo="photo"
        :isHospital="true"
        @photo-uploaded="photo = $event"
      />
      <div class="flex flex-wrap mt-10">
        <div class="flex-item" justify="start">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("doctor.title") }}
          </div>

          <v-select
            class="ml-2"
            v-model="title"
            density="compact"
            :placeholder="$t('doctor.title')"
            prepend-inner-icon="mdi-pencil"
            :items="doctorTitle"
            variant="outlined"
            style="min-width: 300px"
          ></v-select>
        </div>

        <div class="flex-item" justify="start">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("first-name") }}
            <span class="red-text"><strong>* </strong></span>
          </div>
          <v-text-field
            class="ml-2"
            v-model="firstName"
            density="compact"
            :placeholder="$t('first-name')"
            prepend-inner-icon="mdi-pencil"
            variant="outlined"
            :rules="notEmptyRule"
            style="min-width: 300px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("last-name") }}
            <span class="red-text"><strong>* </strong></span>
          </div>
          <v-text-field
            class="ml-2"
            v-model="lastName"
            density="compact"
            :placeholder="$t('last-name')"
            prepend-inner-icon="mdi-pencil"
            variant="outlined"
            :rules="notEmptyRule"
            style="min-width: 300px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("email") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="email"
            density="compact"
            readonly
            :placeholder="$t('email')"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
            style="min-width: 300px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("phoneNumber") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="phoneNumber"
            density="compact"
            :placeholder="$t('phoneNumber')"
            prepend-inner-icon="mdi-cellphone"
            variant="outlined"
            style="min-width: 300px"
          ></v-text-field>
        </div>
        <div class="flex-item" justify="start">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("hospital.department") }}
            <span class="red-text"><strong>* </strong></span>
          </div>

          <v-autocomplete
            class="ml-2"
            v-model="chosenDepartment"
            density="compact"
            :placeholder="$t('hospital.department')"
            :items="departments"
            :item-title="getDepartmentName"
            :item-value="getDepartmentValue"
            prepend-inner-icon="mdi-hospital-building"
            :rules="notEmptyRule"
            variant="outlined"
            :menu-props="{ width: '300px' }"
          ></v-autocomplete>
        </div>
      </div>
    </v-col>
  </div>

  <!-- <h3 align="center" class="mt-5">
    {{ $t("doctor.choose-time") }}
  </h3>
  <v-divider class="mt-4"></v-divider>

  <div align="center">
    <v-card max-width="500" class="mt-10 mb-10" style="border-radius: 2%">
      <div style="display: flex; justify-content: center">
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-switch
              v-bind="props"
              color="primary"
              v-model="activeSearch"
              :label="$t('doctor.calender')"
            ></v-switch>
          </template>
          {{ $t("doctor.calender-tip") }}
        </v-tooltip>
      </div>

      <div align="center">
        <div class="mb-10" v-if="activeSearch">
          <div
            class="mt-5 mb-5 flex"
            v-for="(item, index) in manualTime"
            :key="index"
            style="
              width: 80%;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div style="width: 80%">
              <div class="text-subtitle-1 text-medium-emphasis" align="start">
                {{ $t("time-period") }}
              </div>
              <VueDatePicker
                v-model="manualTime[index][0]"
                range
                :enable-time-picker="false"
                :min-date="Date.now()"
                :teleport="true"
                :clearable="false"
                format="dd MMM yyyy"
              />
              <div
                class="text-subtitle-1 mt-2 text-medium-emphasis"
                align="start"
              >
                {{ $t("number-students") }}
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      icon="mdi-help-circle-outline"
                      color="#3fb984"
                    ></v-icon>
                  </template>
                  {{ $t("number-students-tip") }}
                </v-tooltip>
              </div>

              <v-text-field
                density="compact"
                :placeholder="$t('email')"
                v-model="manualTime[index][1]"
                hide-details
                single-line
                type="number"
                :rules="minRule"
              />
            </div>

            <v-btn
              class="mt-2"
              density="compact"
              color="red"
              icon="mdi-trash-can-outline"
              size="large"
              @click="
                manualTime = manualTime
                  .slice(0, index)
                  .concat(manualTime.slice(index + 1))
              "
            ></v-btn>
          </div>
          <v-btn
            density="compact"
            color="primary"
            icon="mdi-plus"
            :rules="minRule"
            @click="manualTime.push([[], 1])"
          ></v-btn>
        </div>
      </div>
    </v-card> -->
  <v-footer
    style="
      display: flex;
      width: 96%;
      justify-content: end;
      background-color: #f2f2f2;
    "
  >
    <div>
      <div>
        <v-btn
          class="mr-10"
          rounded="lg"
          color="#3fb984"
          variant="flat"
          size="large"
          width="150"
          :loading="isSaving"
          :disabled="infoCompleted"
          @click="saveProfile()"
        >
          {{ $t("save") }}
        </v-btn>
      </div>
    </div>
  </v-footer>
</template>

<script setup lang="ts">
import { computed, defineProps, onBeforeUnmount, onMounted, ref } from "vue"

import { useI18n } from "vue-i18n"
import { useRoute } from "vue-router"
import {
  ApplicantTime,
  Department,
  DoctorProfile,
  HospitalDoctor,
} from "@/types"
import { hospitalActions } from "@/stores/hospital-actions"
import PhotoUpload from "@/components/Static/PhotoUpload/PhotoUpload.vue"
import { doctorTitle } from "@/types/spec"
import { doctorActions } from "@/stores/doctor-actions"
import { useToast } from "vue-toast-notification"

const { t } = useI18n()

const hospitalAction = hospitalActions()
const route = useRoute()
const requestResponse = ref()
const doctor = ref<HospitalDoctor>()
const loading = ref(true)
const id = ref()
const title = ref<string | undefined>(undefined)
const firstName = ref<string | undefined>(undefined)
const lastName = ref<string | undefined>(undefined)
const email = ref<string | undefined>(undefined)
const phoneNumber = ref<string | undefined>(undefined)
const photo = ref<string | undefined>(undefined)
const departments = ref<Department[]>([])
const chosenDepartment = ref<string | null>(null)
const locale = ref(localStorage.getItem("locale") || "de")
const smallWindow = ref(window.innerWidth < 1000)
const lectureFreeSearch = ref(false)
const activeSearch = ref(false)
const manualTime = ref<any[][]>([])
const applicants = ref<ApplicantTime[]>([])
const isSaving = ref(false)
const props = defineProps<{ doctor: HospitalDoctor | undefined }>()
onMounted(async () => {
  id.value = route.params.id
  await getDoctorProfile()
  await getDepartments()
  window.addEventListener("resize", updateWindowSize)
  window.addEventListener("locale-changed", updateLocale)
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateWindowSize)
  window.removeEventListener("locale-changed", updateLocale)
})

const getDoctorProfile = async () => {
  loading.value = true

  title.value = props.doctor?.title ?? "-"
  firstName.value = props.doctor?.firstName ?? undefined
  lastName.value = props.doctor?.lastName ?? undefined
  email.value = props.doctor?.email ?? undefined
  photo.value = props.doctor?.photo
  phoneNumber.value = props.doctor?.privatePhone
  chosenDepartment.value = props.doctor?.department ?? null

  loading.value = false
}

const notEmptyRule = [(v: string) => !!v || t("field-required")]

const getDepartmentName = computed(() => (department: Department): string => {
  return locale.value == "de" ? department.de : department.en
})

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}

const updateLocale = () => {
  locale.value = localStorage.getItem("locale") ?? "de"
}
const getDepartmentValue = (department: Department): string => {
  return department.value
}

const getDepartments = async () => {
  const response = await hospitalAction.getDepartments()
  requestResponse.value = response
  if (response.success) {
    departments.value = response.result as Department[]
  }
}

const infoCompleted = computed((): boolean => {
  return (
    firstName.value != "" &&
    lastName.value != "" &&
    chosenDepartment.value != null &&
    activeSearch.value &&
    (manualTime.value[manualTime.value.length - 1]?.[0].length == 0 ||
      manualTime.value[manualTime.value.length - 1]?.[0]?.[0] == null ||
      manualTime.value[manualTime.value.length - 1]?.[0]?.[1] == null ||
      manualTime.value[manualTime.value.length - 1]?.[1] < 1)
  )
})

const saveProfile = async (): Promise<void> => {
  isSaving.value = true
  var applicants: ApplicantTime[] = []

  const docAction = doctorActions()
  const $toast = useToast()

  manualTime.value.forEach((val) => {
    const data: ApplicantTime = {
      start: docAction.getDatesInString(val[0][0] as Date),
      end: docAction.getDatesInString(val[0][1] as Date),
      applicants: parseInt(val[1].toString()),
    }
    applicants.push(data)
  })
  const profile = {
    firstName: firstName.value,
    lastName: lastName.value,
    title: title.value,
    photo: photo.value,
    phoneNumber: phoneNumber.value,
    department: chosenDepartment.value,
  }

  const response = await hospitalAction.saveDoctorProfile(id.value, profile)
  requestResponse.value = response
  isSaving.value = false
  if (response.success) {
    $toast.success(t("profile-saved-successfully"), {
      position: "bottom-left",
    })
  } else {
    $toast.error(response.message ?? "", {
      position: "bottom-left",
    })
  }
}
</script>

<style>
.text {
  font-size: 50px;
  font-weight: 500;
  margin-top: 130px;
}
.sub-text {
  font-size: 20px;
  font-weight: 300;
}

.content {
  overflow-x: hidden;
}
.footer {
  margin-top: 200px;
}
</style>
