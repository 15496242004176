import { RequestResponse } from './../types/index'
import { defineStore } from 'pinia'
import apiClient from './apiclients'

export const hospitalAuthStore = defineStore({
  id: 'hospital',

  state: () => ({
    hospitalAccessToken: localStorage.getItem('hospitalAccessToken') ?? '',
    hospitalId: localStorage.getItem('hospitalId') ?? '',
    isAuthenticated: false,
  }),

  actions: {
    async login(credentials: {
      email: string
      password: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('hospital/login', credentials)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async register(hospital: {
      email: string
      name: string
      password: string
      confirmPassword: string
      agreements: boolean
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('hospital/register', hospital)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async forgotPassword(credentials: {
      email: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('hospital/forgot-password', credentials)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async resetPassword(credentials: {
      userId: string
      token: string
      password: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }

      await apiClient
        .post('hospital/forgot-password-verify', credentials)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
  },
})
