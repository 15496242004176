import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bgimg" }
const _hoisted_2 = { align: "center" }
const _hoisted_3 = {
  class: "mt-15",
  align: "center"
}
const _hoisted_4 = { align: "center" }
const _hoisted_5 = {
  style: {"font-size":"large"},
  class: "mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: _ctx.smallWindow ? 12 : 5,
          style: _normalizeStyle([{"margin-top":"50px"}, 
          _ctx.smallWindow ? { marginBottom: '0px' } : { marginBottom: '50px' }
        ])
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_img, {
                src: require('../../assets/med-students.jpg'),
                width: "500"
              }, null, 8, ["src"])
            ])
          ]),
          _: 1
        }, 8, ["cols", "style"]),
        _createVNode(_component_v_col, {
          cols: _ctx.smallWindow ? 12 : 7,
          style: _normalizeStyle([{"margin-bottom":"50px"}, _ctx.smallWindow ? { marginTop: '0px' } : { marginTop: '50px' }])
        }, {
          default: _withCtx(() => [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("practical-reality")), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("practical-answer")), 1)
            ])
          ]),
          _: 1
        }, 8, ["cols", "style"])
      ]),
      _: 1
    })
  ]))
}