import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import LandingComponent from "../components/Landing/LandingComponent.vue"
import LoginComponent from "@/components/Auth/AuthStudent/Login/LoginComponent.vue"
import RegisterComponent from "@/components/Auth/AuthStudent/Register/RegisterComponent.vue"
import SearchResultsComponent from "../components/SearchResults/SearchResults.vue"
import DoctorPage from "@/components/DoctorProfile/DoctorPage.vue"
import ApplicationComponent from "@/components/Application/ApplicationComponent.vue"
import StudentProfile from "@/components/StudentProfile/StudentProfile.vue"
import LogoutComponent from "@/components/Auth/AuthStudent/Logout/LogoutComponent.vue"
import ForgotPassword from "@/components/Auth/AuthStudent/ForgotPassword/ForgotPassword.vue"
import EmailVerified from "@/components/Auth/AuthStudent/EmailVerified/EmailVerified.vue"
import TermsAndConditions from "@/components/Auth/AuthStudent/TermsAndConditions/TermsAndConditions.vue"
import PrivacyPolicy from "@/components/Auth/AuthStudent/PrivacyPolicy/PrivacyPolicy.vue"
import ContactComponent from "@/components/Static/Contact/ContactComponent.vue"
import ImprintComponent from "@/components/Static/Imprint/ImprintComponent.vue"
import ResetPassword from "@/components/Auth/AuthStudent/ResetPassword/ResetPassword.vue"
import NotFoundPage from "@/components/Static/404Page/NotFoundPage.vue"
import DocLogin from "@/components/Auth/AuthDoctor/DocLogin/DocLogin.vue"
import DocRegister from "@/components/Auth/AuthDoctor/DocRegistration/DocRegister.vue"
import DoctorDashboard from "@/components/Doctors/Dashboard/DoctorDashboard.vue"
import DoctorOverview from "@/components/Doctors/Dashboard/DoctorOverview/DoctorOverview.vue"
import {
  isDoctorGuard,
  isDoctorOrHospitalGuard,
  isHospitalGuard,
  isLoggedInDoctorGuard,
  isStudentGuard,
} from "@/router/guards"
import DocForgotPassword from "@/components/Auth/AuthDoctor/DocForgotPassword/DocForgotPassword.vue"
import DocResetPassword from "@/components/Auth/AuthDoctor/DocResetPassword/DocResetPassword.vue"
import DoctorProfile from "@/components/Doctors/Profile/DoctorProfile.vue"
import DocLogout from "@/components/Auth/AuthDoctor/DocLogout/DocLogout.vue"
import AttributeComponent from "@/components/Static/Attribute/AttributeComponent.vue"
import EmailVerifiedDoctor from "@/components/Auth/AuthDoctor/EmailVerifiedDoc/EmailVerifiedDoc.vue"
import AboutUs from "@/components/Static/AboutUs/AboutUs.vue"
import ContactDoctorComponent from "@/components/Static/Contact/ContactDoctorComponent.vue"
import AboutUsDoc from "@/components/Static/AboutUs/AboutUsDoc.vue"
import StudentTable from "@/components/Doctors/Dashboard/Broadcasting/StudentsTable/StudentTable.vue"
import BroadcastingMessages from "@/components/Doctors/Dashboard/Broadcasting/BroadcastingMessages/BroadcastingMessages.vue"
import DoctorApplication from "@/components/Doctors/Dashboard/Application/DoctorApplication.vue"
import GeneralInformation from "@/components/StudentProfile/GeneralInformation/GeneralInformation.vue"
import ApplicationsProfileComponent from "@/components/StudentProfile/Applications/ApplicationsProfileComponent.vue"
import StudentMessages from "@/components/StudentProfile/Messages/StudentMessages.vue"
import StudentSingleApplication from "@/components/StudentProfile/SingleApplication/StudentSingleApplication.vue"
import DoctorGeneralInformation from "@/components/Doctors/Profile/DoctorGeneralInformation/DoctorGeneralInformation.vue"
import FamulaturComponent from "@/components/Doctors/Profile/FamulaturComponent/FamulaturComponent.vue"
import TimeTable from "@/components/Doctors/Profile/TimeTable/TimeTable.vue"
import HospitalDashboard from "@/components/Hospitals/Dashboard/HospitalDashboard.vue"
import HospitalOverview from "@/components/Hospitals/Dashboard/Overview/HospitalOverview.vue"
import HospitalLogin from "@/components/Auth/AuthHospital/Login/HospitalLogin.vue"
import HospitalRegister from "@/components/Auth/AuthHospital/Register/HospitalRegister.vue"
import HospitalForgotPassword from "@/components/Auth/AuthHospital/ForgotPassword/HospitalForgotPassword.vue"
import EmailVerifiedHospital from "@/components/Auth/AuthHospital/EmailVerifiedHospital/EmailVerifiedHospital.vue"
import HospitalLogout from "@/components/Auth/AuthHospital/HospitalLogout/HospitalLogout.vue"
import HospitalResetPassword from "@/components/Auth/AuthHospital/HospitalResetPassword/HospitalResetPassword.vue"
import HospitalApplication from "@/components/Hospitals/Dashboard/Overview/HospitalApplication/HospitalApplication.vue"
import HospitalProfile from "@/components/Hospitals/Profile/HospitalProfile.vue"
import HospitalGeneralInformation from "@/components/Hospitals/Profile/HospitalGeneralInformation/HospitalGeneralInformation.vue"
import ContactHospitalComponent from "@/components/Static/Contact/ContactHospitalComponent.vue"
import AboutUsHospital from "@/components/Static/AboutUs/AboutUsHospital.vue"
import HospitalDoctors from "@/components/Hospitals/Dashboard/Doctors/HospitalDoctors.vue"
import HospitalDoctorProfileTabs from "@/components/Hospitals/Dashboard/Doctors/HospitalDoctorProfileTabs.vue"
import HospitalStudentProfile from "@/components/Static/HospitalProfile/HospitalStudentProfile.vue"
import CalendarDashboardComponent from "@/components/Static/CalendarComponent/CalendarDashboardComponent.vue"
import HospitalFamulaturInformation from "@/components/Hospitals/Profile/HospitalGeneralInformation/HospitalFamulaturInformation.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: LandingComponent,
  },
  {
    path: "/about-us",
    name: "About Us",
    component: AboutUs,
  },
  {
    path: "/about-us-doc",
    name: "About Us Doc",
    beforeEnter: isDoctorOrHospitalGuard,
    component: AboutUsDoc,
  },
  {
    path: "/about-us-hospital",
    name: "About Us Hospital",
    beforeEnter: isDoctorOrHospitalGuard,
    component: AboutUsHospital,
  },

  {
    path: "/login",
    name: "Login",
    component: LoginComponent,
  },
  {
    path: "/doc-login",
    name: "Login Doctor",
    beforeEnter: isLoggedInDoctorGuard,
    component: DocLogin,
  },
  {
    path: "/hospital-login",
    name: "Login Hospital",
    component: HospitalLogin,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterComponent,
  },
  {
    path: "/doc-register",
    name: "Doctor Register",
    component: DocRegister,
  },
  {
    path: "/hospital-register",
    name: "Hospital Register",
    component: HospitalRegister,
  },
  {
    path: "/doctors",
    name: "SearchResults",
    component: SearchResultsComponent,
  },

  {
    path: "/doctors/:id",
    name: "DoctorsProfile",
    component: DoctorPage,
  },
  {
    path: "/hospital/:id",
    name: "HospitalProfile",
    component: HospitalStudentProfile,
  },
  {
    path: "/doctors/:id/application",
    name: "Application",
    beforeEnter: isStudentGuard,
    component: ApplicationComponent,
  },
  {
    path: "/profile",
    name: "StudentProfile",
    beforeEnter: isStudentGuard,
    component: StudentProfile,
    children: [
      {
        path: "",
        redirect: { name: "General Information" },
      },
      {
        path: "general-information",
        name: "General Information",
        component: GeneralInformation,
      },
      {
        path: "applications",
        name: "Student Application",
        component: ApplicationsProfileComponent,
      },
      {
        path: "messages",
        name: "Student Messages",
        component: StudentMessages,
      },
      {
        path: "application/:id",
        name: "Student Single Application",
        component: StudentSingleApplication,
        props: (route) => ({
          id: route.query.id,
        }),
      },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutComponent,
  },
  {
    path: "/doc-logout",
    name: "Doctor Logout",
    component: DocLogout,
  },
  {
    path: "/hospital-logout",
    name: "Hospital Logout",
    component: HospitalLogout,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    path: "/stud-verified",
    name: "Email Verified",
    component: EmailVerified,
  },
  {
    path: "/doc-verified",
    name: "Doc Email Verified",
    component: EmailVerifiedDoctor,
  },
  {
    path: "/hospital-verified",
    name: "Hospital Email Verified",
    component: EmailVerifiedHospital,
  },
  {
    path: "/t-and-c",
    name: "Terms and Conditions",
    component: TermsAndConditions,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactComponent,
  },
  {
    path: "/doc-contact",
    name: "Doctor Contact",
    beforeEnter: isDoctorOrHospitalGuard,
    component: ContactDoctorComponent,
  },
  {
    path: "/hospital-contact",
    name: "Hospital Contact",
    beforeEnter: isDoctorOrHospitalGuard,
    component: ContactHospitalComponent,
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: ImprintComponent,
  },
  {
    path: "/attribute",
    name: "Attribute",
    component: AttributeComponent,
  },

  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
    props: (route) => ({
      userId: route.query.userId,
      token: route.query.token,
    }),
  },
  {
    path: "/doc-reset-password",
    name: "Doctor Reset Password",
    component: DocResetPassword,
    props: (route) => ({
      userId: route.query.userId,
      token: route.query.token,
    }),
  },
  {
    path: "/hospital-reset-password",
    name: "Hospital Reset Password",
    component: HospitalResetPassword,
    props: (route) => ({
      userId: route.query.userId,
      token: route.query.token,
    }),
  },
  {
    path: "/doctor-dashboard",
    name: "Dashboard",
    beforeEnter: isDoctorGuard,
    component: DoctorDashboard,
    children: [
      {
        path: "",
        redirect: { name: "Doctor Overview" },
      },
      {
        path: "subscribers",
        name: "Subscribers",
        component: StudentTable,
      },
      {
        path: "broadcast",
        name: "Broadcast",
        component: BroadcastingMessages,
      },
      {
        path: "overview",
        name: "Doctor Overview",
        component: DoctorOverview,
      },
      {
        path: "calendar/:type",
        name: "Doctor Calendar",
        component: CalendarDashboardComponent,
        props: (route) => ({
          type: route.query.type,
        }),
      },
      {
        path: "application/:id",
        name: "Doctor Application",
        component: DoctorApplication,
        props: (route) => ({
          id: route.query.id,
        }),
      },
    ],
  },

  {
    path: "/doctor-profile",
    name: "Doctors Profile",
    beforeEnter: isDoctorOrHospitalGuard,
    component: DoctorProfile,
    children: [
      {
        path: "",
        redirect: { name: "Doctor General Information" },
      },
      {
        path: "general-information",
        name: "Doctor General Information",
        component: DoctorGeneralInformation,
      },
      {
        path: "famulatur",
        name: "Internship Information",
        component: FamulaturComponent,
      },
      {
        path: "time",
        name: "Time Table",
        component: TimeTable,
      },
    ],
  },

  {
    path: "/hospital-dashboard",
    name: "Hospital Dashboard",
    beforeEnter: isHospitalGuard,
    component: HospitalDashboard,
    children: [
      {
        path: "",
        redirect: { name: "Hospital Overview" },
      },
      {
        path: "overview",
        name: "Hospital Overview",
        component: HospitalOverview,
      },
      {
        path: "doctors",
        name: "Hospital Doctors",
        component: HospitalDoctors,
      },
      {
        path: "calendar/:type",
        name: "Hospital Calendar",
        component: CalendarDashboardComponent,
        props: (route) => ({
          type: route.query.type,
        }),
      },
      {
        path: "application/:id",
        name: "Hospital Application",
        component: HospitalApplication,
        props: (route) => ({
          id: route.query.id,
        }),
      },
      {
        path: "doctor/:id",
        name: "Hospital-Doctor Profile",
        component: HospitalDoctorProfileTabs,
        props: (route) => ({
          id: route.query.id,
        }),
      },
    ],
  },
  {
    path: "/hospital-profile",
    name: "Hospital Profile",
    beforeEnter: isHospitalGuard,
    component: HospitalProfile,
    children: [
      {
        path: "",
        redirect: { name: "Hospital General Information" },
      },
      {
        path: "general-information",
        name: "Hospital General Information",
        component: HospitalGeneralInformation,
      },
      {
        path: "hospital-intern-information",
        name: "Hospital Internship Information",
        component: HospitalFamulaturInformation,
      },
    ],
  },
  {
    path: "/doc-forgot-password",
    name: "Doctors Forgot Password Page",
    component: DocForgotPassword,
  },
  {
    path: "/hospital-forgot-password",
    name: "Hospital Forgot Password Page",
    component: HospitalForgotPassword,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFoundPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
