<template>
  <v-btn
    v-model="subscribe"
    class="mt-4 ml-4"
    color="#3fb984"
    height="40"
    :loading="isLoading"
    @click="subscribeToDoctor"
    ><v-icon size="30" class="mr-2">mdi-radio-tower</v-icon>
    {{ !subscribed ? $t('subscribe') : $t('unsubscribe') }}</v-btn
  >

  <v-dialog v-model="subscribe" persistent v-if="!subscribed">
    <v-sheet
      border="md"
      class="pa-6 text-black mx-auto"
      color="white"
      max-width="450px"
      style="border-radius: 7%"
    >
      <div align="center">
        <v-img :src="docPhoto" width="100px" style="border-radius: 50%"></v-img>
      </div>
      <h4 class="text-h5 font-weight-bold mb-4 mt-2" align="center">
        {{ $t('subscribe-header') }} {{ docName }}
      </h4>

      <p class="mb-8" style="font-size: 15px">
        {{ $t('subscription-line-1') }}
        {{ docName }} {{ $t('subscription-line-2') }}
      </p>
      <div align="end">
        <v-btn color="error" @click="subscribe = false" icon>
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
        <v-btn
          class="ml-3"
          color="#3fb984"
          @click="subscribeOrUnsubscribeToDoctor"
          icon
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
  <v-dialog v-model="subscribe" persistent v-if="subscribed">
    <v-sheet
      border="md"
      class="pa-6 text-black mx-auto"
      color="white"
      max-width="450px"
      style="border-radius: 7%"
    >
      <div align="center">
        <v-img :src="docPhoto" width="100px" style="border-radius: 50%"></v-img>
      </div>
      <h4 class="text-h5 font-weight-bold mb-2 mt-2" align="center">
        {{ $t('unsubscribe-header-en') }} {{ docName
        }}{{ $t('unsubscribe-header-de') }}
      </h4>

      <p class="mb-1" style="font-size: 15px">
        {{ $t('unsubscribe-line-1') }} {{ docName }}
      </p>
      <p class="mb-2" style="font-size: 15px">
        {{ docName }}
        {{ $t('unsubscribe-line-2') }}
      </p>
      <div align="end">
        <v-btn color="error" @click="subscribe = false" icon>
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
        <v-btn
          class="ml-3"
          color="#3fb984"
          @click="subscribeOrUnsubscribeToDoctor"
          icon
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, defineProps, ref, defineEmits } from 'vue'
import { Connection, DoctorProfile, RequestResponse } from '@/types'
import HeaderComponent from '../Header/HeaderComponent.vue'
import router from '@/router'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useUserActions } from '@/stores/user-actions'
import { broadcastingActions } from '@/stores/broadcasting-store'

const { t } = useI18n()
const isLoading = ref(false)
const route = useRoute()
const props = defineProps<{ photo: string; name: string }>()
const docPhoto = ref(props.photo)
const docName = ref(props.name)
const id = ref()
const subscribe = ref(false)
const subscribed = ref(false)
const broadcasting = broadcastingActions()
const requestResponse = ref<RequestResponse>()
const connectionId = ref<string>('')

const emit = defineEmits(['subscribed', 'unsubscribed'])
onMounted(async () => {
  isLoading.value = true
  id.value = route.params.id
  const token = localStorage.getItem('accessToken') ?? null
  if (token) {
    const response = await broadcasting.checkSubscription(token, id.value)
    const status = response.result as Connection
    if (status.connected) {
      subscribed.value = true
      connectionId.value = status.connectionId as string
    }
  }
  isLoading.value = false
})

const subscribeToDoctor = () => {
  const token = localStorage.getItem('accessToken') ?? null
  if (!token) {
    localStorage.setItem('docId', id.value)
    router.push('/login')
  }
  subscribe.value = !subscribe.value
}

const subscribeOrUnsubscribeToDoctor = async () => {
  if (!subscribed.value) {
    isLoading.value = true
    const response = await broadcasting.subscribeToDoctor(id.value)
    requestResponse.value = response
    connectionId.value = response?.result?.connectionId
    subscribed.value = response.success
    emit('subscribed', { val: requestResponse.value, subscribed: true })

    subscribe.value = false
    isLoading.value = false
  } else {
    isLoading.value = true
    const response = await broadcasting.unSubscribeToDoctor(connectionId.value)
    requestResponse.value = response
    subscribed.value = !response.success
    emit('subscribed', { val: requestResponse.value, subscribed: false })
    subscribe.value = false
    isLoading.value = false
  }
}
</script>

<style scoped></style>
