<template>
  <HospitalHeader></HospitalHeader>
  <v-card class="mt-6">
    <v-layout>
      <v-navigation-drawer
        :expand-on-hover="smallWindow"
        :rail="smallWindow"
        :permanent="!smallWindow"
        mobile-breakpoint="xs"
        width="230"
        height="100vh"
      >
        <v-list>
          <v-list-item
            :prepend-avatar="
              hospitalAction.hospitalProfileLink != '' &&
              hospitalAction.hospitalProfileLink != undefined
                ? hospitalAction.hospitalProfileLink
                : require('../../../assets/blank.png')
            "
            :title="name"
          >
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item
            prepend-icon="mdi-account"
            :title="$t('doctor.overview')"
            :class="{
              'v-list-item--active': selectedItem === 'Hospital Overview',
              'v-list-item--inactive': selectedItem !== 'Hospital Overview',
            }"
            @click="navigateTo('Hospital Overview')"
          >
          </v-list-item>

          <!-- <v-list-item
            prepend-icon="mdi-inbox-arrow-down"
            title="Inbox"
            stacked
            @click="selectedItem = 'construct'"
          >
            <template v-slot:append>
              <v-badge color="error" content="!" inline></v-badge>
            </template>
          </v-list-item> -->
          <v-list-item
            prepend-icon="mdi-doctor"
            :title="$t('doctors')"
            stacked
            :class="{
              'v-list-item--active': selectedItem === 'Hospital Doctors',
              'v-list-item--inactive': selectedItem !== 'Hospital Doctors',
            }"
            @click="navigateTo('Hospital Doctors')"
          >
          </v-list-item>

          <v-list-item
            prepend-icon="mdi-calendar"
            :title="$t('calendar')"
            stacked
            :class="{
              'v-list-item--active': selectedItem === 'Hospital Calendar',
              'v-list-item--inactive': selectedItem !== 'Hospital Calendar',
            }"
            @click="navigateTo('Hospital Calendar')"
          >
            <template v-slot:append>
              <v-badge color="blue" :content="$t('new')" inline></v-badge>
            </template>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <request-status :response="requestResponse" :isLoading="isLoading" />
        <router-view />
      </v-main>
    </v-layout>
  </v-card>

  <FooterComponent></FooterComponent>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue"

import { useI18n } from "vue-i18n"
import FooterComponent from "@/components/Footer/FooterComponent.vue"
import { useMeta } from "vue-meta"
import router from "@/router"
import { useRoute } from "vue-router"
import HospitalHeader from "../Headers/HospitalHeader.vue"
import { hospitalActions } from "@/stores/hospital-actions"
import { Hospital, RequestResponse } from "@/types"

FooterComponent
export default defineComponent({
  components: { HospitalHeader, FooterComponent },
  name: "HospitalDashboard",

  setup() {
    const { t } = useI18n()
    const selectedItem = ref()
    const docProfileLink = ref("")
    const name = ref("")
    const drawer = ref(true)
    const rail = ref(true)
    const applicationId = ref<string>()
    const smallWindow = ref(window.innerWidth < 600)
    const hospitalAction = hospitalActions()
    const route = useRoute()
    const isLoading = ref(false)
    const requestResponse = ref<RequestResponse>()
    onMounted(() => {
      router.push({ name: routeName.value ?? "Hospital Overview" })
      selectedItem.value = routeName.value ?? "Hospital Overview"
      docProfileLink.value = hospitalAction.hospitalProfileLink
      name.value = hospitalAction.hospitalName
    })

    const routeName = computed(() => {
      return route.name
    })

    useMeta(
      computed(() => ({
        title: name.value ?? "Medcircle",
      }))
    )

    watch([routeName], () => {
      selectedItem.value = routeName.value ?? "Hospital Overview"
    })

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    return {
      selectedItem,
      docProfileLink,
      name,
      drawer,
      rail,
      smallWindow,
      applicationId,
      routeName,
      hospitalAction,
      requestResponse,
      isLoading,
    }
  },
  methods: {
    async navigateTo(item: string): Promise<void> {
      this.isLoading = true
      this.selectedItem = item
      if (this.selectedItem == "Hospital Calendar") {
        this.$router.push({
          path: `/hospital-dashboard/calendar/hospital`,
        })
      } else {
        await this.$router.push({ name: item })
      }
      this.isLoading = false
    },
  },
})
</script>

<style>
.text {
  font-size: 50px;
  font-weight: 500;
  margin-top: 130px;
}
.sub-text {
  font-size: 20px;
  font-weight: 300;
}

.content {
  overflow-x: hidden;
}
.footer {
  margin-top: 200px;
}
</style>
