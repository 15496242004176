<template>
  <h2 class="mt-4">{{ $t('your-messages') }}</h2>
  <v-divider class="mt-3"></v-divider>
  <request-status :response="requestResponse" :isLoading="loading" />
  <request-status :response="seenRequestResponse" :isLoading="false" />
  <MessageTable
    :messages="messages"
    :isDoctor="false"
    v-if="!loading"
    @view="viewMessage($event)"
  ></MessageTable>
  <MessageBox
    :content="content"
    :title="title"
    :broadcast="view"
    :editView="false"
    :studentView="true"
    :doctorName="doctorName"
    :doctorProfile="doctorProfile"
    v-model="view"
    v-if="view"
    @closeDialog="view = false"
  />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, defineEmits } from 'vue'
import { Applicant, Message, RequestResponse } from '@/types'

import { onMounted } from 'vue'

import { broadcastingActions } from '@/stores/broadcasting-store'
import MessageTable from '@/components/Static/MessageTable/MessageTable.vue'
import { Delta } from '@vueup/vue-quill'
import MessageBox from '@/components/Static/MessageBox/MessageBox.vue'
const { t } = useI18n()
const emit = defineEmits(['read'])
const broadcasting = broadcastingActions()
const page = ref(1)
const itemsPerPage = ref(5)
const requestResponse = ref<RequestResponse>()
const seenRequestResponse = ref<RequestResponse>()
const loading = ref(false)
const messages = ref<Message[]>([])
const view = ref(false)
const id = ref<string>('')
const content = ref<Delta>()
const title = ref<string>('')
const doctorName = ref<string>('')
const doctorProfile = ref<string>('')
const token = localStorage.getItem('accessToken') ?? ''
onMounted(() => {
  getMessages()
})

const getMessages = async () => {
  loading.value = true

  const response = await broadcasting.getMessages(
    page.value,
    itemsPerPage.value,
    token
  )
  requestResponse.value = response
  if (response.success) {
    messages.value = response.result as Message[]
  }
  loading.value = false
}

const viewMessage = async (event: any) => {
  id.value = event.id
  title.value = event.title
  content.value = event.content
  doctorName.value = event.name
  doctorProfile.value = event.photo

  view.value = true

  const response = await broadcasting.markSeen(token, id.value)
  seenRequestResponse.value = response
  if (response.success) {
    emit('read')
  }
}
</script>

<style scoped></style>
