import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"min-height":"60vh"} }
const _hoisted_2 = {
  align: "center",
  class: "mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_status = _resolveComponent("request-status")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_CalendarProfileComponent = _resolveComponent("CalendarProfileComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_request_status, {
      response: _ctx.requestResponse,
      isLoading: _ctx.isLoading
    }, null, 8, ["response", "isLoading"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("doctor.choose-time")), 1),
      _createVNode(_component_v_divider, { class: "mt-4" }),
      (_ctx.doctor && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_CalendarProfileComponent, {
            key: 0,
            manualTime: _ctx.manualTime,
            useCalendarSearch: _ctx.activeSearch,
            onSaved: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveProfileData($event)))
          }, null, 8, ["manualTime", "useCalendarSearch"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}