import { computed } from "vue"
import { useI18n } from "vue-i18n"

export const SPECIALIZATION_DATA_DE = [
  "Facharzt/-in für Allgemeinmedizin",
  "Facharzt/-in für Anästhesiologie",
  "Facharzt/-in für Anatomie",
  "Facharzt/-in für Arbeitsmedizin",
  "Facharzt/-in für Augenheilkunde",
  "Facharzt/-in für Biochemie",
  "Facharzt/-in für Allgemeine Chirurgie",
  "Facharzt/-in für Gefäßchirurgie",
  "Facharzt/-in für Herz- und Thoraxchirurgie",
  "Facharzt/-in für Kinderchirurgie",
  "Facharzt/-in für Orthopädie und Unfallchirurgie",
  "Facharzt/-in für plastische Chirurgie",
  "Facharzt/-in für Viszeralchirurgie",
  "Facharzt/-in für Gynäkologie",
  "Facharzt/-in für Gynäkologie und Geburtshilfe mit Schwerpunkt gynäkologische Endokrinologie und Reproduktionsmedizin",
  "Facharzt/-in für Gynäkologie und Geburtshilfe mit Schwerpunkt gynäkologische Onkologie",
  "Facharzt/-in für Gynäkologie und Geburtshilfe mit Schwerpunkt spezielle Geburtshilfe und Perinatalmedizin",
  "Facharzt/-in für Hals-Nasen-Ohrenheilkunde",
  "Facharzt/-in für Sprach-, Stimm- und kindliche Hörstörungen",
  "Facharzt/-in für Haut- und Geschlechtskrankheiten",
  "Facharzt/-in für Humangenetik",
  "Facharzt/-in für Hygiene und Umweltmedizin",
  "Facharzt/-in für Innere und Allgemeinmedizin",
  "Facharzt/-in für Innere Medizin",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Angiologie",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Endokrinologie und Diabetologie",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Gastroenterologie",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Hämatologie und Onkologie",
  "Facharzt/-in für Innere Medizin mit Schwerpunk Kardiologie",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Nephrologie",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Pneumologie",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Rheumatologie",
  "Facharzt/-in für Kinder und Jugendmedizin",
  "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Hämato-Onkologie",
  "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Kinderkardiologie",
  "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Neonatologie",
  "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Neuropädiatrie",
  "Facharzt/-in für Kinder- und Jugendpsychiatrie und -psychotherapie",
  "Facharzt/-in für Labormedizin",
  "Facharzt/-in für Mikrobiologie, Virologie und Infektionsepidemiologie",
  "Facharzt/-in für Mund-Kiefer-Gesichtschirurgie",
  "Facharzt/-in für Neurochirurgie",
  "Facharzt/-in für Neurologie",
  "Facharzt/-in für Nuklearmedizin",
  "Facharzt/-in für Pathologie",
  "Facharzt/-in für Neuropathologie",
  "Facharzt/-in für Physiologie und Pathophysiologie",
  "Facharzt/-in für Klinische Pharmakologie und Toxikologie",
  "Facharzt/-in für Physikalische und Rehabilitative Medizin",
  "Facharzt/-in für Physiologie",
  "Facharzt/-in für Psychiatrie und Psychotherapie",
  "Facharzt/-in für Psychiatrie und Psychotherapie mit Schwerpunkt forensische Psychiatrie",
  "Facharzt/-in für Psychosomatische Medizin und Psychotherapie",
  "Facharzt/-in für Radiologie",
  "Facharzt/-in für Radiologie mit Schwerpunkt pädiatrische Radiologie",
  "Facharzt/-in für Radiologie mit Schwerpunkt Neuroradiologie",
  "Facharzt/-in für Rechtsmedizin",
  "Facharzt/-in für Strahlentherapie",
  "Facharzt/-in für Transfusionsmedizin",
  "Facharzt/-in für Urologie",
]

export const SPECIALIZATION_DATA_EN = [
  "Specialist in General Medicine",
  "Specialist in Anesthesiology",
  "Specialist in Anatomy",
  "Specialist in Occupational Medicine",
  "Specialist in Ophthalmology",
  "Specialist in Biochemistry",
  "Specialist in General Surgery",
  "Specialist in Vascular Surgery",
  "Specialist in Heart and Thoracic Surgery",
  "Specialist in Pediatric Surgery",
  "Specialist in Orthopedics and Trauma Surgery",
  "Specialist in Plastic Surgery",
  "Specialist in Visceral Surgery",
  "Specialist in Gynecology",
  "Specialist in Gynecology and Obstetrics with a focus on Gynecological Endocrinology and Reproductive Medicine",
  "Specialist in Gynecology and Obstetrics with a focus on Gynecological Oncology",
  "Specialist in Gynecology and Obstetrics with a focus on Special Obstetrics and Perinatal Medicine",
  "Specialist in Otorhinolaryngology",
  "Specialist in Speech, Voice, and Pediatric Auditory Disorders",
  "Specialist in Dermatology and Venereology",
  "Specialist in Human Genetics",
  "Specialist in Hygiene and Environmental Medicine",
  "Specialist in Internal and General Medicine",
  "Specialist in Internal Medicine",
  "Specialist in Internal Medicine with a focus on Angiology",
  "Specialist in Internal Medicine with a focus on Endocrinology and Diabetology",
  "Specialist in Internal Medicine with a focus on Gastroenterology",
  "Specialist in Internal Medicine with a focus on Hematology and Oncology",
  "Specialist in Internal Medicine with a focus on Cardiology",
  "Specialist in Internal Medicine with a focus on Nephrology",
  "Specialist in Internal Medicine with a focus on Pulmonology",
  "Specialist in Internal Medicine with a focus on Rheumatology",
  "Specialist in Pediatrics",
  "Specialist in Pediatrics with a focus on Hemato-Oncology",
  "Specialist in Pediatrics with a focus on Pediatric Cardiology",
  "Specialist in Pediatrics with a focus on Neonatology",
  "Specialist in Pediatrics with a focus on Neuropediatrics",
  "Specialist in Child and Adolescent Psychiatry and Psychotherapy",
  "Specialist in Laboratory Medicine",
  "Specialist in Microbiology, Virology, and Infection Epidemiology",
  "Specialist in Oral and Maxillofacial Surgery",
  "Specialist in Neurosurgery",
  "Specialist in Neurology",
  "Specialist in Nuclear Medicine",
  "Specialist in Pathology",
  "Specialist in Neuropathology",
  "Specialist in Physiology and Pathophysiology",
  "Specialist in Clinical Pharmacology and Toxicology",
  "Specialist in Physical and Rehabilitative Medicine",
  "Specialist in Physiology",
  "Specialist in Psychiatry and Psychotherapy",
  "Specialist in Psychiatry and Psychotherapy with a focus on Forensic Psychiatry",
  "Specialist in Psychosomatic Medicine and Psychotherapy",
  "Specialist in Radiology",
  "Specialist in Radiology with a focus on Pediatric Radiology",
  "Specialist in Radiology with a focus on Neuroradiology",
  "Specialist in Forensic Medicine",
  "Specialist in Radiation Therapy",
  "Specialist in Transfusion Medicine",
  "Specialist in Urology",
]

export interface SpecialtiesMap {
  [key: string]: string
}

export const specialtiesMap: SpecialtiesMap = {
  "Specialist in General Medicine": "Facharzt/-in für Allgemeinmedizin",
  "Specialist in Anesthesiology": "Facharzt/-in für Anästhesiologie",
  "Specialist in Anatomy": "Facharzt/-in für Anatomie",
  "Specialist in Occupational Medicine": "Facharzt/-in für Arbeitsmedizin",
  "Specialist in Ophthalmology": "Facharzt/-in für Augenheilkunde",
  "Specialist in Biochemistry": "Facharzt/-in für Biochemie",
  "Specialist in General Surgery": "Facharzt/-in für Allgemeine Chirurgie",
  "Specialist in Vascular Surgery": "Facharzt/-in für Gefäßchirurgie",
  "Specialist in Heart and Thoracic Surgery":
    "Facharzt/-in für Herz- und Thoraxchirurgie",
  "Specialist in Pediatric Surgery": "Facharzt/-in für Kinderchirurgie",
  "Specialist in Orthopedics and Trauma Surgery":
    "Facharzt/-in für Orthopädie und Unfallchirurgie",
  "Specialist in Plastic Surgery": "Facharzt/-in für plastische Chirurgie",
  "Specialist in Visceral Surgery": "Facharzt/-in für Viszeralchirurgie",
  "Specialist in Gynecology": "Facharzt/-in für Gynäkologie",
  "Specialist in Gynecology and Obstetrics with a focus on Gynecological Endocrinology and Reproductive Medicine":
    "Facharzt/-in für Gynäkologie und Geburtshilfe mit Schwerpunkt gynäkologische Endokrinologie und Reproduktionsmedizin",
  "Specialist in Gynecology and Obstetrics with a focus on Gynecological Oncology":
    "Facharzt/-in für Gynäkologie und Geburtshilfe mit Schwerpunkt gynäkologische Onkologie",
  "Specialist in Gynecology and Obstetrics with a focus on Special Obstetrics and Perinatal Medicine":
    "Facharzt/-in für Gynäkologie und Geburtshilfe mit Schwerpunkt spezielle Geburtshilfe und Perinatalmedizin",
  "Specialist in Otorhinolaryngology":
    "Facharzt/-in für Hals-Nasen-Ohrenheilkunde",
  "Specialist in Speech, Voice, and Pediatric Auditory Disorders":
    "Facharzt/-in für Sprach-, Stimm- und kindliche Hörstörungen",
  "Specialist in Dermatology and Venereology":
    "Facharzt/-in für Haut- und Geschlechtskrankheiten",
  "Specialist in Human Genetics": "Facharzt/-in für Humangenetik",
  "Specialist in Hygiene and Environmental Medicine":
    "Facharzt/-in für Hygiene und Umweltmedizin",
  "Specialist in Internal and General Medicine":
    "Facharzt/-in für Innere und Allgemeinmedizin",
  "Specialist in Internal Medicine": "Facharzt/-in für Innere Medizin",
  "Specialist in Internal Medicine with a focus on Angiology":
    "Facharzt/-in für Innere Medizin mit Schwerpunkt Angiologie",
  "Specialist in Internal Medicine with a focus on Endocrinology and Diabetology":
    "Facharzt/-in für Innere Medizin mit Schwerpunkt Endokrinologie und Diabetologie",
  "Specialist in Internal Medicine with a focus on Gastroenterology":
    "Facharzt/-in für Innere Medizin mit Schwerpunkt Gastroenterologie",
  "Specialist in Internal Medicine with a focus on Hematology and Oncology":
    "Facharzt/-in für Innere Medizin mit Schwerpunkt Hämatologie und Onkologie",
  "Specialist in Internal Medicine with a focus on Cardiology":
    "Facharzt/-in für Innere Medizin mit Schwerpunk Kardiologie",
  "Specialist in Internal Medicine with a focus on Nephrology":
    "Facharzt/-in für Innere Medizin mit Schwerpunkt Nephrologie",
  "Specialist in Internal Medicine with a focus on Pulmonology":
    "Facharzt/-in für Innere Medizin mit Schwerpunkt Pneumologie",
  "Specialist in Internal Medicine with a focus on Rheumatology":
    "Facharzt/-in für Innere Medizin mit Schwerpunkt Rheumatologie",
  "Specialist in Pediatrics": "Facharzt/-in für Kinder und Jugendmedizin",
  "Specialist in Pediatrics with a focus on Hemato-Oncology":
    "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Hämato-Onkologie",
  "Specialist in Pediatrics with a focus on Pediatric Cardiology":
    "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Kinderkardiologie",
  "Specialist in Pediatrics with a focus on Neonatology":
    "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Neonatologie",
  "Specialist in Pediatrics with a focus on Neuropediatrics":
    "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Neuropädiatrie",
  "Specialist in Child and Adolescent Psychiatry and Psychotherapy":
    "Facharzt/-in für Kinder- und Jugendpsychiatrie und -psychotherapie",
  "Specialist in Laboratory Medicine": "Facharzt/-in für Labormedizin",
  "Specialist in Microbiology, Virology, and Infection Epidemiology":
    "Facharzt/-in für Mikrobiologie, Virologie und Infektionsepidemiologie",
  "Specialist in Oral and Maxillofacial Surgery":
    "Facharzt/-in für Mund-Kiefer-Gesichtschirurgie",
  "Specialist in Neurosurgery": "Facharzt/-in für Neurochirurgie",
  "Specialist in Neurology": "Facharzt/-in für Neurologie",
  "Specialist in Nuclear Medicine": "Facharzt/-in für Nuklearmedizin",
  "Specialist in Pathology": "Facharzt/-in für Pathologie",
  "Specialist in Neuropathology": "Facharzt/-in für Neuropathologie",
  "Specialist in Physiology and Pathophysiology":
    "Facharzt/-in für Physiologie und Pathophysiologie",
  "Specialist in Clinical Pharmacology and Toxicology":
    "Facharzt/-in für Klinische Pharmakologie und Toxikologie",
  "Specialist in Physical and Rehabilitative Medicine":
    "Facharzt/-in für Physikalische und Rehabilitative Medizin",
  "Specialist in Physiology": "Facharzt/-in für Physiologie",
  "Specialist in Psychiatry and Psychotherapy":
    "Facharzt/-in für Psychiatrie und Psychotherapie",
  "Specialist in Psychiatry and Psychotherapy with a focus on Forensic Psychiatry":
    "Facharzt/-in für Psychiatrie und Psychotherapie mit Schwerpunkt forensische Psychiatrie",
  "Specialist in Psychosomatic Medicine and Psychotherapy":
    "Facharzt/-in für Psychosomatische Medizin und Psychotherapie",
  "Specialist in Radiology": "Facharzt/-in für Radiologie",
  "Specialist in Radiology with a focus on Pediatric Radiology":
    "Facharzt/-in für Radiologie mit Schwerpunkt pädiatrische Radiologie",
  "Specialist in Radiology with a focus on Neuroradiology":
    "Facharzt/-in für Radiologie mit Schwerpunkt Neuroradiologie",
  "Specialist in Forensic Medicine": "Facharzt/-in für Rechtsmedizin",
  "Specialist in Radiation Therapy": "Facharzt/-in für Strahlentherapie",
  "Specialist in Transfusion Medicine": "Facharzt/-in für Transfusionsmedizin",
  "Specialist in Urology": "Facharzt/-in für Urologie",
}

export const FormatDateFunction = (dateString: string) => {
  const [year, month, day] = dateString.split("-")
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const monthName = monthNames[parseInt(month, 10) - 1]
  return `${monthName} ${parseInt(day, 10)} ${year}`
}

export const doctorTitle = computed(() => {
  return ["-", "Frau", "Herr", "Dr.", "Prof. Dr.", "Prof. Dr. Dr."]
})

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
  // sleep(1000).then(() => {
  //   //some things
  // })
}

export const specialtiesReverseMap: SpecialtiesMap = {
  "Facharzt/-in für Allgemeinmedizin": "Specialist in General Medicine",
  "Facharzt/-in für Anästhesiologie": "Specialist in Anesthesiology",
  "Facharzt/-in für Anatomie": "Specialist in Anatomy",
  "Facharzt/-in für Arbeitsmedizin": "Specialist in Occupational Medicine",
  "Facharzt/-in für Augenheilkunde": "Specialist in Ophthalmology",
  "Facharzt/-in für Biochemie": "Specialist in Biochemistry",
  "Facharzt/-in für Allgemeine Chirurgie": "Specialist in General Surgery",
  "Facharzt/-in für Gefäßchirurgie": "Specialist in Vascular Surgery",
  "Facharzt/-in für Herz- und Thoraxchirurgie":
    "Specialist in Heart and Thoracic Surgery",
  "Facharzt/-in für Kinderchirurgie": "Specialist in Pediatric Surgery",
  "Facharzt/-in für Orthopädie und Unfallchirurgie":
    "Specialist in Orthopedics and Trauma Surgery",
  "Facharzt/-in für plastische Chirurgie": "Specialist in Plastic Surgery",
  "Facharzt/-in für Viszeralchirurgie": "Specialist in Visceral Surgery",
  "Facharzt/-in für Gynäkologie": "Specialist in Gynecology",
  "Facharzt/-in für Gynäkologie und Geburtshilfe mit Schwerpunkt gynäkologische Endokrinologie und Reproduktionsmedizin":
    "Specialist in Gynecology and Obstetrics with a focus on Gynecological Endocrinology and Reproductive Medicine",
  "Facharzt/-in für Gynäkologie und Geburtshilfe mit Schwerpunkt gynäkologische Onkologie":
    "Specialist in Gynecology and Obstetrics with a focus on Gynecological Oncology",
  "Facharzt/-in für Gynäkologie und Geburtshilfe mit Schwerpunkt spezielle Geburtshilfe und Perinatalmedizin":
    "Specialist in Gynecology and Obstetrics with a focus on Special Obstetrics and Perinatal Medicine",
  "Facharzt/-in für Hals-Nasen-Ohrenheilkunde":
    "Specialist in Otorhinolaryngology",
  "Facharzt/-in für Sprach-, Stimm- und kindliche Hörstörungen":
    "Specialist in Speech, Voice, and Pediatric Auditory Disorders",
  "Facharzt/-in für Haut- und Geschlechtskrankheiten":
    "Specialist in Dermatology and Venereology",
  "Facharzt/-in für Humangenetik": "Specialist in Human Genetics",
  "Facharzt/-in für Hygiene und Umweltmedizin":
    "Specialist in Hygiene and Environmental Medicine",
  "Facharzt/-in für Innere und Allgemeinmedizin":
    "Specialist in Internal and General Medicine",
  "Facharzt/-in für Innere Medizin": "Specialist in Internal Medicine",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Angiologie":
    "Specialist in Internal Medicine with a focus on Angiology",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Endokrinologie und Diabetologie":
    "Specialist in Internal Medicine with a focus on Endocrinology and Diabetology",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Gastroenterologie":
    "Specialist in Internal Medicine with a focus on Gastroenterology",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Hämatologie und Onkologie":
    "Specialist in Internal Medicine with a focus on Hematology and Oncology",
  "Facharzt/-in für Innere Medizin mit Schwerpunk Kardiologie":
    "Specialist in Internal Medicine with a focus on Cardiology",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Nephrologie":
    "Specialist in Internal Medicine with a focus on Nephrology",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Pneumologie":
    "Specialist in Internal Medicine with a focus on Pulmonology",
  "Facharzt/-in für Innere Medizin mit Schwerpunkt Rheumatologie":
    "Specialist in Internal Medicine with a focus on Rheumatology",
  "Facharzt/-in für Kinder und Jugendmedizin": "Specialist in Pediatrics",
  "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Hämato-Onkologie":
    "Specialist in Pediatrics with a focus on Hemato-Oncology",
  "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Kinderkardiologie":
    "Specialist in Pediatrics with a focus on Pediatric Cardiology",
  "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Neonatologie":
    "Specialist in Pediatrics with a focus on Neonatology",
  "Facharzt/-in für Kinder und Jugendmedizin mit Schwerpunkt Neuropädiatrie":
    "Specialist in Pediatrics with a focus on Neuropediatrics",
  "Facharzt/-in für Kinder- und Jugendpsychiatrie und -psychotherapie":
    "Specialist in Child and Adolescent Psychiatry and Psychotherapy",
  "Facharzt/-in für Labormedizin": "Specialist in Laboratory Medicine",
  "Facharzt/-in für Mikrobiologie, Virologie und Infektionsepidemiologie":
    "Specialist in Microbiology, Virology, and Infection Epidemiology",
  "Facharzt/-in für Mund-Kiefer-Gesichtschirurgie":
    "Specialist in Oral and Maxillofacial Surgery",
  "Facharzt/-in für Neurochirurgie": "Specialist in Neurosurgery",
  "Facharzt/-in für Neurologie": "Specialist in Neurology",
  "Facharzt/-in für Nuklearmedizin": "Specialist in Nuclear Medicine",
  "Facharzt/-in für Pathologie": "Specialist in Pathology",
  "Facharzt/-in für Neuropathologie": "Specialist in Neuropathology",
  "Facharzt/-in für Physiologie und Pathophysiologie":
    "Specialist in Physiology and Pathophysiology",
  "Facharzt/-in für Klinische Pharmakologie und Toxikologie":
    "Specialist in Clinical Pharmacology and Toxicology",
  "Facharzt/-in für Physikalische und Rehabilitative Medizin":
    "Specialist in Physical and Rehabilitative Medicine",
  "Facharzt/-in für Physiologie": "Specialist in Physiology",
  "Facharzt/-in für Psychiatrie und Psychotherapie":
    "Specialist in Psychiatry and Psychotherapy",
  "Facharzt/-in für Psychiatrie und Psychotherapie mit Schwerpunkt forensische Psychiatrie":
    "Specialist in Psychiatry and Psychotherapy with a focus on Forensic Psychiatry",
  "Facharzt/-in für Psychosomatische Medizin und Psychotherapie":
    "Specialist in Psychosomatic Medicine and Psychotherapy",
  "Facharzt/-in für Radiologie": "Specialist in Radiology",
  "Facharzt/-in für Radiologie mit Schwerpunkt pädiatrische Radiologie":
    "Specialist in Radiology with a focus on Pediatric Radiology",
  "Facharzt/-in für Radiologie mit Schwerpunkt Neuroradiologie":
    "Specialist in Radiology with a focus on Neuroradiology",
  "Facharzt/-in für Rechtsmedizin": "Specialist in Forensic Medicine",
  "Facharzt/-in für Strahlentherapie": "Specialist in Radiation Therapy",
  "Facharzt/-in für Transfusionsmedizin": "Specialist in Transfusion Medicine",
  "Facharzt/-in für Urologie": "Specialist in Urology",
}
