<template>
  <v-dialog max-width="450px">
    <v-sheet
      class="mx-auto pa-2"
      style="font-family: Verdana, Geneva, Tahoma, sans-serif"
      elevation="8"
      :width="smallWindow ? '350px' : '500px'"
      rounded="lg"
      ><v-card-title class="d-flex justify-space-between align-center mb-5">
        {{ $t("hospital.add-doctor") }}
        <v-btn icon="mdi-close" variant="text" @click="$emit('close')"></v-btn>
      </v-card-title>

      <v-text-field
        v-model="email"
        density="compact"
        :placeholder="$t('email')"
        :rules="emailRules"
        prepend-inner-icon="mdi-email-outline"
        variant="outlined"
        @keyup.enter="isInfoCompleted ? invite() : undefined"
      ></v-text-field>
      <v-autocomplete
        v-model="chosenDepartment"
        density="compact"
        :placeholder="$t('hospital.department')"
        :items="departments"
        :item-title="getDepartmentName"
        :item-value="getDepartmentValue"
        prepend-inner-icon="mdi-hospital-building"
        variant="outlined"
        :rules="notEmptyRule"
        :menu-props="{ width: '300px' }"
        @keyup.enter="isInfoCompleted ? invite() : undefined"
      ></v-autocomplete>

      <div class="mt-2" align="end">
        <v-btn color="#3fb984" @click="invite" :disabled="!isInfoCompleted">
          {{ $t("hospital.send-invite") }}
        </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import {
  ref,
  onMounted,
  defineEmits,
  onBeforeUnmount,
  computed,
  watch,
} from "vue"
import { hospitalActions } from "@/stores/hospital-actions"
import { Department, Departments, RequestResponse } from "@/types"
import { useToast } from "vue-toast-notification"

const emit = defineEmits(["close", "invited"])
const { t } = useI18n()
const hospitalAction = hospitalActions()
const email = ref<string>("")
const $toast = useToast()
const smallWindow = ref(window.innerWidth < 1000)
const requestResponse = ref()
const departments = ref<Department[]>([])
const chosenDepartment = ref<string | null>(null)
const locale = ref(localStorage.getItem("locale") || "de")

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}

const notEmptyRule = [(v: string) => !!v || t("field-required")]

onMounted(async () => {
  window.addEventListener("resize", updateWindowSize)
  await getDepartments()
  window.addEventListener("locale-changed", updateLocale)
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateWindowSize)
  window.removeEventListener("locale-changed", updateLocale)
})

const updateLocale = () => {
  locale.value = localStorage.getItem("locale") ?? "de"
}

const getDepartmentName = computed(() => (department: Department): string => {
  return locale.value == "de" ? department.de : department.en
})
const getDepartmentValue = (department: Department): string => {
  return department.value
}

const emailRules = [
  (v: string) => !!v || t("email-required"),
  (v: string) => /.+@.+\..+/.test(v) || t("email-authentic"),
]

const isInfoCompleted = computed(() => {
  const isEmailInvalid = emailRules.some((rule) => {
    const result = rule(email.value)
    return typeof result === "string"
  })

  return !isEmailInvalid && chosenDepartment.value
})

const getDepartments = async () => {
  const response = await hospitalAction.getDepartments()
  if (response.success) {
    departments.value = response.result as Department[]
  }
}

const invite = async (): Promise<void> => {
  const data = {
    doctorEmail: email.value,
    department: chosenDepartment.value,
  }
  const response = await hospitalAction.inviteDoctor(data)
  requestResponse.value = response
  if (response.success) {
    $toast.success(t("notifications.invite-send-successful"), {
      position: "bottom-left",
    })
  } else {
    $toast.error(response.message ?? "", {
      position: "bottom-left",
    })
  }
  emit("invited")
}
</script>

<style scoped></style>
