<template>
  <div class="container" v-if="isLoading">
    <v-progress-circular indeterminate color="green"></v-progress-circular>
  </div>

  <div v-if="requestFailed && !isLoading">
    <v-alert
      color="red"
      theme="dark"
      icon="mdi-close-octagon"
      border
      align="center"
    >
      {{ errorMessage }}
    </v-alert>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, defineProps, ref } from 'vue'
import { RequestResponse } from '../../../types/index'

const props = defineProps({
  response: {
    type: Object as () => RequestResponse,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

const requestFailed = computed(() => {
  if (props.response?.success != null) {
    return !props.response?.success
  } else {
    return false
  }
})

const errorMessage = computed(() => {
  return (
    props.response?.message ??
    'Irgendwas ist schiefgelaufen. Bitte versuchen sie es nochmals'
  )
})
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set height to full viewport height */
}
</style>
